/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Authorization(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.3">
                <path d="M10.5 18.92C7.33 17.92 5 14.68 5 11.18V7.57999L10.5 5.17999L16 7.57999V11.18C16 11.493 15.9814 11.804 15.9451 12.1115C13.6903 12.5957 12 14.6005 12 17C12 17.401 12.0472 17.7909 12.1363 18.1646C11.6246 18.4825 11.0762 18.7382 10.5 18.92Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 17C21 19.2091 19.2091 21 17 21C14.7909 21 13 19.2091 13 17C13 14.7909 14.7909 13 17 13C19.2091 13 21 14.7909 21 17ZM17 16.62C17.6186 16.62 18.12 16.1186 18.12 15.5C18.12 14.8814 17.6186 14.38 17 14.38C16.3814 14.38 15.88 14.8814 15.88 15.5C15.88 16.1186 16.3814 16.62 17 16.62ZM14.76 18.58C14.81 17.86 16.27 17.5 17 17.5C17.73 17.5 19.19 17.86 19.24 18.58C18.74 19.29 17.92 19.75 17 19.75C16.08 19.75 15.26 19.29 14.76 18.58Z"
                />
            </g>
            <path d="M18.12 15.5C18.12 16.1186 17.6186 16.62 17 16.62C16.3814 16.62 15.88 16.1186 15.88 15.5C15.88 14.8814 16.3814 14.38 17 14.38C17.6186 14.38 18.12 14.8814 18.12 15.5Z" />
            <path d="M17 17.5C16.27 17.5 14.81 17.86 14.76 18.58C15.26 19.29 16.08 19.75 17 19.75C17.92 19.75 18.74 19.29 19.24 18.58C19.19 17.86 17.73 17.5 17 17.5Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 3L3 6.27V11.18C3 15.72 6.2 19.97 10.5 21C11.3818 20.7888 12.2174 20.4421 12.9886 19.9853C13.9001 21.2081 15.3576 22 17 22C19.7614 22 22 19.7614 22 17C22 14.5659 20.2607 12.5381 17.9572 12.0915C17.9855 11.7896 18 11.4855 18 11.18V6.27L10.5 3ZM15.9451 12.1115C15.9814 11.804 16 11.493 16 11.18V7.57999L10.5 5.17999L5 7.57999V11.18C5 14.68 7.33 17.92 10.5 18.92C11.0762 18.7382 11.6246 18.4825 12.1363 18.1646C12.0472 17.7909 12 17.401 12 17C12 14.6005 13.6903 12.5957 15.9451 12.1115ZM21 17C21 19.2091 19.2091 21 17 21C14.7909 21 13 19.2091 13 17C13 14.7909 14.7909 13 17 13C19.2091 13 21 14.7909 21 17Z"
            />
        </SvgIcon>
    );
}
