/**
 * @module webcore-ux/nextgen/components/ToggleButton/ToggleButtonGroup
 * @copyright © Copyright 2021 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MuiToggleButtonGroup } from '@material-ui/lab/ToggleButtonGroup';
import styles from './styles';
import styled from 'styled-components';

/**
 * Toggle button group control.
 * @returns {JSX.Element} - The component to render
 */
const ToggleButtonGroup = ({ children, className, multiselect, readOnly, size, groupLabel, onChange, value, mandatory }) => {
    const sizeClass = `wcux-nxt-toggle-button-group-${size}`;
    const readOnlyClass = readOnly ? 'wcux-nxt-toggle-button-group-readonly' : '';

    let groupedChildren;
    if (Array.isArray(children)) {
        groupedChildren = children.map((child) => React.cloneElement(child, { grouped: true }));
    } else {
        groupedChildren = React.cloneElement(children, { grouped: true });
    }

    return (
        <div data-testid="wcux-nxt-toggle-button-group" className={className}>
            <div className={sizeClass}>
                {groupLabel && (
                    <label
                        className={classNames('wcux-nxt-toggle-button-group-label', 'wcux-nxt-label', {
                            'wcux-nxt-mandatory-indicator': mandatory,
                        })}
                    >
                        {groupLabel}
                    </label>
                )}
                <MuiToggleButtonGroup
                    className={classNames('wcux-nxt-toggle-button-group', readOnlyClass)}
                    exclusive={!multiselect}
                    onChange={onChange}
                    value={value}
                >
                    {groupedChildren}
                </MuiToggleButtonGroup>
            </div>
        </div>
    );
};

ToggleButtonGroup.defaultProps = {
    multiselect: false,
    readOnly: false,
    size: 'normal',
};

ToggleButtonGroup.propTypes = {
    /** Toggle buttons */
    children: PropTypes.node,

    /** CSS class name */
    className: PropTypes.string,

    /** True to allow multiple selection */
    multiselect: PropTypes.bool,

    /** True to prohibit any selections */
    readOnly: PropTypes.bool,

    /**
     * Size of buttons.
     */
    size: PropTypes.oneOf(['small', 'normal', 'large']),

    /** Label for entire group */
    groupLabel: PropTypes.string,

    /** Callback function when the value changes. Signature: function(event: object, value: object) => void */
    onChange: PropTypes.func,

    /** The currently selected value within the group or an array of selected values if multiselect */
    value: PropTypes.any,

    /** Checks if mandatory */
    mandatory: PropTypes.bool,
};

export default styled(ToggleButtonGroup)`
    ${styles}
`;
