/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Admin(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.3">
                <path fillRule="evenodd" clipRule="evenodd" d="M5 3H19V9H5V3ZM7 5H9V7H7V5ZM17 5H11V7H17V5Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5 15H19V21H5V15ZM7 17H9V19H7V17ZM11 17H17V19H11V17Z" />
            </g>
            <path d="M7 5H9V7H7V5Z" />
            <path d="M17 5H11V7H17V5Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2C3 1.44772 3.44772 1 4 1H20C20.5523 1 21 1.44772 21 2V10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10V2ZM19 3H5V9H19V3Z"
            />
            <path d="M7 17H9V19H7V17Z" />
            <path d="M11 17H17V19H11V17Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V14ZM19 15H5V21H19V15Z"
            />
        </SvgIcon>
    );
}
