/**
 * @module webcore-ux/Utils
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

/**
 * Determines if the browser is running on an iPad/iPhone (we don't consider iPod since that's dead)
 * @returns {boolean} - indicates if it's ios
 */
export function isIOS() {
    // TODO: Consider Caching this result
    return !!window.navigator.userAgent.match(/iPad|iPhone|iPod/i);
}

/**
 * Determines if the browser is running Webkit
 * @returns {boolean} - indicates webkit
 */
export function isWebkit() {
    return !!window.navigator.userAgent.match(/Webkit/i);
}

/**
 * Checks if we are running Safari, on iOS, and that it is NOT chrome iOS
 * @returns {boolean} - indicates Safari on iOS
 */
export function isSafariIOS() {
    // Is IOS and Webkit, and Not Chrome IOS
    return isIOS() && isWebkit() && !window.navigator.userAgent.match(/CriOS/i);
}

/**
 * Given an object return true if it is null, undefined, an empty string or an empty array
 * @param {*} obj - the object to check
 * @returns {boolean} whether the object is null or undefined or empty
 */
export function isEmpty(obj) {
    if (obj === null || obj === undefined || obj === '' || (Array.isArray(obj) && obj.length === 0)) {
        return true;
    } else {
        return false;
    }
}

export default {
    isIOS,
    isWebkit,
    isSafariIOS,
    isEmpty,
};
