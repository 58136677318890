/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';

export default function BuildTwoTone(props) {
    return <BuildTwoToneIcon {...props} />;
}
