/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Model(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.3">
                <path d="M3 10L3 14H7V10H3Z" />
                <path d="M17 18V22H21V18H17Z" />
                <path d="M17 14V10H21V14H17Z" />
                <path d="M17 2V6H21V2L17 2Z" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 15H2L2 9H8V11H11V5C11 3.89 11.89 3 13 3L16 3V1L22 1V7L16 7V5H13V11H16V9H22V15H16V13H13V19H16V17H22V23H16V21H13C11.89 21 11 20.11 11 19V13H8L8 15ZM3 14L3 10H7V14H3ZM17 22V18H21V22H17ZM17 10V14H21V10H17ZM17 6V2L21 2V6H17Z"
            />
        </SvgIcon>
    );
}
