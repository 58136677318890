/**
 * @file Component that renders the app header stripe for an app
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Branding } from '../Branding/Branding';
import { MenuTray } from '../MenuTray/MenuTray';
import { MenuTrayContext } from '../MenuTray/MenuTrayContext';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: theme.layout.appBar.height,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.nearWhite.main,
        position: 'absolute',
        top: 0,
        left: 0,
    },
    menuButtonContainer: {
        width: theme.layout.tray.closeWidth,
        display: 'flex',
        justifyContent: 'center',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.nearBlack.main,
    },
    centerContent: {
        display: 'flex',
        flex: 1,
    },
    rightContent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

/**
 * renders the an app header
 * @param props - the properties for the component
 * @returns {JSX.Element}
 * @constructor
 */

export const Stripe = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);

    /**
     * toggle the tray open/closed
     * @param {boolean} open the open state, true for open
     */
    const toggleTray = (open) => {
        setOpen(open);
        toggleExpanded(null);
    };

    /**
     * toggles the expanded section
     * @param {string} expanded the name of the section to be expanded
     */
    const toggleExpanded = (expanded) => {
        setExpanded(expanded);
    };

    return (
        <div className={classNames('wcux-nxt-stripe-container', classes.root, props.className)} data-testid={'wcux-nxt-stripe-container'}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar elevation={1}>
                    <div className={classes.menuButtonContainer}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={() => toggleTray(!open)}
                            data-testid={'wcux-nxt-stripe-main-menu-button'}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>

                    <div id={'wcux-nxt-header-branding'} onClick={props.onClickBranding} data-testid={'wcux-nxt-stripe-branding'}>
                        <Branding brandingText={props.brandingText} />
                    </div>

                    <div className={classes.centerContent}>{props.centerContent}</div>

                    <div className={classes.rightContent}>{props.rightContent}</div>
                </Toolbar>

                <MenuTrayContext.Provider value={{ open, setOpen, expanded, setExpanded }}>
                    <MenuTray
                        anchor={'left'}
                        hideBackdrop={true}
                        onClose={() => {
                            toggleTray(false);
                        }}
                        data-testid={'wcux-nxt-stripe-menu-tray'}
                    >
                        {props.menuContent}
                    </MenuTray>
                </MenuTrayContext.Provider>
            </AppBar>
        </div>
    );
};

Stripe.defaultProps = {
    centerContent: null,
    rightContent: null,
    menuContent: null,
    brandingContent: 'Lumada',
    onClickBranding: () => {},
};

Stripe.propTypes = {
    /** Callback when the logo/header text is clicked */
    onClickBranding: PropTypes.func,
    /** Components to add to the branding container container */
    brandingText: PropTypes.string,
    /** Components to add to the center container */
    centerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** Components to add to the right container */
    rightContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** Components to add to the right container */
    menuContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** Custom class name on the root element */
    className: PropTypes.string,
};

export default Stripe;
