/**
 * @file Readonly field wrapper
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

// Wrapper for a readonly version of editable inputs.
// Not quite the same as "text-display" or other "-display" control configurations which are a legacy view layout.
const ReadOnlyField = ({ label, children, multiline, className }) => {
    return (
        <div className={classNames('wcux-nxt-display-readonly-field', className)} data-testid="display-readonly-field">
            <label className="wcux-nxt-display-readonly-field-label wcux-nxt-label" data-testid="display-readonly-field-label">
                {label}
            </label>
            <div
                className={classNames('wcux-nxt-display-readonly-field-content', {
                    'single-row': !multiline,
                })}
                data-testid="display-readonly-field-content"
            >
                {children}
            </div>
        </div>
    );
};

ReadOnlyField.defaultProps = {
    multiline: false,
};

ReadOnlyField.propTypes = {
    /** Label text */
    label: PropTypes.string,
    /** Whether the value display is multiline */
    multiline: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

const StyledReadOnlyField = styled(ReadOnlyField)`
    ${({ theme }) => `
        overflow: hidden;

        .wcux-nxt-display-readonly-field-content {
            background: inherit;
            min-width: 141px;
            margin-top: ${theme.spacing(1)}px;
            margin-bottom: ${theme.spacing(2)}px;

            &.single-row {
                white-space: nowrap;
                overflow: auto;
            }
        }

        .wcux-nxt-display-readonly-field-content::-webkit-scrollbar {
            display: none;
        }
    `}
`;

StyledReadOnlyField.defaultProps = {
    theme,
};

export default StyledReadOnlyField;
