import styled, { keyframes, css } from 'styled-components';
import _ from 'lodash';
import { theme as defaultTheme } from '../../theme';

export const rectCount = 5,
    animationDuration = 1.2,
    delayRange = 0.4;

const loadingKeyframes = ({ theme }) => keyframes`
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        background-color: ${theme.palette.grey[255]};
    }

    20% {
        transform: scaleY(1);
        background-color: ${theme.palette.primary.main};
    }
`;

const loadingAnimation = css`
    animation: ${loadingKeyframes} ${animationDuration}s infinite ease-in-out;
`;

const createAnimationDelays = () => {
    let styles = '';

    _.times(rectCount, (i) => {
        styles += `
            .wcux-nxt-loading-indicator-rect${i} {
                animation-delay: ${-animationDuration + (delayRange / (rectCount - 1)) * (i - 1)}s;
            }
        `;
    });

    return css`
        ${styles}
    `;
};

const StyledLoadingIndicator = styled.div`
    .wcux-nxt-loading-indicator-rect {
        ${loadingAnimation};
    }

    ${createAnimationDelays()}

    ${({ theme }) => ` 
        margin: 0px auto;
        width: 60px;
        height: 50px;

        &.wcux-nxt-loading-indicator-small {
            width: 30px;
            height: 25px;

            .wcux-nxt-loading-indicator-rect {
                margin: 0px 1px;
                width: 4px;
            }
        }

        &.wcux-nxt-loading-indicator-large {
            width: 90px;
            height: 75px;

            .wcux-nxt-loading-indicator-rect {
                margin: 0px 3px;
                width: 12px;
            }
        }
        
        .wcux-nxt-loading-indicator-rect {
            margin: 0px ${theme.spacing(1) / 4}px; // 0 2px
            height: 100%;
            width: 8px;
            display: inline-block;
            background-color: ${theme.palette.primary.main};
            
        }
    `}
`;

StyledLoadingIndicator.defaultProps = {
    theme: defaultTheme,
};

export { StyledLoadingIndicator };
