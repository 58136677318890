/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Restore(props) {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.5001 3.08997L15.0001 7.58997V3.99997H13.0001V11H20.0001V8.99997H16.4101L20.9101 4.49997L19.5001 3.08997ZM4.00009 13V15H7.59009L3.09009 19.5L4.50009 20.91L9.00009 16.41V20H11.0001V13H4.00009Z"
                    fill="#3F4551"
                />
            </svg>
        </SvgIcon>
    );
}
