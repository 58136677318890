/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function MoreVert(props) {
    return <MoreVertIcon {...props} />;
}
