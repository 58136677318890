/**
 * @module webcore-ux/nextgen/components/FilterToolbar
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from '../../../Utils';
import { wcuxNxtMenuStyle } from '../../sharedStyles/Menu';
import Button from '../Button/Button';
import FilterChip from '../FilterChip/FilterChip';
import MenuList from '../MenuList/MenuList';
import Popup from '../Popup/Popup';

const FilterToolbar = ({
    'data-testid': dataTestId,
    className,
    filterConfig,
    filterSelections,
    onFilterSelectionsChange,
    getStringResource,
}) => {
    const [openAddFilterDropdown, setOpenAddFilterDropdown] = useState(false);
    const [addFilterAnchorEl, setAddFilterAnchorEl] = useState();
    const [recentlySelectedFilter, setRecentlySelectedFilter] = useState();
    const [internalFilterSelections, setInternalFilterSelections] = useState(filterSelections);

    useEffect(() => {
        if (!isEqual(filterSelections, internalFilterSelections)) {
            setInternalFilterSelections(internalFilterSelections);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSelections]);

    useEffect(() => {
        onFilterSelectionsChange(internalFilterSelections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalFilterSelections]);

    // Helper to appropriately update the filterSelections
    const updateFilterSelections = (updater) =>
        setInternalFilterSelections((prev) => {
            const newFilterSelections = cloneDeep(prev);
            updater(newFilterSelections);
            return newFilterSelections;
        });

    const buildAddFilterOptions = () => {
        const addFilterOptions = [];

        filterConfig.forEach((fc) => {
            if (!internalFilterSelections.find((fs) => fs.filterKey === fc.filterKey)) {
                addFilterOptions.push({ value: fc.filterKey, displayLabel: fc.label });
            }
        });

        return addFilterOptions;
    };

    const buildFilterChips = () =>
        internalFilterSelections.map((fs) => {
            const filterKey = fs.filterKey;
            const config = filterConfig.find((c) => c.filterKey === filterKey);
            return (
                <FilterChip
                    data-testid={'wcux-nxt-filterchip-dropdown-' + filterKey}
                    className={'wcux-nxt-filterchip'}
                    key={filterKey}
                    name={filterKey}
                    label={config.label}
                    type={config.type}
                    fetchOptions={config.fetchOptions}
                    nowDateTime={config.nowDateTime}
                    isOpen={recentlySelectedFilter === filterKey}
                    validate={config.validate}
                    initialSelections={fs.selections}
                    onChange={(selections) =>
                        updateFilterSelections((fs) => {
                            const filterSelection = fs.find((s) => s.filterKey === filterKey);
                            if (filterSelection) {
                                filterSelection.selections = selections;
                            } else {
                                fs.push({ filterKey: filterKey, selections: selections });
                            }
                        })
                    }
                    onDelete={() =>
                        updateFilterSelections((fs) => {
                            const filterSelectionIndex = fs.findIndex((s) => s.filterKey === filterKey);
                            if (filterSelectionIndex !== -1) {
                                fs.splice(filterSelectionIndex, 1);
                            }
                        })
                    }
                    getStringResource={getStringResource}
                />
            );
        });

    return (
        <div data-testid={dataTestId + '-filtertoolbar'} className={className}>
            <div className={'wcux-nxt-filtertoolbar'}>
                {buildFilterChips()}
                <div className={'wcux-nxt-filtertoolbar-action-buttons'}>
                    <div className={'wcux-nxt-filtertoolbar-button-container'}>
                        {!isEmpty(internalFilterSelections) && <div className="wcux-nxt-vertical-divider wcux-nxt-right-margin" />}
                        <Button
                            data-testid="wcux-nxt-filtertoolbar-add-filter-button"
                            className={'wcux-nxt-right-margin'}
                            variant="ultra-discrete"
                            disabled={buildAddFilterOptions().length === 0}
                            onClick={(event) => {
                                setOpenAddFilterDropdown((prev) => !prev);
                                setAddFilterAnchorEl(event.currentTarget);
                            }}
                        >
                            {getStringResource('filter.addFilter')}
                        </Button>
                    </div>
                    <div className={'wcux-nxt-filtertoolbar-button-container'}>
                        {!isEmpty(internalFilterSelections) && (
                            <>
                                <div className="wcux-nxt-vertical-divider wcux-nxt-right-margin" />
                                <Button
                                    data-testid="wcux-nxt-filtertoolbar-clear-filters-button"
                                    className={'wcux-nxt-right-margin'}
                                    variant="ultra-discrete"
                                    onClick={() => setInternalFilterSelections([])}
                                >
                                    {getStringResource('filter.clearFilters')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Popup
                data-testid={'wcux-nxt-add-filters-popup'}
                disableArrow={true}
                anchorEl={addFilterAnchorEl}
                isOpen={openAddFilterDropdown}
                onOutsideClick={() => setOpenAddFilterDropdown(false)}
            >
                <MenuList
                    menuOptions={buildAddFilterOptions()}
                    onClick={(filterKey) => {
                        updateFilterSelections((fs) => fs.push({ filterKey: filterKey }));
                        setOpenAddFilterDropdown(false);

                        // Update this to auto-open the attribute chip popup
                        setRecentlySelectedFilter(filterKey);
                    }}
                />
            </Popup>
        </div>
    );
};

const defaultStringResources = {
    'boolean.false': 'False',
    'boolean.notSet': 'Not Set',
    'boolean.true': 'True',
    'dayRangePicker.from': 'From',
    'dayRangePicker.to': 'To',
    'filter.addFilter': '+ Add Filter',
    'filter.addValue': '+ Add Value',
    'filter.all': 'All',
    'filter.clearFilters': 'Clear Filters',
    'filter.clearValues': 'Clear All',
    'filter.dateRange.after': 'After',
    'filter.dateRange.before': 'Before',
    'filter.dateRange.fromError': 'From date cannot be after To date',
    'filter.dateRange.hours': 'hours',
    'filter.dateRange.now': 'Now',
    'filter.dateRange.nowInfoLabel': 'Now Timestamp',
    'filter.dateRange.nowInfoTooltip': "The relative filter control will use this data refresh timestamp as the 'now' reference point.",
    'filter.dateRange.to': 'to',
    'filter.dateRange.toError': 'To date cannot be before From date',
    'filter.noValues': 'No values available',
};

FilterToolbar.defaultProps = {
    'data-testid': 'wcux-nxt-filtertoolbar',
    filterConfig: [],
    filterSelections: [],
    onFilterSelectionsChange: () => {},
    getStringResource: (key) => defaultStringResources[key] || key,
};

FilterToolbar.propTypes = {
    /** A unique identifier used for testing */
    'data-testid': PropTypes.string,
    /** Class name to be appended to the root element of the component */
    className: PropTypes.string,
    /**
     * This determines what filters are available. The order here will be used to determine
     * the order of options shown when clicking on 'Add Filter'.
     * Each object in the array can contain the following:
     *   filterKey: string - A key used to reference the individual filters (e.g column name for DataGrid)
     *   label: The label prop forwarded to the FilterChip
     *   type: string - The type prop forwarded to the FilterChip
     *   fetchOptions: The fetchOptions prop forwarded to the FilterChip
     *   nowDateTime: The nowDateTime prop forwarded to the FilterChip
     *   validate: The validate prop forwarded to the FilterChip
     * Example:
     * [
     *   {
     *     filterKey: 'stringColumnName',
     *     label: 'String Column Header',
     *     type: 'multiselect',
     *     fetchOptions: (pageParam) => { hasMore: false, cursor: 5, results: [] }
     *   },
     *   {
     *     filterKey: 'dateColumnName',
     *     label: 'Date Column Header',
     *     type: 'date',
     *     nowDateTime: new Date(),
     *     validate: (selections) -> { ... }
     *   },
     *   {
     *     filterKey: 'booleanColumnName',
     *     label: 'Boolean Column Header',
     *     type: 'boolean'
     *   }
     * ]
     */
    filterConfig: PropTypes.array,
    /**
     * The current filter state. Filter states that do not exist in this list will not be shown.
     * The order of this array will determine what order the filter chips are displayed.
     * Each object in the array can contain the following:
     *   filterKey: A unique identifier for the filter, typically will be the column name
     *   selections: array - the selections made for this filter. The elements in the array will be determined by the filter type
     * Example:
     * [
     *   {
     *     filterKey: 'stringColumn',
     *     selections: ['a', 'b']
     *   },
     *   {
     *     filterKey: 'dateColumn',
     *     selections: [
     *       {
     *         "date": "2021-08-17T07:00:00.000Z",
     *         "dateType": "absolute",
     *         "relativeDateOffset": null,
     *         "relativeDateOptionValue": RelativeDatePicker.dateOptionEnum.noDate
     *       },
     *       {
     *         "date": "2021-08-18T02:50:40.103Z",
     *         "dateType": "relative",
     *         "relativeDateOffset": 2,
     *         "relativeDateOptionValue": RelativeDatePicker.dateOptionEnum.hoursFromNow
     *       }
     *     ]
     *   },
     *   {
     *     filterKey: 'booleanColumn',
     *     selections: [true]
     *   },
     * ]
     */
    filterSelections: PropTypes.array,
    /**
     * Callback for whenever selection changes for any of the FilterChips
     * The function will be called with the current filterSelections
     * e.g. (filterSelections) => {}
     * The order here matches the order determined by filterSelections.
     * Any new FilterChips added will be appended to the end of the array.
     */
    onFilterSelectionsChange: PropTypes.func,
    /** Function for getting a string resource. Signature: `getStringResource(key)` */
    getStringResource: PropTypes.func,
};

const StyledFilterToolbar = styled(FilterToolbar)`
    ${({ theme }) => `
        .wcux-nxt-filtertoolbar {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 52px;
        }

        .wcux-nxt-filtertoolbar-action-buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .wcux-nxt-filtertoolbar-button-container {
            display: flex;
            align-items: center;
        }

        .wcux-nxt-vertical-divider {
            border-right: 1px solid #C7C9D9;
            height: 34px;
        }

        .wcux-nxt-right-margin {
            margin-right: ${theme.spacing(2)}px;
        }

        .wcux-nxt-filterchip {
            margin: 10px ${theme.spacing(2)}px 10px 0px;
        }

        .wcux-nxt-dropdown__menu {
            ${wcuxNxtMenuStyle}
        }
    `}
`;

export default StyledFilterToolbar;
