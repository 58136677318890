/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Replay(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.20269 13.7973C4.54417 15.2849 5.3004 16.6141 6.34315 17.6569C7.70038 19.0141 9.54302 19.8859 11.5883 19.9896C11.7247 19.9965 11.8619 20 12 20C15.3137 20 18.1569 17.9853 19.3713 15.114C19.7761 14.1569 20 13.1046 20 12C20 7.58172 16.4183 4 12 4C11.7601 4 11.5226 4.01056 11.288 4.03125C10.4428 4.1058 9.63524 4.3118 8.88604 4.62868C6.01472 5.84315 4 8.68629 4 12H5.99999L4.20269 13.7973ZM9.99999 7.5L16 12L9.99999 16.5V7.5Z"
            />
            <path d="M22 12C22 17.5228 17.5229 22 12 22C9.23858 22 6.73858 20.8807 4.92894 19.0711L6.34315 17.6569C7.79087 19.1046 9.79087 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58173 4 4.00001 7.58172 4.00001 12H6L3 15L0 12H2.00001C2.00001 6.47715 6.47716 2 12 2C17.5229 2 22 6.47715 22 12Z" />
            <path d="M16 12L10 7.5V16.5L16 12Z" />
        </SvgIcon>
    );
}
