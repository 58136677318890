/**
 * @file Component that renders a badge component
 * @copyright © 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Badge as MUIBadge } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    badge: {},
}));

/**
 * renders a badge
 * @param {string} color - the badge variant
 * @param {string} badgeContent - the content for the badge
 * @param {node} children - the wrapped children
 * @returns {JSX.Element} - the badge component
 * @constructor
 */
export const Badge = ({ color, badgeContent, children }) => {
    const classes = useStyles();

    return (
        <MUIBadge className={classes.badge} badgeContent={badgeContent} color={color} data-testid={'wcux-nxt-badge'}>
            {children}
        </MUIBadge>
    );
};

Badge.propTypes = {
    /** the typography name variant  */
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']),
    /** The badge content */
    badgeContent: PropTypes.string,
    /** the wrapped children **/
    children: PropTypes.node,
};

export default Badge;
