/**
 * @module webcore-ux/nextgen/components/FilterChip/FilterChip
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { createRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button/Button';
import Chip from '../Chip/Chip';
import Input from '../Input/Input';
import Popup from '../Popup/Popup';
import RelativeDatePicker from '../RelativeDatePicker/RelativeDatePicker';
import * as RelativeDatePickerConstants from '../RelativeDatePicker/constants';
import ToggleButton from '../ToggleButton/ToggleButton';
import ToggleButtonGroup from '../ToggleButton/ToggleButtonGroup';
import Tooltip from '../Tooltip/Tooltip';
import LazyMenuList from '../LazyMenuList/LazyMenuList';
import { CaretDownArrow, Close, InformationCircle2, Search } from '../Icons';
import { isEmpty } from '../../../Utils';
import Divider from './Divider';

export const FILTER_CHIP_TYPE = Object.freeze({
    MULTISELECT: 'multiselect',
    DATETIME: 'datetime',
    BOOLEAN: 'boolean',
    STRING: 'string',
});
export const BOOLEAN_NOT_SET = 'notSet';

const INITIAL_DATE_INFO = Object.freeze({
    date: null,
    dateType: RelativeDatePickerConstants.dateTypeEnum.absolute,
    relativeDateOffset: null,
    relativeDateOptionValue: RelativeDatePickerConstants.dateOptionEnum.noDate,
});

const DEBOUNCE_TIME_MS = 500;

/**
 * An interactive chip with a popup that allows filter options/values to be selected
 * @returns {JSX.Element} FilterChip component
 */
const FilterChip = ({
    'data-testid': dataTestId,
    className,
    name,
    label,
    type,
    fetchOptions,
    initialSelections,
    nowDateTime,
    isOpen,
    validate,
    onChange,
    onDelete,
    getStringResource,
}) => {
    const [selections, setSelections] = useState(initialSelections || buildDefaultInitialSelections(type));
    const prevSelections = useRef(selections);
    const prevChipRef = useRef();
    const chipRef = createRef();
    const [openPopup, setOpenPopup] = useState(false);
    const [filterInput, setFilterInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterChipLabel, setFilterChipLabel] = useState(buildChipTextProps({ type, selections, label, getStringResource }));

    // Multiselect filter specific states
    const [firstOption, setFirstOption] = useState();
    const [multiselectInputHasFocus, setMultiselectInputHasFocus] = useState(false);
    const multiselectInputRef = useRef();
    const multiselectMenuItemsRef = useRef();

    // Date/DateTime filter specific states
    const [isNowDateTimeInfoOpen, setIsNowDateTimeInfoOpen] = useState(false);
    const [fromDateError, setFromDateError] = useState();
    const [toDateError, setToDateError] = useState();
    // these are required to manually force the relative date pickers to re-render when their initial date infos change
    const [fromDateRenderKey, setFromDateRenderKey] = useState(false);
    const [toDateRenderKey, setToDateRenderKey] = useState(false);

    // String filter specific states
    const [searchInput, setSearchInput] = useState();
    const [isSearchInfoOpen, setIsSearchInfoOpen] = useState(false);

    // take care of popup-related requirements based on the chip component
    useLayoutEffect(() => {
        // ensure it only runs on the first time the chip is rendered
        if (!prevChipRef.current && chipRef.current) {
            prevChipRef.current = chipRef.current; // set the popup anchor element
            // ensure the popup gets opened only if the popup is set to be opened
            if (isOpen) {
                setOpenPopup(true);
            }
        }

        // ignore anything else because we only need to run this when the chip is available
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chipRef]);

    // propagate detected changes up to the appropriate listeners. This is debounced to throttle a large volume of on change
    // calls, for example typing the date into the date filter, or quickly selecting multiple multi-select options.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSelectionsUseEffect = useCallback(
        debounce((selections) => {
            switch (type) {
                case FILTER_CHIP_TYPE.MULTISELECT:
                case FILTER_CHIP_TYPE.BOOLEAN:
                case FILTER_CHIP_TYPE.STRING:
                    // [= update things based on significant changes =]

                    if (!isEqual(prevSelections.current, selections)) {
                        onChange(selections);
                        setFilterChipLabel(buildChipTextProps({ type, selections, label, getStringResource }));
                    }

                    break;

                case FILTER_CHIP_TYPE.DATETIME: {
                    const [prevFromDateInfo, prevToDateInfo] = prevSelections.current;
                    const [fromDateInfo, toDateInfo] = selections;

                    // [= validate =]

                    let [fromErr, toErr] = [];

                    // base validation: from <= to
                    if (fromDateInfo && fromDateInfo.date && toDateInfo && toDateInfo.date && fromDateInfo.date > toDateInfo.date) {
                        fromErr = getStringResource('filter.dateRange.fromError');
                        toErr = getStringResource('filter.dateRange.toError');
                    }

                    // custom validation
                    const [customFromErr, customToErr] = validate(selections);

                    if (isEmpty(fromErr)) fromErr = customFromErr;

                    if (isEmpty(toErr)) toErr = customToErr;

                    setFromDateError(fromErr);
                    setToDateError(toErr);

                    // [= update things based on significant changes =]

                    if (!isDateInfoEqual(prevFromDateInfo, fromDateInfo) || !isDateInfoEqual(prevToDateInfo, toDateInfo)) {
                        onChange(selections);
                        setFilterChipLabel(buildChipTextProps({ type, selections, label, getStringResource }));
                    }

                    break;
                }

                default:
            }

            prevSelections.current = selections;
        }, DEBOUNCE_TIME_MS),
        []
    );

    // We do not expect 'debouncedSelectionsStateChange' to change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => debouncedSelectionsUseEffect(selections), [selections]);

    let onClear = () => setSelections(buildDefaultInitialSelections(type));
    let popupBody;

    switch (type) {
        case FILTER_CHIP_TYPE.MULTISELECT:
            popupBody = (
                <>
                    <div className={'wcux-nxt-filterchip-multiselect-values'}>
                        {selections.map((selected) => (
                            <Chip
                                data-testid={`${dataTestId}-value-chip-${selected}`}
                                key={selected}
                                className={'wcux-nxt-filterchip-value-chip'}
                                label={selected}
                                onDelete={() => setSelections((prev) => prev.filter((s) => s !== selected))}
                            />
                        ))}
                        <Input
                            ref={multiselectInputRef}
                            data-testid={`${dataTestId}-filter-input`}
                            placeholder={getStringResource('filter.addValue')}
                            value={filterInput}
                            onChange={(e) => setFilterInput(e.target.value)}
                            onEnterKey={() => {
                                if (!isEmpty(firstOption)) {
                                    setSelections((prev) => prev.concat(firstOption));
                                }

                                setFilterInput('');
                            }}
                            onFocus={() => {
                                setMultiselectInputHasFocus(true);
                            }}
                            onBlur={() => {
                                setMultiselectInputHasFocus(false);
                            }}
                        />
                    </div>
                    <LazyMenuList
                        ref={multiselectMenuItemsRef}
                        className={
                            multiselectInputHasFocus
                                ? 'wcux-nxt-filterchip-lazymenulist wcux-nxt-filterchip-lazymenulist-force-focus'
                                : 'wcux-nxt-filterchip-lazymenulist'
                        }
                        name={name}
                        selectedItems={selections}
                        searchTerm={filterInput}
                        loading={loading}
                        setLoading={setLoading}
                        fetchOptions={fetchOptions}
                        onItemClick={(s) => setSelections((prev) => prev.concat(s))}
                        onFirstAvailableOption={setFirstOption}
                        getItemDisplayLabel={(value, searchValue) => {
                            if (isEmpty(searchValue)) return value;

                            // Split terms and highlight them separately based on what CQ/Elasticsearch would do.
                            const terms = searchValue.split(/[\s*&^!-]/g).reduce((acc, entry) => {
                                // Escape any existing regular expression special characters coming from the user (except ?, because we have our own meaning for that)
                                let modifiedEntry = entry.replace(/[-[\]{}()*+.,\\^$|#]/g, '\\$&');
                                // Replace any '?' with '.', to simulate what ES would do.
                                modifiedEntry = modifiedEntry.replace('?', '.');
                                acc.push(modifiedEntry);
                                return acc;
                            }, []);

                            return (
                                <Highlighter
                                    highlightClassName="wcux-nxt-filterchip-lazymenulist-highlight"
                                    searchWords={terms}
                                    textToHighlight={typeof value === 'string' ? value : String(value)}
                                    autoEscape={false}
                                />
                            );
                        }}
                        getStringResource={getStringResource}
                    />
                </>
            );
            break;
        case FILTER_CHIP_TYPE.DATETIME: {
            onClear = () => {
                // reset everything except the dateType
                setSelections((prev) => {
                    const initSelections = buildDefaultInitialSelections(type);
                    return prev.map((ps, i) => ({ ...initSelections[i], dateType: ps.dateType }));
                });
                // force relative date pickers to re-render because of the update above
                setFromDateRenderKey((prev) => !prev);
                setToDateRenderKey((prev) => !prev);
            };

            const [fromDateInfo, toDateInfo] = selections;
            popupBody = (
                <>
                    <div data-testid={`${dataTestId}-from-date`}>
                        <RelativeDatePicker
                            key={`from${fromDateRenderKey}`}
                            label={getStringResource('dayRangePicker.from')}
                            initialDateInfo={fromDateInfo}
                            onChange={(dateInfo) => setSelections([dateInfo, toDateInfo])}
                            dateTimePart={RelativeDatePickerConstants.dateTimePartEnum.startOfDay}
                            error={fromDateError}
                            allowEmptyDates={true}
                            getStringResource={getStringResource}
                            menuPosition="absolute"
                        />
                    </div>
                    <div data-testid={`${dataTestId}-to-date`}>
                        <RelativeDatePicker
                            key={`to${toDateRenderKey}`}
                            label={getStringResource('dayRangePicker.to')}
                            initialDateInfo={toDateInfo}
                            onChange={(dateInfo) => setSelections([fromDateInfo, dateInfo])}
                            dateTimePart={RelativeDatePickerConstants.dateTimePartEnum.endOfDay}
                            error={toDateError}
                            allowEmptyDates={true}
                            getStringResource={getStringResource}
                            menuPosition="absolute"
                        />
                    </div>
                    {isNowDateTimeShown(fromDateInfo, toDateInfo) && (
                        <div data-testid={`${dataTestId}-now-date`} className="wcux-nxt-filterchip-body-now-datetime">
                            <span className="wcux-nxt-filterchip-body-now-datetime-label">
                                {getStringResource('filter.dateRange.nowInfoLabel')}:
                            </span>
                            <span
                                data-testid={`${dataTestId}-body-now-datetime-value`}
                                className="wcux-nxt-filterchip-body-now-datetime-value"
                            >{`${nowDateTime.toLocaleString()}`}</span>
                            <Tooltip
                                className="wcux-nxt-filterchip-body-now-datetime-tooltip"
                                disableHoverListener
                                arrow
                                open={isNowDateTimeInfoOpen}
                                title={getStringResource('filter.dateRange.nowInfoTooltip')}
                                onOpen={() => setIsNowDateTimeInfoOpen(true)}
                                onClose={() => setIsNowDateTimeInfoOpen(false)}
                            >
                                <Button
                                    variant="ultra-discrete"
                                    startIcon={{ icon: <InformationCircle2 /> }}
                                    onClick={() => setIsNowDateTimeInfoOpen((prev) => !prev)}
                                    className="wcux-nxt-filterchip-right-align-button"
                                />
                            </Tooltip>
                        </div>
                    )}
                </>
            );
            break;
        }

        case FILTER_CHIP_TYPE.BOOLEAN:
            popupBody = (
                <>
                    <div data-testid={`${dataTestId}-boolean-column-name`} className={'wcux-nxt-filterchip-boolean-column-name'}>
                        {getStringResource(label)}
                    </div>
                    <ToggleButtonGroup
                        size="large"
                        multiselect={true}
                        value={selections}
                        onChange={(_event, value) => setSelections(value)}
                    >
                        <ToggleButton key="true" size="normal" value={true}>
                            {getStringResource('boolean.true')}
                        </ToggleButton>
                        <ToggleButton key="false" size="normal" value={false}>
                            {getStringResource('boolean.false')}
                        </ToggleButton>
                        <ToggleButton key={BOOLEAN_NOT_SET} size="normal" value={BOOLEAN_NOT_SET}>
                            {getStringResource('boolean.notSet')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </>
            );
            break;
        case FILTER_CHIP_TYPE.STRING:
            onClear = () => {
                setSelections(buildDefaultInitialSelections(type));
                setSearchInput('');
            };

            popupBody = (
                <>
                    <Input
                        data-testid={`${dataTestId}-string-filter-input`}
                        placeholder={getStringResource('filter.inputText')}
                        icon={
                            <Search data-testid={`${dataTestId}-string-filter-search-icon`} onClick={() => setSelections([searchInput])} />
                        }
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onEnterKey={() => setSelections([searchInput])}
                        onBlur={() => setSelections([searchInput])}
                    />
                    <div data-testid={`${dataTestId}-searchinfo`} className="wcux-nxt-filterchip-body-searchinfo">
                        <span className="wcux-nxt-filterchip-body-searchinfo-label">{getStringResource('filter.searchInfoLabel')}</span>
                        <Tooltip
                            classes={{ tooltip: 'wcux-nxt-filterchip-body-searchinfo-tooltip' }}
                            disableHoverListener
                            arrow
                            open={isSearchInfoOpen}
                            title={getStringResource('filter.searchInfoTooltip')}
                            onOpen={() => setIsSearchInfoOpen(true)}
                            onClose={() => setIsSearchInfoOpen(false)}
                        >
                            <Button
                                variant="ultra-discrete"
                                startIcon={{ icon: <InformationCircle2 /> }}
                                onClick={() => setIsSearchInfoOpen((prev) => !prev)}
                                className="wcux-nxt-filterchip-right-align-button"
                            />
                        </Tooltip>
                    </div>
                </>
            );
            break;
        default:
    }

    return (
        <div className={className}>
            <div ref={chipRef}>
                <Chip
                    data-testid={`${dataTestId}-chip`}
                    className={'wcux-nxt-filterchip-chip'}
                    {...filterChipLabel}
                    color={openPopup ? 'primary' : 'ondark'}
                    avatar={<CaretDownArrow />}
                    avatarPosition="right"
                    onClick={() => setOpenPopup((prev) => !prev)}
                    onDelete={() => onDelete()}
                />
            </div>
            <Popup
                onKeyDown={(e) => {
                    if (multiselectInputHasFocus && multiselectMenuItemsRef.current && e.key === 'ArrowDown') {
                        const secondEl = multiselectMenuItemsRef.current.querySelector('.wcux-nxt-menu-item:nth-child(2)');
                        if (secondEl) {
                            secondEl.focus();
                        } else {
                            const firstEl = multiselectMenuItemsRef.current.querySelector('.wcux-nxt-menu-item:first-of-type');
                            if (firstEl) firstEl.focus();
                            else return;
                        }

                        // When going from the input focus to the MenuList focus via the 'DownArrow' we don't want any other operations happening, like the current scroll position moving unless required
                        // etc. By focusing on the correct element this will be done automatically.
                        e.stopPropagation();
                        e.preventDefault();
                    }

                    if (multiselectInputHasFocus && multiselectMenuItemsRef.current && e.key === 'End') {
                        const lastEl = multiselectMenuItemsRef.current.querySelector('.wcux-nxt-menu-item:last-child');
                        if (lastEl) lastEl.focus();
                        else return;
                    }

                    if (!multiselectInputHasFocus && multiselectMenuItemsRef.current && ['ArrowUp', 'Home'].includes(e.key)) {
                        const currentSelectedMenuItem = multiselectMenuItemsRef.current.querySelector('.wcux-nxt-menu-item:first-of-type');

                        if (multiselectInputRef.current && document.activeElement === currentSelectedMenuItem) {
                            // TRICKY: setTimeout is used here to add the focus call to the end of the JS execution queue so we let the MenuList functionality finish before we go taking focus away from it.
                            // If we don't do this then we get into strange scenarios where we focus the Input field, but the MenuList doesn't process the fact that it has lost focus, and we see multiple
                            // MenuItems highlighted.
                            setTimeout(() => multiselectInputRef.current.focus(), 0);
                        }
                    }
                }}
                tabIndex=" "
                data-testid={`${dataTestId}-popup`}
                className={`wcux-nxt-filterchip-popup wcux-nxt-filterchip-${type}-popup`}
                disableArrow={true}
                placement={'bottom-start'}
                anchorEl={prevChipRef.current}
                isOpen={openPopup}
                onOutsideClick={() => setOpenPopup(false)}
            >
                <div data-testid={`${dataTestId}-toolbar`} className={'wcux-nxt-filterchip-toolbar'}>
                    {/* TRICKY: Set the flex-direction to row-reverse and put the "Close" button
                                 first so that when the "Clear All" button is not shown the "Close"
                                 button will still be justified on the right */}
                    <Button
                        data-testid={`${dataTestId}-close-button`}
                        variant="secondary-ultra-discrete"
                        className="wcux-nxt-filterchip-right-align-button"
                        startIcon={{ icon: <Close /> }}
                        onClick={() => setOpenPopup(false)}
                    />
                    {isClearButtonShown(type, selections) && (
                        <Button
                            data-testid={`${dataTestId}-clear-values-button`}
                            className="wcux-nxt-filterchip-clear-values-button wcux-nxt-filterchip-left-align-button"
                            variant="ultra-discrete"
                            onClick={() => onClear()}
                        >
                            {getStringResource('filter.clearValues')}
                        </Button>
                    )}
                </div>
                <Divider />
                <div data-testid={`${dataTestId}-body`} className="wcux-nxt-filterchip-body">
                    {popupBody}
                </div>
            </Popup>
        </div>
    );
};

const buildDefaultInitialSelections = (type) => {
    switch (type) {
        case FILTER_CHIP_TYPE.MULTISELECT:
        case FILTER_CHIP_TYPE.BOOLEAN:
        case FILTER_CHIP_TYPE.STRING:
            return [];
        case FILTER_CHIP_TYPE.DATETIME:
            return [INITIAL_DATE_INFO, INITIAL_DATE_INFO];
        default:
            return undefined;
    }
};

const isDateInfoEqual = (di1, di2) => {
    // ensure both date types are the same before continuing
    if (di1 && di1.dateType !== di2 && di2.dateType) return false;

    if (di1 && di1.dateType === RelativeDatePicker.dateTypeEnum.relative) {
        // if these are relative date infos, then ignore the resolved absolute dates
        return isEqual({ ...di1, date: undefined }, { ...di2, date: undefined });
    } else {
        return isEqual(di1, di2);
    }
};

const isNowDateTimeShown = (fromDateInfo, toDateInfo) => {
    return (
        (fromDateInfo && fromDateInfo.dateType === RelativeDatePickerConstants.dateTypeEnum.relative) ||
        (toDateInfo && toDateInfo.dateType === RelativeDatePickerConstants.dateTypeEnum.relative)
    );
};

const isClearButtonShown = (type, selections) => {
    switch (type) {
        case FILTER_CHIP_TYPE.DATETIME: {
            const [fromDateInfo, toDateInfo] = selections;
            return fromDateInfo.date || toDateInfo.date;
        }

        default:
            return !isEmpty(selections);
    }
};

/**
 * Builds all of the text props that will be passed into the chip component based on the specified filter chip parameters.
 * @param {object} params - the parameters that would influence what text props will be built
 * @param {FILTER_CHIP_TYPE} params.type - the filter chip type
 * @param {array} params.selections - the filter chip selections
 * @param {string} params.label - the filter chip label
 * @param {func} params.getStringResource - function for getting a string resource
 * @returns {object} the chip text props
 */
const buildChipTextProps = (params) => {
    let chipLabel;
    let chipPostfix;

    switch (params.type) {
        case FILTER_CHIP_TYPE.MULTISELECT:
            chipLabel = isEmpty(params.selections) ? '' : params.selections[params.selections.length - 1];
            chipPostfix = params.selections.length > 1 ? '(+' + (params.selections.length - 1) + ')' : '';
            break;
        case FILTER_CHIP_TYPE.DATETIME: {
            const [fromDateInfo, toDateInfo] = params.selections;
            const fromLabel = buildRelativeDateChipLabel(fromDateInfo, params.getStringResource);
            const toLabel = buildRelativeDateChipLabel(toDateInfo, params.getStringResource);

            if (fromLabel && toLabel) {
                chipLabel = fromLabel + ` ${params.getStringResource('filter.dateRange.to')} ` + toLabel;
            } else if (fromLabel) {
                chipLabel = `${params.getStringResource('filter.dateRange.after')} ` + fromLabel;
            } else if (toLabel) {
                chipLabel = `${params.getStringResource('filter.dateRange.before')} ` + toLabel;
            } else {
                chipLabel = params.getStringResource('filter.all');
            }

            break;
        }

        case FILTER_CHIP_TYPE.BOOLEAN:
            chipLabel = buildBooleanFilterChipLabel(params.selections, params.getStringResource);
            break;
        case FILTER_CHIP_TYPE.STRING:
            if (isEmpty(params.selections[0])) {
                chipLabel = params.getStringResource('filter.all');
            } else {
                chipLabel = '"' + params.selections[0] + '"';
            }

            break;
        default:
    }

    return {
        title: params.getStringResource(params.label) + ': ',
        label: isEmpty(params.selections) ? params.getStringResource('filter.all') : chipLabel,
        postfix: isEmpty(params.selections) || isEmpty(chipPostfix) ? '' : chipPostfix,
    };
};

const buildRelativeDateChipLabel = (dateInfo, getStringResource) => {
    let label;

    if (dateInfo && dateInfo.date) {
        label = dateInfo.date.toLocaleString(navigator.language, { dateStyle: 'short' });

        if (dateInfo.dateType === RelativeDatePickerConstants.dateTypeEnum.relative) {
            const relativeInfo = RelativeDatePickerConstants.getRelativeDateValueOption(dateInfo.relativeDateOptionValue);
            if (relativeInfo.hours === 1) {
                if (dateInfo.relativeDateOffset === 0) {
                    label = getStringResource('filter.dateRange.now');
                } else {
                    const sign =
                        relativeInfo.typeToFrom === RelativeDatePickerConstants.relativeDateDirectionEnum.relativeDateFrom ? '-' : '+';

                    label =
                        sign + parseInt(dateInfo.relativeDateOffset).toLocaleString() + ' ' + getStringResource('filter.dateRange.hours');
                }
            }

            if (label) label = '[' + label + ']';
        }
    }

    return label;
};

const buildBooleanFilterChipLabel = (values, getStringResource) => {
    let label;
    if (isEmpty(values) || values.length === 3) {
        label = getStringResource('filter.all');
    } else if (values.length === 1) {
        label = lookupBooleanLocaleText(values[0], getStringResource);
    } else if (values.length === 2) {
        label = lookupBooleanLocaleText(values[0], getStringResource) + ' & ' + lookupBooleanLocaleText(values[1], getStringResource);
    }

    return label;
};

const lookupBooleanLocaleText = (value, getStringResource) => {
    switch (value) {
        case true:
            return getStringResource('boolean.true');
        case false:
            return getStringResource('boolean.false');
        default:
            return getStringResource('boolean.notSet');
    }
};

const defaultStringResource = {
    'boolean.false': 'False',
    'boolean.notSet': 'Not Set',
    'boolean.true': 'True',
    'dayRangePicker.from': 'From',
    'dayRangePicker.to': 'To',
    'filter.addValue': '+ Add Value',
    'filter.all': 'All',
    'filter.clearValues': 'Clear All',
    'filter.dateRange.after': 'After',
    'filter.dateRange.before': 'Before',
    'filter.dateRange.fromError': 'From date cannot be after To date',
    'filter.dateRange.hours': 'hours',
    'filter.dateRange.now': 'Now',
    'filter.dateRange.nowInfoLabel': 'Now Timestamp',
    'filter.dateRange.nowInfoTooltip': 'The relative filter control will use this data refresh timestamp as the "now" reference point.',
    'filter.dateRange.to': 'to',
    'filter.dateRange.toError': 'To date cannot be before From date',
    'filter.noValues': 'No values available',
};

FilterChip.defaultProps = {
    'data-testid': 'wcux-nxt-filterchip',
    name: undefined,
    label: undefined,
    fetchOptions: () => ({ results: [], cursor: 0, hasMore: false }),
    initialSelections: undefined,
    nowDateTime: new Date(),
    isOpen: false,
    validate: () => [],
    onChange: () => {},
    onDelete: () => {},
    getStringResource: (key) => defaultStringResource[key] || key,
};

FilterChip.propTypes = {
    /** A unique identifier used for testing */
    'data-testid': PropTypes.string,
    /** Class name to be appended to the root element of the component */
    className: PropTypes.string,
    /** A unique identifier used by LazyMenuList */
    name: PropTypes.string,
    /** The i18n key or filter chip display label. This prefixes any selected values. */
    label: PropTypes.string,
    /** The type of control inside the popup */
    type: PropTypes.oneOf(Object.values(FILTER_CHIP_TYPE)).isRequired,
    /** The fetchOptions prop forwarded to the LazyMenuList */
    fetchOptions: PropTypes.func,
    /** The selections to initialize the component with */
    initialSelections: PropTypes.array,
    /** The now date time reference point. Used if `type` is `date` or `datetime`. */
    nowDateTime: PropTypes.object,
    /** Whether to have the popup be opened */
    isOpen: PropTypes.bool,
    /**
     * Callback that is used to validate the selections in order to display any error messages on the relevant input fields
     * that were used to make these selections.
     * @param {Array} selections the values selected will vary depending on the `type`
     * + MULTISELECT: An array of strings
     * + DATETIME: An array of date infos with a constant size of 2.
     *   The first element is for the 'from' part and the second is for the 'to' part.
     *   The date info object is based on the relative date picker internals, of the form:
     *   ```
     *   {
     *       date: <Date>,
     *       dateType: <RelativeDatePicker.dateTypeEnum>,
     *       relativeDateOffset: <Integer>,
     *       relativeDateOptionValue: <RelativeDatePicker.relativeDateValueOptions>,
     *   }
     *   ```
     * @returns A list of error strings to be processed accordingly.
     * The position of each error string in the list needs to correspond to the position of the selection that is at fault.
     * Note: At the time of writing, only the ‘datetime’ type has validation support. All other types will ignore this.
     */
    validate: PropTypes.func,
    /**
     * Callback for whenever a change had been made to this filter chip.
     * @param {Array} selections the values selected will vary depending on the `type`
     * + MULTISELECT: An array of strings
     * + DATETIME: An array of date infos with a constant size of 2.
     *   The first element is for the 'from' part and the second is for the 'to' part.
     *   The date info object is based on the relative date picker internals, of the form:
     *   ```
     *   {
     *       date: <Date>,
     *       dateType: <RelativeDatePicker.dateTypeEnum>,
     *       relativeDateOffset: <Integer>,
     *       relativeDateOptionValue: <RelativeDatePicker.relativeDateValueOptions>,
     *   }
     *   ```
     * + BOOLEAN: An array consisting of any combination of the following: [true, false, 'notSet']
     */
    onChange: PropTypes.func,
    /** Callback for whenever the filter chip is deleted. */
    onDelete: PropTypes.func,
    /** Function for getting a string resource. Signature: `getStringResource(key)` */
    getStringResource: PropTypes.func,
};

const StyledFilterChip = styled(FilterChip)`
    ${({ theme }) => `
        .wcux-nxt-filterchip-chip {
            max-width: 400px;
        }

        .wcux-nxt-filterchip-popup {
            min-width: 300px;
            max-width: 560px;

            .wcux-nxt-popup-content-wrapper {
                display: flex;
                flex-direction: column;
            }
        }

        .wcux-nxt-filterchip-multiselect-popup {
            .wcux-nxt-popup-content-wrapper {
                overflow-y: unset;
            }

            .wcux-nxt-filterchip-body {
                // Always show the scroll and set a negative margin so that the scroll will show in the padding space
                overflow-y: scroll;
                margin-right: -16px;
            }
        }

        .wcux-nxt-filterchip-date-popup, .wcux-nxt-filterchip-datetime-popup {
            .wcux-nxt-popup-content-wrapper {
                overflow: visible;
            }
        }

        .wcux-nxt-popup-content-wrapper {
            padding: ${theme.spacing(2, 2, 2, 2)};
        }

        .wcux-nxt-filterchip-toolbar {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .wcux-nxt-filterchip-multiselect-values {
            display: flex;
            flex-wrap: wrap;
            margin: 0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px;
            border: solid 1px ${theme.palette.secondary.main};
            padding: 4px ${theme.spacing(2)}px 4px ${theme.spacing(2)}px;

            .wcux-nxt-input-container {
                margin: 0px 0px 0px ${theme.spacing(1)}px;
                width: 100%;

                .wcux-nxt-input {
                    border: none;

                    input {
                        padding: 0px;
                    }
                }
            }
        }

        .wcux-nxt-filterchip-left-align-button {
            margin-left: -${theme.spacing(1)}px;
        }

        .wcux-nxt-filterchip-right-align-button {
            margin-right: -10px;
        }

        .wcux-nxt-filterchip-value-chip {
            margin: 4px ${theme.spacing(1)}px 4px 0px;
            max-width: 325px;
        }

        .wcux-nxt-filterchip-body {
            & > * {
                // This aligns all the content to the right.
                margin-left: auto;
                margin-right: 0;
            }

            .wcux-nxt-dropdown__menu {
                // This is a fix for drop downs rendering strange when there isn't quite enough space for them.
                height: fit-content;
            }

            .wcux-nxt-filterchip-body-now-datetime {
                font-size: ${theme.typography.fontSizes.small};
                display: flex;
                align-items: center;

                & > span {
                    padding-left: 4px;
                }
                width: fit-content; // This makes sure the text div/spans only take the room they need and nothing more (allowing it to be right aligned).
            }

            .wcux-nxt-filterchip-body-now-datetime-value {
                color: ${theme.palette.primary.main}
            }

            #relative-date-picker {
                max-height: unset;
                margin-bottom: 10px;

                .wcux-nxt-input-container {
                    height: unset;
                }
            }
        }

        .wcux-nxt-filterchip-boolean-column-name {
            font-size: ${theme.typography.body1.fontSize};
            line-height: 20px;
            color: ${theme.typography.secondary};
            padding-bottom: ${theme.spacing(2)}px;
        }

        .wcux-nxt-filterchip-lazymenulist-highlight {
            background-color: ${theme.palette.primary.lightest};
        }

        .wcux-nxt-filterchip-lazymenulist-force-focus .wcux-nxt-menu-item:first-of-type {
            background-color: ${theme.palette.action.selected};
        }
    `}
`;

export default StyledFilterChip;
