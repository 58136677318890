/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PlaceIcon from '@material-ui/icons/PlaceTwoTone';

export default function PlaceTwoTone(props) {
    return <PlaceIcon {...props} />;
}
