import { css } from 'styled-components';
import { wcuxNxtLabelClass } from '../../sharedStyles/Form';

const formStyles = css`
    ${wcuxNxtLabelClass}
    ${({ theme }) => `
    font-family: ${theme.typography.fontFamily};

    .wcux-nxt-page {
        .wcux-nxt-section {
            margin-top: ${theme.spacing(3)}px;

            .wcux-nxt-section-title {
                color: ${theme.palette.primary.dark};
            }

            .wcux-nxt-expansion-panel-summary-text {
                flex: 1;

                .wcux-nxt-section-collapsed-title {
                    display: flex;
                    align-items: center;

                    .wcux-nxt-section-collapsed-title-left {
                        margin-right: 15px;
                    }

                    .wcux-nxt-section-collapsed-title-right {
                        flex: 1;

                        .wcux-nxt-display-field {
                            align-items: center;
                            justify-content: flex-end;

                            .wcux-nxt-display-field-label {
                                flex: unset;
                            }

                            .wcux-nxt-display-field-data {
                                flex: unset;
                            }
                        }
                    }
                }
            }

            .wcux-nxt-section-groupbox {
                padding: 25px;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
                border: 1px solid ${theme.palette.grey[200]};
            }

            .wcux-nxt-section-title {
                margin-bottom: ${theme.spacing(3)}px;
            }

            .wcux-nxt-input-container,
            .wcux-nxt-dropdown-root,
            .wcux-nxt-checkbox,
            .wcux-nxt-tristate,
            .wcux-nxt-toggle-switch,
            .wcux-nxt-editable-table,
            .wcux-nxt-attachment-control {
                margin-bottom: ${theme.spacing(2)}px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .wcux-nxt-tristate .wcux-nxt-checkbox {
                margin-bottom: 0;
            }

            .wcux-nxt-readonly-table:not(.wcux-nxt-editable-table) {
                &:not(:last-child) {
                    margin-bottom: ${theme.spacing(1)}px;
                }
            }

            .wcux-nxt-display-readonly-date-range {
                display: flex;
                .wcux-nxt-display-readonly-date-range-field {
                    display: flex;
                    flex-direction: column;
                    .wcux-nxt-display-readonly-date-range-field-content {
                        height: ${theme.spacing(5)}px;
                        background: inherit;
                        margin-top: ${theme.spacing(1)}px;
                        margin-right: ${theme.spacing(3)}px;
                    }
                }
            }

            .wcux-nxt-display-field {
                display: flex;
                width: 100%;
                font-size: ${theme.typography.fontSizes.medium};

                &:not(:last-child) {
                    padding-bottom: 8px;
                }

                .wcux-nxt-display-field-icon {
                    width: 12px;
                    height: 12px;
                    color: #ff7300;
                }

                .wcux-nxt-display-field-status {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    padding: 0 5px 0 0;
                }

                .wcux-nxt-display-field-label {
                    flex: 1;
                    color: ${theme.typography.secondary};
                    word-wrap: break-word;
                    vertical-align: top;
                }

                .wcux-nxt-display-field-data {
                    flex: 2;
                    padding-left: 15px;
                    color: ${theme.typography.primary};
                    word-wrap: break-word;
                    vertical-align: top;

                    a:link {
                        color: ${theme.palette.hyperlink.main}; // See theme notes; may not be finalized
                    }

                    a:visited {
                        color: ${theme.palette.hyperlink.visited}; // See theme notes; may not be finalized
                    }

                    .boolean-display-checkbox {
                        font-size: ${theme.typography.fontSizes.medium};
                        position: relative;
                        top: 2px;
                    }

                    .wcux-nxt-display-field-boolean-container {
                        display: inline-block;
                    }

                    .wcux-nxt-display-field-true-label,
                    .wcux-nxt-display-field-false-label {
                        display: inline;
                        vertical-align: top;
                    }

                    .wcux-nxt-display-field-true-label {
                        padding-right: 5px;
                        margin-right: 5px;
                        border-right: 1px solid ${theme.palette.grey[70]};
                    }
                }
            }

            .wcux-nxt-section-separator {
                width: 100%;
                height: 0px;
                margin-bottom: 0;
                margin-top: ${theme.spacing(1)}px;
                border-bottom: 0;
                border-left: 0;
                border-right: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
            }
        }

        .wcux-nxt-expansion-panel-toggle-wrapper {
            padding: 0 15px;
            display: flex;
            justify-content: flex-end;
            .wcux-nxt-expansion-panel-toggle-expand,
            .wcux-nxt-expansion-panel-toggle-collapse {
                font-size: ${theme.typography.fontSizes.medium};
                color: ${theme.palette.hyperlink.main}; // See theme notes; may not be finalized
                cursor: pointer;
            }
        }

        .wcux-nxt-form-table-row-btn-container {
            min-width: 100px;
        }
    }
`}
`;

export default formStyles;
