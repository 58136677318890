/**
 * @file Actions Toobar
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { Button } from '../../components';
import { Clear } from '../Icons';
import ActionButtons from '../ActionButtons/ActionButtons';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

// JSS styling for this component
const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.grey[255],
        height: 66,
        padding: '0px 20px',
        borderBottom: `1px solid ${theme.palette.grey[208]}`,
    },
    leftContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectionText: {
        color: alpha(theme.palette.grey[104], 0.42),
        fontWeight: '600',
        fontSize: theme.typography.fontSizes.large,
    },
    selectionTextSpacing: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    clearSelection: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
        fontWeight: '600',
    },
    actionButtons: {},
}));

const defaultLocales = {
    done: 'Done',
    selectionText: '0 Selections on page',
    clearSelections: 'Clear all Selections',
    selectRows: 'Select rows to perform actions',
};

const ActionsToolbar = ({ selectedRowsCount, onDoneClick, onClearSelectionClick, locales, isLoadingActions, actions = [] }) => {
    const classes = useStyles();

    const mergedLocales = { ...defaultLocales, ...locales };

    return (
        <div className={classNames('wcux-nxt-actions-toolbar', classes.toolbar)} data-testid="wcux-nxt-actions-toolbar">
            <div className={classNames('wcux-nxt-actions-toolbar-left-content', classes.leftContent)}>
                <Button
                    variant="secondary"
                    startIcon={{ icon: <Clear fontSize="small" /> }}
                    onClick={onDoneClick}
                    data-testid="wcux-nxt-actions-toolbar-done-button"
                >
                    {mergedLocales.done}
                </Button>
                {selectedRowsCount === 0 && (
                    <span className={classNames('wcux-nxt-actions-toolbar-selection', classes.selectionText, classes.selectionTextSpacing)}>
                        {mergedLocales.selectionText}
                    </span>
                )}
                {selectedRowsCount > 0 && (
                    <Button
                        variant="ultra-discrete"
                        onClick={() => {
                            onClearSelectionClick();
                        }}
                        className={classNames('wcux-nxt-actions-toolbar-clear-selection', classes.clearSelection)}
                    >
                        {mergedLocales.clearSelections}
                    </Button>
                )}
            </div>
            {selectedRowsCount === 0 && (
                <span className={classNames('wcux-nxt-actions-toolbar-selection-hint', classes.selectionText)}>
                    {mergedLocales.selectRows}
                </span>
            )}
            {isLoadingActions && (
                <span data-testid="wcux-nxt-toolbar-action-loader">
                    <LoadingIndicator size="small" />
                </span>
            )}
            {!isLoadingActions && actions.length > 0 && (
                <div className={classNames('wcux-nxt-actions-toolbar-action-buttons', classes.actionButtons)}>
                    <ActionButtons actions={actions} />
                </div>
            )}
        </div>
    );
};

ActionsToolbar.propTypes = {
    selectedRowsCount: PropTypes.number,
    onDoneClick: PropTypes.func,
    onClearSelectionClick: PropTypes.func,
    /**
     * Show an indicator for actions being loaded. Any provided `actions` will not be displayed if this is true.
     */
    isLoadingActions: PropTypes.bool,
    locales: PropTypes.shape({
        done: PropTypes.string,
        selectionText: PropTypes.string,
        clearSelections: PropTypes.string,
        selectRows: PropTypes.string,
    }),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            /** Value of the string to display */
            value: PropTypes.string,
            /** click function to fire when button is pressed */
            onClick: PropTypes.func,
            /** Shows Icon when provided. Currently shows all icons on bar except at smallest breakpoint
            where it is in the kebab menu */
            icon: PropTypes.node,
            /** Boolean to disable the button */
            disabled: PropTypes.bool,
        })
    ),
};

export default ActionsToolbar;
