/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';

export default function ShoppingCartTwoTone(props) {
    return <ShoppingCartTwoToneIcon {...props} />;
}
