/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

export default function HelpTwoTone(props) {
    return <HelpTwoToneIcon {...props} />;
}
