/**
 * @file constants for nextgen components
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

// unsecured apps for app wrapper container and menu content
export const unsecuredApps = ['homeapp', 'modelapp', 'helpapp'];

// supported widget types
export const widgetTypes = {
    kpi: 1,
    chart: 2,
    attachments: 3,
    count: 4,
    dataGrid: 5,
    attributes: 6,
};

// supported chart types
export const widgetChartTypes = {
    pie: 1,
    bar: 2,
    line: 3,
};

// supported count widget types
export const widgetCountTypes = {
    wds: 1,
    cq: 2,
};
