/**
 * @file CardItem component
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useCardGridLayoutContext } from '.';
import { Expand, OpenInNew, Restore } from '../Icons';
import { IconToolbar } from '..';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.surface5 || '#fff',
        height: 'inherit',
        borderRadius: '8px',
        boxShadow: '0px 0.5px 1.5px 0px rgba(0, 0, 0, 0.19), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)', // Custom box-shadow
    },
    header: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.surface5 || '#fff',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)', // Custom box-shadow
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '56px',
        overflow: 'hidden',
    },
    headerTextContainer: {
        display: 'contents',
        verticalAlign: 'middle',
        height: '56px',
        overflow: 'hidden',
    },
    headerText: {
        width: '100%',
        height: 'fit-content',
        lineHeight: 'normal',
        fontWeight: 600,
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: theme.spacing(3),
        '&.-has-actions': {
            paddingRight: 0, // Decrease padding between text and actions when actions exist
        },
    },
    headerActions: {
        display: 'flex',
    },
    container: {},
    inner: {
        height: '100%',
        position: 'relative', // For positioning the drag handle
        display: 'flex',
        flexDirection: 'column',
    },
}));

const Container = styled.div`
    padding: ${({ theme }) => theme.spacing(3)}px;
    padding-top: ${({ theme }) => theme.spacing(1)}px;
    margin-top: ${({ hasHeader }) => (hasHeader ? 56 : 0)}px;
    overflow: auto;
`;

/**
 * CardItem Component
 * @param {object} props - The props for the component
 * @returns {JSX.Element} - The component to render
 */
export const CardItem = ({
    id,
    theme,
    useStyles: customUseStyles,
    children,
    headerContent,
    className,
    actions,
    showMaximizeButton,
    openInNew,
    ...other
}) => {
    const headerRef = useRef({ current: {} });
    const classes = useStyles(theme);
    const cardGridContext = useCardGridLayoutContext();

    let customClasses = {};
    if (typeof customUseStyles === 'function') {
        customClasses = customUseStyles();
    }

    const allActions = actions ? [...actions] : [];

    if (showMaximizeButton || openInNew) {
        if (cardGridContext && id) {
            if (openInNew) {
                allActions.push({
                    icon: <OpenInNew />,
                    value: 'openInNew',
                    'data-testid': 'testid-openinnew-' + id,
                    onClick: () => cardGridContext.openInNew(id),
                });
            }

            if (showMaximizeButton) {
                allActions.push({
                    icon: cardGridContext.maximizedCardId === id ? <Restore /> : <Expand />,
                    value: 'maximize',
                    'data-testid': (cardGridContext.maximizedCardId === id ? 'testid-restore-' : 'testid-expand-') + id,
                    onClick: () => cardGridContext.maximizeCard(id),
                });
            }
        } else {
            throw new Error(
                'when using showMaximizeButton for a <CardItem>, it should have a unique id and wrap with <CardGridLayoutProvider> component'
            );
        }
    }

    return (
        <Card className={classNames(className, classes.root, customClasses.root)} {...other}>
            <div className={classNames('wcux-nxt-card-inner', classes.inner)} data-testid="wcux-nxt-card-inner">
                {(headerContent || allActions.length !== 0) && (
                    <div ref={headerRef} className={classNames('wcux-nxt-card-header', classes.header)} data-testid="wcux-nxt-card-header">
                        <div className={classNames(classes.headerTextContainer)}>
                            <Typography
                                variant="h6"
                                className={classNames('wcux-nxt-card-header-title', classes.headerText, {
                                    '-has-actions': actions,
                                })}
                            >
                                {headerContent}
                            </Typography>
                        </div>
                        <IconToolbar buttons={allActions} />
                    </div>
                )}
                <Container
                    className={classNames('wcux-nxt-card-body', classes.container, customClasses.container)}
                    data-testid="wcux-nxt-card-body"
                    hasHeader={headerContent || allActions.length !== 0}
                >
                    {children}
                </Container>
            </div>
        </Card>
    );
};

CardItem.defaultProps = {
    actions: null,
};

CardItem.propTypes = {
    /** Class name to be applied to the root */
    className: PropTypes.string,
    /** Id for the Card. It is mandatory if you want to use either showMaximizeButton or openInNew */
    id: PropTypes.string,
    theme: PropTypes.object,
    children: PropTypes.node.isRequired,
    useStyles: PropTypes.func,
    /** It will display maximize button */
    showMaximizeButton: PropTypes.bool,
    /** It will display open in new tab button */
    openInNew: PropTypes.bool,
    /** Title text/node that goes in the card header */
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Action buttons in the header, if any */
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            /** Eg. an SVG icon for the button contents */
            icon: PropTypes.node.isRequired,
            /** Unique value to be supplied to the click callback. Also to be used as the key. */
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            /** Signature: (value: string | number | undefined) -> void */
            onClick: PropTypes.func,
            /** Tristate. If true, applies toggled on styles; if false, applies toggled off styles. If not supplied, displays a regular button. */
            isToggledOn: PropTypes.bool,
            'data-testid': PropTypes.string,
            /** Tooltip content */
            title: PropTypes.string,
            /** Additional tooltip props such as placement */
            tooltipProps: PropTypes.shape({
                placement: PropTypes.oneOf([
                    'bottom-end',
                    'bottom-start',
                    'bottom',
                    'left-end',
                    'left-start',
                    'left',
                    'right-end',
                    'right-start',
                    'right',
                    'top-end',
                    'top-start',
                    'top',
                ]),
            }),
        })
    ),
};

export default CardItem;
