/**
 * @file Component that renders a tray menu item
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { default as MenuTrayContext } from '../MenuTrayContext';
import ExpandMore from '../../Icons/ExpandMore';
import ExpandLess from '../../Icons/ExpandLess';
import classNames from 'classnames';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { alpha } from '@material-ui/core';

const Styles = styled.div`
    ${({ theme }) => `
        .wcux-nxt-tray-menu-item-root {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        .wcux-nxt-tray-menu-item {
            padding-left: 0;
        }
        .wcux-nxt-tray-menu-item:hover {
            background-color: ${alpha(theme.palette.primary.main, 0.3)}; !important;
        }
        .wcux-nxt-tray-menu-item.activated {
            background-color: ${theme.palette.primary.main} !important;
        }
        .wcux-nxt-tray-menu-item-text {
            color: ${theme.palette.common.nearWhite};
            font-size: ${theme.typography.body1.fontSize};
        }
        .wcux-nxt-tray-menu-item-icon-root {
            width: ${theme.layout.tray.closeWidth}px;
            display: block;
            text-align: center;
        }
        .wcux-nxt-tray-menu-item-icon {
            color: ${theme.palette.common.nearWhite};
        }
        .wcux-nxt-tray-menu-item-noicon-root {
            width: 0;
            display: block;
        }
        .wcux-nxt-tray-menu-item-svg-icon-root {
            font-size: 1.7rem;
        }
        .wcux-nxt-tray-menu-item-icon-activated {
            color: ${theme.palette.common.nearWhite};
        }
        .wcux-nxt-tray-menu-item-icon-text-display {
            display: block;
            color: ${theme.palette.common.nearWhite};
            text-align: center;
            font-size: 11px;
        }
        .wcux-nxt-tray-menu-item-icon-text-hidden {
            display: none;
        }
        .activated .wcux-nxt-tray-menu-item-text {
            color: ${theme.palette.common.nearWhite};
            font-weight: ${theme.typography.body2.fontWeight + 200};
        }
        .activated .wcux-nxt-tray-menu-item-icon-text-display {
            color: ${theme.palette.common.nearWhite};
        }
        .activated-wrapper {
            background-color: ${theme.palette.background.default};
        }
        .visible {
            display: block;
        }
        .wcux-nxt-tray-menu-no-icon {
            width: 20px;
        }
        .wcux-nxt-tray-menu-expanded-section {
            border-top: solid 1px  ${theme.palette.secondary.main};
            border-bottom: solid 1px  ${theme.palette.secondary.main};
            padding: 0px;
        }
        .nested {
            background-color: ${theme.palette.common.nearBlack};
        }
    `}
`;

/**
 * render an application tray menu/navigation item
 * @param {object} menuItem the menu item config object
 * @returns {JSX.Element} the tray menu item component
 * @constructor
 */
const TrayMenuItem = ({ menuItem }) => {
    const { open, setOpen } = useContext(MenuTrayContext);
    const [expanded, setExpanded] = useState('');

    /**
     * expands a single top expandable menu item
     * @param {string}expandedIn the section name to expand
     */
    const handleExpandClick = (expandedIn) => {
        setOpen(true);
        // if section clicked is the current expanded section, collapse the section else expand the section
        setExpanded(expandedIn === expanded ? '' : expandedIn);
    };

    /**
     * handles on click events for links
     */
    const handleOnClick = () => {
        if (menuItem.onClick) {
            menuItem.onClick();
        }
    };

    /**
     * renders a menu icon
     * @param {object} menuItem - a menu item config object
     * @returns {JSX.Element} the menu item icon
     * @constructor
     */
    const MenuItemIcon = ({ menuItem }) => (
        <ListItemIcon
            classes={{
                root: menuItem.icon ? 'wcux-nxt-tray-menu-item-icon-root' : 'wcux-nxt-tray-menu-item-noicon-root',
            }}
        >
            {menuItem.icon ? (
                <div
                    className={classNames({
                        'wcux-nxt-tray-menu-item-icon-activated': menuItem.activated,
                        'wcux-nxt-tray-menu-item-icon': !menuItem.activated,
                    })}
                >
                    {React.cloneElement(menuItem.icon, {
                        classes: { root: 'wcux-nxt-tray-menu-item-svg-icon-root' },
                    })}
                </div>
            ) : (
                <div />
            )}
            <div
                className={classNames({
                    'wcux-nxt-tray-menu-item-icon-text-display': !open,
                    'wcux-nxt-tray-menu-item-icon-text-hidden': open,
                })}
            >
                {menuItem.iconText}
            </div>
        </ListItemIcon>
    );

    /**
     * creates an expandable menu item
     * @param {object} menuItem - a menu item config object
     * @returns {JSX.Element} - the component
     * @constructor
     */
    const MenuItemExpandable = ({ menuItem }) => {
        return (
            <>
                {!open && menuItem.link && (
                    <Link
                        href={!menuItem.activated ? menuItem.link : ''}
                        target={menuItem.newWindow ? '_blank' : ''}
                        data-testid={'wcux-nxt-tray-menu-item-link'}
                        className={'wcux-nxt-tray-menu-item-link'}
                        underline={'none'}
                    >
                        <ListItem
                            button
                            className={classNames('wcux-nxt-tray-menu-item', {
                                activated: menuItem.activated,
                                nested: !menuItem.icon,
                            })}
                            classes={{ root: 'wcux-nxt-tray-menu-item-root' }}
                            data-testid={'wcux-nxt-tray-menu-item'}
                        >
                            <MenuItemIcon menuItem={menuItem} />
                            <ListItemText primary={menuItem.displayText} classes={{ primary: 'wcux-nxt-tray-menu-item-text' }} />
                        </ListItem>
                    </Link>
                )}
                {(open || !menuItem.link) && (
                    <ListItem
                        button
                        className={classNames({
                            'wcux-nxt-tray-menu-item': true,
                            activated: menuItem.activated,
                        })}
                        onClick={() => handleExpandClick(menuItem.displayText)}
                        data-testid={'wcux-nxt-tray-menu-item'}
                    >
                        <MenuItemIcon menuItem={menuItem} />
                        <ListItemText primary={menuItem.displayText} classes={{ primary: 'wcux-nxt-tray-menu-item-text' }} />
                        {expanded === menuItem.displayText ? (
                            <ExpandLess className={'wcux-nxt-tray-menu-item-icon'} />
                        ) : (
                            <ExpandMore className={'wcux-nxt-tray-menu-item-icon'} />
                        )}
                    </ListItem>
                )}
            </>
        );
    };

    const MenuItemFixed = ({ menuItem }) => {
        return (
            <>
                <Link
                    href={!menuItem.activated ? menuItem.link : ''}
                    target={menuItem.newWindow ? '_blank' : ''}
                    data-testid={'wcux-nxt-tray-menu-item-link'}
                    onClick={handleOnClick}
                    className={'.wcux-nxt-tray-menu-item-link'}
                    underline={'none'}
                >
                    <ListItem
                        button
                        className={classNames('wcux-nxt-tray-menu-item', {
                            activated: menuItem.activated,
                            nested: !menuItem.icon,
                        })}
                        classes={{ root: 'wcux-nxt-tray-menu-item-root' }}
                        data-testid={'wcux-nxt-tray-menu-item'}
                    >
                        <MenuItemIcon menuItem={menuItem} />
                        <ListItemText primary={menuItem.displayText} classes={{ primary: 'wcux-nxt-tray-menu-item-text' }} />
                    </ListItem>
                </Link>
            </>
        );
    };

    return (
        <Styles>
            {!menuItem.childMenuItems || !menuItem.childMenuItems.length ? (
                <MenuItemFixed menuItem={menuItem} />
            ) : (
                <>
                    <MenuItemExpandable menuItem={menuItem} />

                    <Collapse in={expanded === menuItem.displayText} timeout="auto" unmountOnExit>
                        <List component="div" className={'wcux-nxt-tray-menu-expanded-section'}>
                            {menuItem.childMenuItems.map((childMenuItem, index) => (
                                <TrayMenuItem menuItem={childMenuItem} key={index} />
                            ))}
                        </List>
                    </Collapse>
                </>
            )}
        </Styles>
    );
};

TrayMenuItem.defaultProps = {
    menuItem: {},
};

TrayMenuItem.propTypes = {
    /** menuItem object **/
    menuItem: PropTypes.shape({
        /** the active state of the menu item **/
        activated: PropTypes.bool,
        /** the display text for a menu item when expanded **/
        displayText: PropTypes.string,
        /** the icon of a menu item **/
        icon: PropTypes.object,
        /** the text to display below an icon when collapsed **/
        iconText: PropTypes.string,
        /** the link to open when clicked **/
        link: PropTypes.string,
        /** the text when menu tray closed **/
        linkText: PropTypes.string,
        /** onClick handler (optional) **/
        onClick: PropTypes.func,
        /** if the menu item is expanded **/
        expanded: PropTypes.bool,
        /** open in a new window **/
        newWindow: PropTypes.bool,
        /** expandable children **/
        childMenuItems: PropTypes.arrayOf(
            PropTypes.shape({
                /** the active state of the menu item **/
                activated: PropTypes.bool,
                /** the display text for a menu item when expanded **/
                displayText: PropTypes.string,
                /** the icon of a menu item **/
                icon: PropTypes.object,
                /** the link to open when clicked **/
                link: PropTypes.string,
                /** open in a new window **/
                newWindow: PropTypes.bool,
            })
        ),
    }),
};

export default TrayMenuItem;
