/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import Logger from 'abb-webcore-logger/Logger';
import moment from 'moment';
import MuiCheckbox from '@material-ui/icons/CheckBox';
import MuiCheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { parsePhoneNumber, ParseError } from 'libphonenumber-js';
import { valueFromObjSatisfiesConditionsArray } from 'webcore-common';
import { ConfigType } from './Constants';
import { convertToReadOnlyTableColumnDefinitions } from './FormUtil';
import Handlebars from 'handlebars';
import momentDurationFormatSetup from 'moment-duration-format';
import ReadOnlyTable from '../../../react/components/ReadOnlyTable/ReadOnlyTable';
import WarningCircle from '../Icons/WarningCircle';
import StatusIndicator from '../../../react/components/StatusIndicator/StatusIndicator';
import TextDisplay from './DisplayFields/TextDisplay';

//Initialize duration format for moment
momentDurationFormatSetup(moment);

/**
 * Render a display field
 *
 * @param {object} config - field config
 * @param {object} configData - config data
 * @param {object} record - data record
 * @param {function} getStringResource - callback for getting string resource
 * @param {object} callbacks - callbacks
 * @param {string} key - key
 *
 * @returns {JSX.Element} display field
 */
export function renderDisplayField({ config, configData, record, getStringResource, callbacks, key }) {
    const { name, label, labelTextAlign, styles } = config;

    // Form data comes from the record where type conversion of the values has been done already.
    // No further type conversion of the value should be done to render the display field.
    const data = record.getData(true);

    const style = getStyle(styles, data); // Get the custom styling for the display field
    const labelText = getStringResource(label);

    /**
     * Render the display field component
     * @param {string | JSX.Element} labelContents - Label contents
     * @param {string | JSX.Element} fieldDataContents - Field data
     * @returns {JSX.Element} - Display field JSX wrapping the contents
     */
    const render = (labelContents, fieldDataContents) => (
        <div key={key} className="wcux-nxt-display-field">
            {labelContents && (
                <div className="wcux-nxt-display-field-label" style={{ textAlign: labelTextAlign }}>
                    {labelContents}
                </div>
            )}
            <div className="wcux-nxt-display-field-data" data-testid={config['data-testid'] || name}>
                {fieldDataContents}
            </div>
        </div>
    );

    /**
     * Helper for rendering most display fields
     * @param {string | JSX.Element} value - The display field value/node
     * @returns {JSX.Element} - JSX with label and style wrapper
     */
    const renderPresets = (value) => render(labelText, <div style={style}>{value}</div>);

    // checking for LITERAL_DISPLAY here as it won't require any other fields like `name`, `type` etc.
    if (config.type === ConfigType.LITERAL_DISPLAY) {
        if (config.value) {
            return renderPresets(config.value);
        }

        throw new Error(`Invalid value for ${ConfigType.LITERAL_DISPLAY} field.`);
    }

    if (!name) {
        throw new Error('Field name is not defined');
    }

    const fieldDef = record.recordDef.fields[name];
    const value = data[name];

    if (!fieldDef) {
        //:TRICKY Do NOT remove this until we have proper logging.
        Logger.error(`Missing record entry for field [${record.recordName}.${name}]`);
        return render(
            <>
                <span className="wcux-nxt-display-field-status">
                    <WarningCircle className="wcux-nxt-display-field-icon" color="primary" titleAccess={`Invalid field [${name}]`} />
                </span>
                {labelText}
            </>,
            <div style={style}>{value}</div>
        );
    }

    const fieldType = fieldDef.type;

    switch (config.type) {
        case ConfigType.TEXT_DISPLAY:
            return renderPresets(<TextDisplay value={value} config={config} getStringResource={getStringResource} />);

        case ConfigType.NUMBER_DISPLAY:
            return renderPresets(displayAsNumber(fieldType, value, config));

        case ConfigType.DATETIME_DISPLAY:
            return renderPresets(displayAsDatetime(fieldType, value));

        case ConfigType.DATE_DISPLAY:
            return renderPresets(displayAsDate(fieldType, value));

        case ConfigType.TIME_DISPLAY:
            return renderPresets(displayAsTime(fieldType, value));

        case ConfigType.BOOLEAN_DISPLAY:
            return renderPresets(displayAsBoolean(fieldType, value, config, { getStringResource }));

        case ConfigType.HYPERLINK_DISPLAY:
            return renderPresets(displayAsHyperlink(fieldType, value, config, data));

        case ConfigType.LISTITEM_DISPLAY:
            return renderPresets(displayAsListItem(value, config, configData, { getStringResource }, data));

        case ConfigType.PHONENUMBER_DISPLAY:
            return renderPresets(displayAsPhoneNumber(fieldType, value, config));

        case ConfigType.STATUS_DISPLAY:
            // Any styles that get applied should affect the component directly, unlike the style div in renderPresets()
            return render(labelText, <StatusIndicator label={value} status={config.status} style={style} />);

        case ConfigType.REFERENCE_DISPLAY:
            if (callbacks[ConfigType.REFERENCE_DISPLAY] && callbacks[ConfigType.REFERENCE_DISPLAY].onRender) {
                return callbacks[ConfigType.REFERENCE_DISPLAY].onRender({ config, record, key });
            }

            return null;

        case ConfigType.TABLE_DISPLAY:
            return renderDisplayTable(config, configData, record, { ...callbacks, getStringResource }, key);

        case ConfigType.DURATION_DISPLAY:
            return renderPresets(displayAsDuration(fieldType, value, config));

        default:
            throw new Error(`Config type ${config.type} not supported`);
    }
}

/**
 * Get the style that satisfies the condition given the value
 *
 * @param {object[]} styles - list of styles
 * @param {object} data - form data
 *
 * @returns {object} style object
 */
function getStyle(styles, data) {
    if (Array.isArray(styles) && styles.length > 0) {
        for (let i = 0; i < styles.length; i += 1) {
            let style = styles[i];

            if (valueFromObjSatisfiesConditionsArray(data, style.conditions)) {
                return style.style;
            }
        }
    }

    return;
}

/**
 * Check the record field type and display value as localized number
 *
 * @param {string} type - source record field type
 * @param {null|number} value - value
 * @param {object} config - config
 * @param {number} config.precision - number precision
 *
 * @returns {null|string} localized number
 */
function displayAsNumber(type, value, { precision }) {
    if (type === 'number') {
        if (value === null) {
            return null;
        }

        // Formatting number based on precision value set in config
        //undefined so that it can automatically take locale of the browser
        if (precision !== null && !isNaN(precision)) {
            return value.toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision });
        }

        return value.toLocaleString();
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.NUMBER_DISPLAY}`);
}

/**
 * Check the record field type and display value as localized duration
 *
 * @param {string} type - source record field type
 * @param {null|number} value - value
 * @param {object} config - config
 * @param {string} config.format - configuration containing format to be applied
 * @param {string} config.source - source unit, if not specifed default is milliseconds by plugin
 * @param {number} config.precision - precision
 *
 * @returns {null|string} localized duration
 */
function displayAsDuration(type, value, { format, source, precision }) {
    if (type === 'duration') {
        if (value === null) {
            return null;
        }

        if (format) {
            return moment.duration(value, source).format(format, precision, { trim: false }).toLocaleString();
        }

        return value.toLocaleString();
    }

    throw new Error('Cannot display record field of type ' + type + ' in ' + ConfigType.DURATION_DISPLAY);
}

/**
 * Check the record field type and display value as localized datetime
 *
 * @param {string} type - source record field type
 * @param {null|string} value - value
 *
 * @returns {null|string} localized datetime
 */
function displayAsDatetime(type, value) {
    if (type === 'datetime') {
        if (value === null) {
            return null;
        }

        return moment(value).toDate().toLocaleString();
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.DATETIME_DISPLAY}`);
}

/**
 * Check the record field type and display value as localized date
 *
 * @param {string} type - source record field type
 * @param {null|string} value - value
 *
 * @returns {null|string} localized date
 */
function displayAsDate(type, value) {
    if (type === 'date' || type === 'datetime') {
        if (value === null) {
            return null;
        }

        return moment(value).toDate().toLocaleDateString();
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.DATE_DISPLAY}`);
}

/**
 * Check the record field type and display value as localized time
 *
 * @param {string} type - source record field type
 * @param {null|string} value - value
 *
 * @returns {null|string} localized time
 */
function displayAsTime(type, value) {
    if (type === 'time' || type === 'datetime') {
        if (value === null) {
            return null;
        }

        return moment(value, [moment.ISO_8601, moment.HTML5_FMT.TIME_SECONDS]).toDate().toLocaleTimeString();
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.TIME_DISPLAY}`);
}

/**
 * Check the record field type and display a representation of the boolean value
 *
 * @param {string} type - source record field type
 * @param {null|boolean} value - value
 * @param {object} config - config
 * @param {string} config.trueLabel - label to show for true
 * @param {string} config.falseLabel - label to show for false
 * @param {boolean} config.displayNullAsFalse - true to display null value as false
 * @param {object} callbacks - callbacks
 * @param {function} callbacks.getStringResource - callback for getting string resource
 *
 * @returns {*} representation of the boolean value
 */
function displayAsBoolean(type, value, { trueLabel, falseLabel, displayNullAsFalse }, { getStringResource }) {
    if (type === 'boolean' || type === 'tristate') {
        const trueText = getStringResource(trueLabel) || 'True';
        const falseText = getStringResource(falseLabel) || 'False';
        const trueLabelNode = <div className="wcux-nxt-display-field-true-label">{trueText}</div>;
        const falseLabelNode = <div className="wcux-nxt-display-field-false-label">{falseText}</div>;

        /**
         * Helper to create a checkbox/label container.
         * A checkbox and its label must be wrapped together to display the correct order for right-to-left mode.
         * @param {MuiCheckbox | MuiCheckboxOutlineBlank} CheckboxType - Checkbox component (checked or blank)
         * @param {JSX.Element} labelNode - Label JSX
         * @param {String} label - checkbox label
         * @param {boolean} isSelected - true if checkbox is selected
         * @returns {JSX.Element} - The container wrapping the checkbox/label.
         */
        const makeContainer = (CheckboxType, labelNode, label, isSelected) => {
            const dataTestID = isSelected ? 'selected' : 'not-selected';
            return (
                <div className="wcux-nxt-display-field-boolean-container" data-testid={`${label}-${dataTestID}`}>
                    <CheckboxType classes={{ root: 'boolean-display-checkbox' }} />
                    {labelNode}
                </div>
            );
        };

        if (value === null && !displayNullAsFalse) {
            return (
                <>
                    {makeContainer(MuiCheckboxOutlineBlank, trueLabelNode, trueText)}
                    {makeContainer(MuiCheckboxOutlineBlank, falseLabelNode, falseText)}
                </>
            );
        } else if (value) {
            return (
                <>
                    {makeContainer(MuiCheckbox, trueLabelNode, trueText, true)}
                    {makeContainer(MuiCheckboxOutlineBlank, falseLabelNode, falseText)}
                </>
            );
        } else {
            return (
                <>
                    {makeContainer(MuiCheckboxOutlineBlank, trueLabelNode, trueText)}
                    {makeContainer(MuiCheckbox, falseLabelNode, falseText, true)}
                </>
            );
        }
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.BOOLEAN_DISPLAY}`);
}

/**
 * Check the record field type and display value as hyperlink
 *
 * @param {string} type - source record field type
 * @param {string} value - value
 * @param {object} config - config
 * @param {string} config.displayTextField - name of field containing text to display
 * @param {string} config.displayText - text to display
 * @param {object} data - record data
 *
 * @returns {*} hyperlink
 */
function displayAsHyperlink(type, value, { displayTextField, displayText }, data) {
    if (type === 'string') {
        let textToDisplay;

        if (displayTextField) {
            textToDisplay = data[displayTextField];
        }

        if (!textToDisplay && displayText) {
            textToDisplay = displayText;
        }

        if (!textToDisplay) {
            textToDisplay = value;
        }

        if (value) {
            return (
                <a href={value} target="_blank" rel="noopener noreferrer">
                    {textToDisplay}
                </a>
            );
        }

        if (textToDisplay) {
            return textToDisplay;
        }

        return null;
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.HYPERLINK_DISPLAY}`);
}

/**
 * Display the list item label/description that matches the value
 *
 * @param {*} value - value
 * @param {object} config - config
 * @param {object} config.data - list data source config
 * @param {object} configData - config data
 * @param {object} callbacks - callbacks
 * @param {object} recData - record data
 * @param {function} callbacks.getStringResource - callback for getting string resource
 *
 * @returns {*} list item label/description if found or original value
 */
function displayAsListItem(value, { data }, configData, { getStringResource }, recData) {
    let arrayData = [];

    if (!data || !['config', 'url'].includes(data.source) || !['lists', 'inline', 'enum'].includes(data.type)) {
        throw new Error(`${ConfigType.LISTITEM_DISPLAY} data config is missing or data source/type is not supported`);
    }

    if (data.type === 'lists' && (!configData || !configData.lists || !configData.lists[data.name] || !configData.lists[data.name].data)) {
        throw new Error(`${ConfigType.LISTITEM_DISPLAY} list data is not configured`);
    }

    if (data.type === 'inline' && (!Array.isArray(data.inlineData) || data.inlineData.length < 1)) {
        throw new Error(`${ConfigType.LISTITEM_DISPLAY} inline data was not provided`);
    }

    if (data.type === 'lists') {
        arrayData = configData.lists[data.name].data;
    } else if (data.type === 'inline') {
        arrayData = data.inlineData;
    } else if (data.type === 'enum') {
        if (data.labelKeyTemplate) {
            const labelKeyTemplate = Handlebars.compile(data.labelKeyTemplate);
            return getStringResource(labelKeyTemplate(recData));
        }
    }

    for (let i = 0; i < arrayData.length; i += 1) {
        let item = arrayData[i];

        if (item.value === value) {
            return getStringResource(item.label);
        }
    }

    return value;
}

/**
 * Check the record field type and display as a clickable call phone number link
 *
 * @param {string} type - source record field type
 * @param {string} value - value
 * @param {object} config - config
 * @param {boolean} config.parseAndFormat - true to parse and format the phone number
 * @param {string} config.country - 2 letter ISO country code. Applicable only if parseAndFormat = true.
 *
 * @returns {*} clickable phone number link
 */
function displayAsPhoneNumber(type, value, { parseAndFormat, country }) {
    if (type === 'string') {
        if (parseAndFormat) {
            try {
                let phoneNumber = parsePhoneNumber(value, country);

                if (country) {
                    // Example: "(213) 373-4253"
                    return <a href={phoneNumber.getURI()}>{phoneNumber.formatNational()}</a>;
                }

                // Example: "+1 213 373 4253"
                return <a href={phoneNumber.getURI()}>{phoneNumber.formatInternational()}</a>;
            } catch (error) {
                if (error instanceof ParseError) {
                    // Not a phone number, non-existent country, etc.
                    Logger.info(error.message);
                } else {
                    Logger.info(`Error parsing phone number: ${value}`);
                }
            }
        }

        // Default
        return <a href={`tel:${value}`}>{value}</a>;
    }

    throw new Error(`Cannot display record field of type ${type} in ${ConfigType.PHONENUMBER_DISPLAY}`);
}

/**
 * Render a read only table control
 *
 * @param {object} config - table display config
 * @param {object} configData - config data
 * @param {object} record - record
 * @param {object} callbacks - callbacks
 * @param {string} key - key
 * @returns {ReadOnlyTable} ReadOnlyTable control
 */
function renderDisplayTable(config, configData, record, callbacks, key) {
    const { label, name, columns, onSortChange, sortData } = config;

    let columnsDefinition = convertToReadOnlyTableColumnDefinitions(columns, configData, callbacks, callbacks.getStringResource);

    return (
        <ReadOnlyTable
            key={key}
            columns={columnsDefinition}
            data={record.getValue(name)}
            title={callbacks.getStringResource(label)}
            dense={true}
            onSortChange={onSortChange}
            sortData={sortData}
        />
    );
}

export default {
    renderDisplayField,
    getStyle,
    displayAsNumber,
    displayAsDatetime,
    displayAsDate,
    displayAsTime,
    displayAsBoolean,
    displayAsHyperlink,
    displayAsListItem,
    displayAsPhoneNumber,
    displayAsDuration,
};
