/**
 * @file Component that renders a tray menu
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import TrayMenuItem from '../TrayMenuItem/TrayMenuItem';
import TrayMenuSection from '../TrayMenuSection/TrayMenuSection';
import { MoreHoriz as MoreHorizIcon } from '../../Icons';
import MenuTrayContext from '../MenuTrayContext';
import styled from 'styled-components';

const Styles = styled.div`
    ${({ theme }) => `
        .wcux-nxt-tray-menu {
            background-color: ${theme.palette.sideMenu.background};
        }
    `}
`;

/**
 * ender an application tray menu/navigation
 * @param {object} config - tray menu config object
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const TrayMenu = ({ config }) => {
    const { open, setOpen } = useContext(MenuTrayContext);

    /**
     * runs on click of more link icon
     */
    const moreOnClickHandler = () => {
        setOpen(true);
    };

    if (!config || !config.menuSections) {
        return <div />;
    }

    const menuSections = config.menuSections;
    const closedMenuItems = Object.keys(menuSections[0].menuItems);

    return (
        <Styles>
            <List className={'wcux-nxt-tray-menu'} data-testid={'tray-menu'}>
                {!open && (
                    <>
                        {closedMenuItems.map((menuItemKey, index) => {
                            return (
                                <Fragment key={index}>
                                    {menuItemKey !== 'global' && menuSections.length && (
                                        <TrayMenuItem menuItem={menuSections[0].menuItems[menuItemKey]} />
                                    )}
                                </Fragment>
                            );
                        })}
                        {config.global.moreLinkText && (
                            <TrayMenuItem
                                menuItem={{
                                    activated: false,
                                    displayText: config.global.moreLinkText,
                                    icon: <MoreHorizIcon />,
                                    iconText: config.global.moreLinkText,
                                    onClick: moreOnClickHandler,
                                }}
                            />
                        )}
                    </>
                )}

                {open &&
                    menuSections.map((menuSection, index) => {
                        return <TrayMenuSection config={menuSection} key={index} />;
                    })}
            </List>
        </Styles>
    );
};

const menuItemsShape = {
    /** activated display state for menu item **/
    activated: PropTypes.bool,
    /** the menu item text displayed when menu is expanded **/
    displayText: PropTypes.string,
    /** the menu item icon **/
    icon: PropTypes.object,
    /** the menu item text when collapsed **/
    iconText: PropTypes.string,
    /** the menu item link **/
    link: PropTypes.string,
    /** menu item is expandable **/
    expandable: PropTypes.bool,
    /** the permissions app code **/
    code: PropTypes.string,
    /** have menu item link open in a new window **/
    newWindow: PropTypes.bool,
    childMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
            /** the display text **/
            displayText: PropTypes.string,
            /** the child menu item icon **/
            icon: PropTypes.object,
            /** the child menu item link **/
            link: PropTypes.string,
        })
    ),
};

const menuSectionShape = {
    /** title text for a section **/
    sectionTitleText: PropTypes.string,
    /** array of section menu items **/
    menuItems: PropTypes.arrayOf(PropTypes.shape(menuItemsShape)),
};

TrayMenu.propTypes = {
    config: PropTypes.shape({
        /** global config settings **/
        global: PropTypes.shape({
            /** text for the 'More' link **/
            moreLinkText: PropTypes.string,
        }),
        /** array of menu section objects **/
        menuSections: PropTypes.arrayOf(PropTypes.shape(menuSectionShape)),
    }).isRequired,
};

export default TrayMenu;
