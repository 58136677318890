/**
 * @file Action Buttons
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { IconButton as MuiIconButton } from '@material-ui/core';

import { Button } from '..';
import More from '../More/More';

// JSS styling for this component
const useStyles = makeStyles((theme) => ({
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginRight: `${theme.spacing(1)}px`,
        },
    },
    moreButton: {
        padding: 0,
        height: '40px',
        paddingLeft: `${theme.spacing(1)}px`,
        paddingRight: `${theme.spacing(1)}px`,
    },
}));

const ActionButtons = ({ actions = [] }) => {
    const classes = useStyles();

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const renderButtons = (actions) => {
        return actions.map((button, index) => (
            <Button
                key={'button' + index}
                variant={index === 0 ? 'primary' : 'secondary'}
                {...{ onClick: button.onClick }}
                data-testid={`wcux-nxt-action-button-${index}`}
                disabled={button.disabled}
            >
                {button.value}
            </Button>
        ));
    };

    const renderIcons = (actions) => {
        return actions.map((icon, index) => (
            <MuiIconButton
                onClick={icon.onClick}
                key={'icon' + index}
                disabled={icon.disabled}
                data-testid={`wcux-nxt-action-icon-${index}`}
            >
                {icon.icon}
            </MuiIconButton>
        ));
    };

    const renderMore = (actions) =>
        actions.length > 0 && (
            <More menuOptions={actions} data-testid="wcux-nxt-actions-more-btn" className={classes.moreButton} size="normal" />
        );

    const renderActionButtonComponents = () => {
        const breakpoints = {
            lg: 1919,
            md: 1439,
            sm: 1023,
            xs: 767,
        };

        const actionButtons = actions.filter((action) => action.icon === undefined);
        const actionIcons = actions.filter((action) => action.icon);
        if (width > breakpoints.lg) {
            // more button, icons, primary action, two secondary actions
            const moreActions = actionButtons.splice(3);

            return (
                <>
                    {renderIcons(actionIcons)}
                    {renderButtons(actionButtons)}
                    {renderMore(moreActions)}
                </>
            );
        } else if (width > breakpoints.md) {
            // more button, icons, and primary action
            const moreActions = actionButtons.splice(2);

            return (
                <>
                    {renderIcons(actionIcons)}
                    {renderButtons(actionButtons)}
                    {renderMore(moreActions)}
                </>
            );
        } else if (width > breakpoints.sm) {
            // more button, icons, and primary action
            const moreActions = actionButtons.splice(1);

            return (
                <>
                    {renderIcons(actionIcons)}
                    {renderButtons(actionButtons)}
                    {renderMore(moreActions)}
                </>
            );
        } else if (width > breakpoints.xs) {
            // more button and icons
            return (
                <>
                    {renderIcons(actionIcons)}
                    {renderMore(actionButtons)}
                </>
            );
        } else if (width <= breakpoints.xs) {
            // more button only
            return renderMore([...actionIcons, ...actionButtons]);
        }
    };

    return (
        <div className={classNames('wcux-nxt-action-buttons', classes.actionButtons)} data-testid="wcux-nxt-action-buttons">
            {renderActionButtonComponents()}
        </div>
    );
};

ActionButtons.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            /** Value of the string to display */
            value: PropTypes.string,
            /** click function to fire when button is pressed */
            onClick: PropTypes.func,
            /** Shows Icon when provided. Currently shows all icons on bar except at smallest breakpoint
            where it is in the kebab menu */
            icon: PropTypes.node,
            /** Boolean to disable the button */
            disabled: PropTypes.bool,
        })
    ),
};

export default ActionButtons;
