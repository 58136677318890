/**
 * @module webcore-ux/nextgen/components/FormBuilder/Utils
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

/**
 * Given possible inline record fields in a config, generate a Record definition object.
 * @param {object} config - Form configuration definition
 * @returns {object} - Record definition
 */
export const generateRecordFromInline = (config) => {
    const fields = {};

    const traverse = (nodes) => {
        nodes.forEach((node) => {
            if (Array.isArray(node.children)) {
                traverse(node.children);
            }

            if (node.field) {
                const { path, type } = node.field;
                const name = node.field.name || node.name || node.field.path;
                if (name) {
                    fields[name] = {
                        path,
                        type,
                    };
                }
            }
        });
    };

    if (Array.isArray(config.children)) {
        traverse(config.children);
    }

    return {
        fields,
    };
};
