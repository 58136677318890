/**
 * @file Component that renders the copyright text for an app
 * @copyright © 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

/**
 * renders a copyright text
 * @param {string} variant - the typography variant
 * @param {string} className - the custom classname
 * @param {boolean} twoLines - true if text should appear on two lines
 * @returns {JSX.Element} - the copyright component
 * @constructor
 */
export const Copyright = ({ variant, className, twoLines }) => {
    return (
        <Typography variant={variant} className={className} data-testid={'wcux-nxt-copyright'}>
            &copy; {new Date().getFullYear()} Hitachi ABB Power Grids Ltd.
            {twoLines ? <br /> : ' '}
            All rights reserved.
        </Typography>
    );
};

Copyright.defaultProps = {
    variant: 'subtitle2',
    twoLines: false,
};

Copyright.propTypes = {
    /** the typography name variant  */
    variant: PropTypes.string,
    /** Custom class name on the root element */
    className: PropTypes.string,
    /** if message should be broken on 2 lines **/
    twoLines: PropTypes.bool,
};

export default Copyright;
