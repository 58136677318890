/**
 * @file Component that renders a tray menu
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import TrayMenuItem from '../TrayMenuItem/TrayMenuItem';
import styled from 'styled-components';

const Styles = styled.div`
    ${({ theme }) => `
        .wcux-nxt-tray-menu-section {
            background-color: ${theme.palette.background.sideMenu};
        }
        .wcux-nxt-tray-menu-section-title {
            color: ${theme.palette.common.nearWhite};
            opacity: 64%;
            font-size: 14px;
            line-height: 24px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 16px;
            text-transform: uppercase;
            border-top: solid 1px ${theme.palette.secondary.main};
        }
    `}
`;

/**
 * ender an application tray menu/navigation
 * @param {object} config - tray menu config object
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const TrayMenuSection = ({ config }) => {
    return (
        <Styles>
            <div className={'wcux-nxt-tray-menu-section'} data-testid={'wcux-nxt-tray-menu-section'}>
                {config.sectionTitleText && (
                    <div className={'wcux-nxt-tray-menu-section-title'} data-testid={'wcux-nxt-tray-menu-section-title'}>
                        {config.sectionTitleText}
                    </div>
                )}

                {config.menuItems.map((menuItem, index) => (
                    <TrayMenuItem menuItem={menuItem} key={index} />
                ))}
            </div>
        </Styles>
    );
};

TrayMenuSection.defaultProps = {
    config: {},
};

const menuItemsShape = {
    /** activated display state for menu item **/
    activated: PropTypes.bool,
    /** the menu item text displayed when menu is expanded **/
    displayText: PropTypes.string,
    /** the menu item icon **/
    icon: PropTypes.object,
    /** the menu item text when collapsed **/
    iconText: PropTypes.string,
    /** the menu item link **/
    link: PropTypes.string,
    /** menu item is expandable **/
    expandable: PropTypes.bool,
    /** the permissions app code **/
    code: PropTypes.string,
    /** have menu item link open in a new window **/
    newWindow: PropTypes.bool,
    childMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
            /** the display text **/
            displayText: PropTypes.string,
            /** the child menu item icon **/
            icon: PropTypes.object,
            /** the child menu item link **/
            link: PropTypes.string,
        })
    ),
};

TrayMenuSection.propTypes = {
    config: PropTypes.shape({
        sectionTitleText: PropTypes.string,
        menuItems: PropTypes.arrayOf(PropTypes.shape(menuItemsShape)),
    }),
};

export default TrayMenuSection;
