import moment from 'moment';

/**
 * @file Relative Date Picker Constants
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

export const dateTypeEnum = { absolute: 'absolute', relative: 'relative' };
export const relativeDateDirectionEnum = { relativeDateTo: 'relativeDateTo', relativeDateFrom: 'relativeDateFrom' };
export const absoluteDateOptionEnum = { dateOnly: 'dateOnly', dateAndTime: 'dateAndTime' };
export const dateTimePartEnum = { startOfDay: 'startOfDay', endOfDay: 'endOfDay' };
export const dateOptionEnum = {
    noDate: 'a4477e30-a1eb-445f-a25b-2fb0d130d90c',
    hoursAgo: '58039726-8680-4049-9D24-61E8FD2370F2',
    daysAgo: '393BDC4B-4220-42EA-88BC-7B23C4862754',
    weeksAgo: '6F53BA03-6E28-4971-B715-2CF7EC4E361F',
    monthsAgo: '59DA5AFE-6546-469D-ABF5-F5A4A7D75B26',
    hoursFromNow: '6CFC12D7-D048-49A0-978C-26D5670198E7',
    daysFromToday: '133C65FC-D94E-4A8B-8851-50B938557589',
    weeksFromToday: '7B2644D3-3475-4AB8-909A-D354099E2481',
    monthsFromToday: '4C928822-A4CA-4851-B861-15C01EBF41A2',
};
export const datePatternEnum = { toMonthId: dateOptionEnum.monthsFromToday, fromMonthdId: dateOptionEnum.monthsAgo };

/**
 * Builds the content of the options list, used by the relative date dropdown.
 * @param {object} data - The Relative Date Value Options
 * @param {function} getStringResource - callback for getting localized string
 * @returns {object[]} - array of objects containing Relative Date Value Options.
 */
export const buildRelativeDateValueOptions = (data, getStringResource) => {
    let options = [];

    if (data) {
        options = [...data];
        options.map((item) => {
            // if localized function is provided then retrieve the localized string
            if (getStringResource) {
                const localizedLabel = getStringResource(item.i18nLabel);
                // use localized string if defined otherwise use default label for backward compatibility
                if (!isEmpty(localizedLabel) && localizedLabel !== item.i18nLabel) {
                    item.label = localizedLabel;
                }
            }
        });
    }

    return options;
};

/**
 * Creates a Absolute Date from the Date and time part.
 * @param {object} date - Date value to convert.
 * @param {object} timePart - time part.
 * @returns {object} - Absolute Date based on an actual date plus either a start of day or end of day time pattern.
 */
export const createAbsoluteDate = (date, timePart) => {
    if (timePart === dateTimePartEnum.startOfDay) {
        // Set to 12:00:00 AM
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    } else if (timePart === dateTimePartEnum.endOfDay) {
        // Set to 11:59:59 PM
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    }
};

/**
 * Builds the content of the options list, used by the relative date dropdown.
 * @returns {object[]} - array of objects containing Relative Date Value Options.
 */
export const relativeDateValueOptions = [
    {
        value: dateOptionEnum.noDate,
        label: 'No Date',
        i18nLabel: 'relativeDatePicker.noDate',
        startFromNow: true,
        hours: null,
        typeToFrom: relativeDateDirectionEnum.relativeDateFrom,
    },
    {
        value: dateOptionEnum.hoursAgo,
        label: 'Hours ago',
        i18nLabel: 'relativeDatePicker.hoursAgo',
        startFromNow: true,
        hours: 1,
        typeToFrom: relativeDateDirectionEnum.relativeDateFrom,
    },
    {
        value: dateOptionEnum.daysAgo,
        label: 'Days ago',
        i18nLabel: 'relativeDatePicker.daysAgo',
        startFromNow: false,
        hours: 24,
        typeToFrom: relativeDateDirectionEnum.relativeDateFrom,
    },
    {
        value: dateOptionEnum.weeksAgo,
        label: 'Weeks ago',
        i18nLabel: 'relativeDatePicker.weeksAgo',
        startFromNow: false,
        hours: 168,
        typeToFrom: relativeDateDirectionEnum.relativeDateFrom,
    },
    {
        value: dateOptionEnum.monthsAgo,
        label: 'Months ago',
        i18nLabel: 'relativeDatePicker.monthsAgo',
        startFromNow: false,
        hours: 720,
        typeToFrom: relativeDateDirectionEnum.relativeDateFrom,
    },
    {
        value: dateOptionEnum.hoursFromNow,
        label: 'Hours from now',
        i18nLabel: 'relativeDatePicker.hoursFromNow',
        startFromNow: true,
        hours: 1,
        typeToFrom: relativeDateDirectionEnum.relativeDateTo,
    },
    {
        value: dateOptionEnum.daysFromToday,
        label: 'Days from today',
        i18nLabel: 'relativeDatePicker.daysFromToday',
        startFromNow: false,
        hours: 24,
        typeToFrom: relativeDateDirectionEnum.relativeDateTo,
    },
    {
        value: dateOptionEnum.weeksFromToday,
        label: 'Weeks from today',
        i18nLabel: 'relativeDatePicker.weeksFromToday',
        startFromNow: false,
        hours: 168,
        typeToFrom: relativeDateDirectionEnum.relativeDateTo,
    },
    {
        value: dateOptionEnum.monthsFromToday,
        label: 'Months from today',
        i18nLabel: 'relativeDatePicker.monthsFromToday',
        startFromNow: false,
        hours: 720,
        typeToFrom: relativeDateDirectionEnum.relativeDateTo,
    },
];

export const getRelativeDateValueOption = (value, options = relativeDateValueOptions) => options.find((o) => o.value === value);

/**
 * Calculate Date Value.
 * @param {object} params -
 * ```
 * {
 *   dateType: '{dateTypeEnum} relative or absolute',
 *   offset: 'date time offset value (unit is based on the relevant relative date option)',
 *   optionValue: 'value that specifies the relative date option to refer to',
 *   relativeDateOptionValues: 'the list of relative date options',
 *   dateTimePart: '{dateTimePartEnum} start/end of day',
 *   relativeDateReference: 'specifies the relative date time reference point to use, otherwise the current date time (as of invocation) will be used',
 *   absoluteDateOption: '{absoluteDateOptionEnum} dateOnly or dateAndTime'
 * };
 * ```
 * @returns {object} - Date based on relative date pattern.
 */
export const calcDateValue = (params) => {
    const relativeDateOption = params.relativeDateOptionValues.find((x) => x.value === params.optionValue);

    // if "No Date" is selected, then return null
    if (relativeDateOption && isEmpty(relativeDateOption.hours)) {
        return null;
    }

    // Need to get Date Time now for processing relative date.
    // Clone the input params date as necessary.
    let relativeDate;
    if (!isEmpty(params.relativeDateReference)) {
        relativeDate = new Date(params.relativeDateReference);
    } else {
        relativeDate = new Date();
    }

    if (relativeDateOption && params.dateType === dateTypeEnum.relative) {
        // Do not reset time part when Hours is selected ie startFromNow is set to true
        if (relativeDateOption.startFromNow === false) {
            setTimePart(relativeDate, params.dateTimePart);
        }

        if (relativeDateOption.typeToFrom === relativeDateDirectionEnum.relativeDateTo) {
            // Check if using month pattern.
            if (params.optionValue && params.optionValue === datePatternEnum.toMonthId) {
                // To Date add months
                relativeDate = addMonthsToDate(relativeDate, params.offset);
            } else {
                // To Date add hours
                relativeDate = addHoursToDate(relativeDate, relativeDateOption.hours * params.offset);
            }
        } else if (relativeDateOption.typeToFrom === relativeDateDirectionEnum.relativeDateFrom) {
            // Check if using month pattern.
            if (params.optionValue && params.optionValue === datePatternEnum.fromMonthId) {
                // To Date subtract months
                relativeDate = subtractMonthsFromDate(relativeDate, params.offset);
            } else {
                // To Date subtract hours
                relativeDate = subtractHoursFromDate(relativeDate, relativeDateOption.hours * params.offset);
            }
        }
    }

    return relativeDate;
};

/**
 * Set Time of the date based on Time Part - StartOfDay or EndOfDay.
 * @param {object} date - Date to set time on.
 * @param {string} timePart - StartOfDay or EndOfDay.
 */
export const setTimePart = (date, timePart) => {
    if (timePart === dateTimePartEnum.startOfDay) {
        // Set to 12:00:00 AM
        date.setHours(0, 0, 0, 0);
    } else if (timePart === dateTimePartEnum.endOfDay) {
        // Set to 11:59:59 PM
        date.setHours(23, 59, 59, 999);
    }
};

/**
 * Add Months to Date.
 * @param {object} dateValue - Date to add months.
 * @param {number} months - Number of months to add.
 * @returns {object} - New Date with months added.
 */
export const addMonthsToDate = (dateValue, months) => {
    const momentDate = moment(dateValue);
    return momentDate.add(months, 'months').toDate();
};

/**
 * Subtract Hours to Date.
 * @param {object} dateValue - Date to subtract months.
 * @param {number} months - Number of months to add.
 * @returns {object} - New Date with months removed.
 */
export const subtractMonthsFromDate = (dateValue, months) => {
    const momentDate = moment(dateValue);
    return momentDate.subtract(months, 'months').toDate();
};

/**
 * Add Hours to Date.
 * @param {object} dateValue - Date to add hours.
 * @param {number} hours - Number of hours to add.
 * @returns {object} - New Date with hours added.
 */
export const addHoursToDate = (dateValue, hours) => {
    return new Date(new Date(dateValue).setHours(dateValue.getHours() + hours));
};

/**
 * Subtract Hours from Date.
 * @param {object} dateValue - Date to subtract hours from.
 * @param {number} hours - Number of hours to subtract.
 * @returns {object} - New Date with hours subtracted.
 */
export const subtractHoursFromDate = (dateValue, hours) => {
    return new Date(new Date(dateValue).setHours(dateValue.getHours() - hours));
};

export function isEmpty(obj) {
    if (obj === null || obj === undefined || obj === '') {
        return true;
    } else {
        return false;
    }
}
