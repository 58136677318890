/**
 * @file WidgetKPI component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useCallback } from 'react';
import Logger from 'abb-webcore-logger/Logger';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';
import i18next from 'i18next';

/**
 * Hook used to fetch KPI metrics for the logged in user
 * @param {String} status - a string of a status
 * @param {function} getData - get data pending promise
 * @returns {object} - list of Notifications and doFetchNotifications function
 */
const useWidgetKPIMetric = (status, getData) => {
    const [kpiMetric, setKPIMetric] = useState({});
    const [loading, setLoading] = useState(true);

    const doFetchKPIMetric = useCallback(async () => {
        try {
            const data = await getData();

            setKPIMetric(data);
        } catch (err) {
            Logger.error(`Failed to retrieve data: ${err}`);
            //default count to 0 if failed to fetch the data
            setKPIMetric({ count: 0 });
        } finally {
            setLoading(false);
        }
    }, [getData]);

    return { kpiMetric, doFetchKPIMetric, setKPIMetric, loading };
};

/**
 * creates a widget kpi component
 * @param {string} kpiText - the kpi text to display
 * @param {string} status - status text for lookup
 * @param {string} color - the color of the KPI metric
 * @param {function} getData - a pending getData promise
 * @param {function} onClick - the on click handler
 * @returns {JSX.Element} the component
 * @constructor
 */
export const WidgetKPI = ({ kpiText, color, getData, onClick }) => {
    const { kpiMetric, doFetchKPIMetric, loading } = useWidgetKPIMetric(status, getData);
    const { count } = kpiMetric;
    // const Icon = selectIconByStatus(status);

    useEffect(() => {
        doFetchKPIMetric();
    }, []);

    const useStyles = makeStyles((theme) => ({
        container: {
            width: '100%',
            height: '100%',
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            color: color,
            borderRadius: '4px',
            cursor: 'pointer',
        },
        title: {
            color: '#000000',
            fontSize: '15px',
            textAlign: 'left',
            padding: 0,
            paddingLeft: '11px',
            margin: 0,
        },
        moreIcon: {
            color: '#333',
            textAlign: 'right',
        },
        count: {
            fontSize: '32px',
            padding: 0,
            margin: 0,
        },
        status: {
            padding: 0,
            margin: 0,
            color: '#333',
            fontWeight: 600,
            fontSize: '16px',
        },
        icon: {
            marginTop: '10px',
        },
        body: {
            padding: `${theme.spacing(1)}px 0`,
        },
    }));

    const classes = useStyles();

    if (loading) {
        return (
            <div>
                <ProgressBar data-testid={'de-cmn-widget-kpi-progress-bar'} />
            </div>
        );
    }

    return (
        <div className={classes.container} data-testid={'de-cmn-widget-kpi-root'} onClick={onClick}>
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <Grid container className={classes.body}>
                        <Grid item lg={12} md={12} xs={12} data-testid={'de-cmn-widget-kpi-count'}>
                            <h3 className={classes.count}>{count}</h3>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} data-testid={'de-cmn-widget-kpi-status'}>
                            <p className={classes.status}>{i18next.t(kpiText)}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

WidgetKPI.defaultProps = {
    onClick: () => {},
};

WidgetKPI.propTypes = {
    /** the color of the KPI metric number **/
    color: PropTypes.string,
    /** the kpi text **/
    kpiText: PropTypes.string,
    /** the fetch data pending promise object**/
    getData: PropTypes.object,
    /** kpi onClick handler **/
    onClick: PropTypes.func,
};

export default WidgetKPI;
