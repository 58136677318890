/**
 * @module webcore-ux/nextgen/components/Chip
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
import { Chip as MuiChip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const IDS = {
    CHIP: 'wcux-nxt-chip',
};

export const VALID_COLORS = ['default', 'primary', 'secondary', 'success', 'warning', 'error', 'ondark'];

/**
 * A status text display for DE objects.
 * @returns {JSX.Element} - The component JSX
 */
const Chip = ({ color, variant, title, label, postfix, avatarPosition, ...other }) => {
    const selectors = ['wcux-nxt-chip'];

    if (color && VALID_COLORS.includes(color.toLowerCase())) {
        const selector = `wcux-nxt-chip-${color.toLowerCase()}${variant === 'outlined' ? '-outlined' : ''}`;
        selectors.push(selector);
    }

    if (avatarPosition === 'right') {
        selectors.push('wcux-nxt-chip-avatar-right');
    }

    const { classes, ...otherProps } = other;
    if (classes && classes.root) {
        selectors.push(classes.root);
    }

    return (
        <MuiChip
            label={
                <div className="wcux-nxt-chip-label-parent">
                    <div className="wcux-nxt-chip-label-text">
                        {title && <span className="wcux-nxt-chip-title">{title}</span>}
                        {label && <span className="wcux-nxt-chip-label">{label}</span>}
                    </div>
                    {postfix && (
                        <div>
                            <span className="wcux-nxt-chip-postfix-separator"> </span>
                            <span className="wcux-nxt-chip-postfix">{postfix}</span>
                        </div>
                    )}
                </div>
            }
            classes={{
                ...classes,
                root: selectors.join(' '),
            }}
            variant={variant}
            {...otherProps}
        />
    );
};

Chip.defaultProps = {
    'data-testid': IDS.CHIP,
    color: 'default',
    avatarPosition: 'left',
};

Chip.propTypes = {
    /** The color variant, which maps to a particular background/text colour combination. Case insensitive.*/
    color: PropTypes.oneOf([undefined, ...VALID_COLORS]),
    variant: PropTypes.oneOf(['default', 'outlined']),
    /** The title to display */
    title: PropTypes.string,
    /** The text to display */
    label: PropTypes.string,
    /** Text to display after the label that will not get truncated */
    postfix: PropTypes.string,
    /** For specifying custom styles not covered by status */
    style: PropTypes.object,
    /** The size of the indicator. Default is small. */
    size: PropTypes.oneOf(['small', 'medium']),
    /** Custom test id */
    'data-testid': PropTypes.string,
    /** Position the avatar relative to the text */
    avatarPosition: PropTypes.oneOf(['left', 'right']),
};

export default styled(Chip)`
    ${({ theme }) => `
        &.wcux-nxt-chip {
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSizes.medium};
            letter-spacing: 0.02rem;

            &.MuiChip-sizeSmall {
                font-size: ${theme.typography.fontSizes.small};
            }

            &.wcux-nxt-chip-default {
                color: ${theme.palette.text.primary};
                background-color: ${theme.palette.background.surface7};

                // deleteIcon color should match text color
                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.text.primary};
                }
            }

            &.wcux-nxt-chip-primary {
                color: ${theme.palette.primary.contrastText};
                background-color: ${theme.palette.primary.main};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.primary.contrastText};
                }
            }

            &.wcux-nxt-chip-primary-outlined {
                border: 1px solid ${theme.palette.primary.main};
            }

            &.wcux-nxt-chip-secondary {
                color: ${theme.palette.secondary.contrastText};
                background-color: ${theme.palette.secondary.main};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.secondary.contrastText};
                }
            }

            &.wcux-nxt-chip-secondary-outlined {
                border: 1px solid ${theme.palette.secondary.main};
            }

            &.wcux-nxt-chip-success {
                color: ${theme.palette.success.contrastText};
                background-color: ${theme.palette.success.main};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.success.contrastText};
                }
            }

            &.wcux-nxt-chip-success-outlined {
                border: 1px solid ${theme.palette.success.main};
            }

            &.wcux-nxt-chip-warning-outlined {
                border: 1px solid ${theme.palette.warning.main};
            }

            &.wcux-nxt-chip-warning {
                color: ${theme.palette.warning.contrastText};
                background-color: ${theme.palette.warning.main};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.warning.contrastText};
                }
            }

            &.wcux-nxt-chip-warning-outlined {
                border: 1px solid ${theme.palette.warning.main};
            }

            &.wcux-nxt-chip-error {
                color: ${theme.palette.error.contrastText};
                background-color: ${theme.palette.error.main};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.error.contrastText};
                }
            }

            &.wcux-nxt-chip-error-outlined {
                border: 1px solid ${theme.palette.error.main};
            }

            &.wcux-nxt-chip-ondark {
                color: ${theme.palette.text.primary};
                background-color: ${theme.palette.background.surface4};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.text.primary};
                }
            }

            &.wcux-nxt-chip-ondark-outlined {
                border: 1px solid ${theme.palette.background.surface4};
                color: ${theme.palette.background.surface4};

                .MuiChip-deleteIcon, .MuiChip-avatar {
                    color: ${theme.palette.background.surface4};
                }
            }

            .wcux-nxt-chip-title {
                font-weight: ${theme.typography.fontWeightBold}
            }

            &.wcux-nxt-chip-avatar-right {
                .MuiChip-label {
                    order: 0;
                    margin-left: 8px;
                    margin-right -4px;
                    ${theme.direction === 'ltr' ? 'margin: 0 -4px 0 8px;' : 'margin: 0 8px 0 -4px;'}
                }

                .MuiChip-avatar {
                    order: 1;
                    ${theme.direction === 'ltr' ? 'margin: 0 8px 0 -4px;' : 'margin: 0 -4px 0 8px;'}
                    flex-shrink: 0;
                }

                .MuiChip-deleteIcon {
                    order: 2;
                }
            }

            .wcux-nxt-chip-label-parent {
                display: flex;
            }

            .wcux-nxt-chip-label-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .wcux-nxt-chip-postfix-separator {
                white-space: pre;
            }
        }

        .MuiChip-deleteIcon {
            ${theme.direction === 'rtl' ? `margin: 0 -4px 0 4px;` : ''}
        }

        .MuiChip-avatar {
            ${theme.direction === 'rtl' ? `margin: 0 4px 0 -4px;` : ''}
        }
    `}
`;
