/**
 * @file Styled components styling for NextGen Input
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
import { css } from 'styled-components';
import { wcuxNxtFieldClass, wcuxNxtLabelClass, wcuxNxtMandatoryIndicatorClass } from '../../sharedStyles/Form';

const VALIDATION_BORDER_WIDTH = '2px';
const BORDER_TYPE = 'solid';

const styles = css`
    ${wcuxNxtLabelClass}

    ${wcuxNxtMandatoryIndicatorClass}

    ${({ theme }) => `
        &.wcux-nxt-input-container {
            margin-bottom: ${theme.spacing(2)}px;

            ${wcuxNxtFieldClass}
        
            &.wcux-nxt-input-multiline {
                .wcux-nxt-input {
                    height: unset;
                }
            }

            .wcux-nxt-label-disabled {
                color: ${theme.palette.text.disabled};
            }
        
            .wcux-nxt-input {
                width: 100%;
                height: 40px;
                min-height: 40px;
                border-radius: 2px;
                border: 1px ${BORDER_TYPE} ${theme.palette.grey[135]};
                font-family: ${theme.typography.fontFamily};
                font-size: ${theme.typography.fontSizes.large};
                color: ${theme.palette.text.primary};
                box-shadow: none;
                background-color: ${theme.palette.background.surface4};
        
                padding: 0;
        
                input,
                textarea {
                    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
                    height: calc(100% - ${theme.spacing(2)}px);
                    border-radius: 2px;
        
                    &:disabled {
                        color: ${theme.palette.text.disabled};
                    }
        
                    &:-webkit-autofill::first-line {
                        font-family: ${theme.typography.fontFamily};
                        font-size: ${theme.typography.fontSizes.large};
                    }
                }
        
                &.wcux-nxt-input-readonly {
                    border-color: ${theme.palette.grey[135]};
                    background-color: ${theme.palette.background.readOnly};
                }

                .wcux-nxt-input-disabled {
                    border-color: ${theme.palette.text.disabled};
                    background-color: ${theme.palette.background.surface4};
                }
        
                .wcux-adornment-icon-set {
                    height: 100%;
                    background-color: transparent;
                }
        
                &.wcux-input-date.wcux-input-empty {
                    color: ${theme.palette.grey[159]};
                }
        
                // The following css only applies to iOS.
                @supports (-webkit-overflow-scrolling: touch) {
                    input[type='datetime-local'][readonly],
                    input[type='date'][readonly],
                    input[type='time'][readonly] {
                        // All readonly ios controls are disabled, so webkit-appearance is applied via that.
                        color: ${theme.typography.primary}
                        background-color: ${theme.palette.action.readOnly};
                    }
        
                    input[type='datetime-local'][disabled],
                    input[type='date'][disabled],
                    input[type='time'][disabled] {
                        -webkit-appearance: none;
                    }
                }
            }
        
            &:focus-within {
                .wcux-nxt-input {
                    border-color: ${theme.palette.primary.main};
                    background-color: ${theme.palette.background.surface4};
                }
                .wcux-nxt-input-readonly {
                    border-color: ${theme.palette.grey[135]};
                    background-color: ${theme.palette.background.readOnly};
                }
                .wcux-nxt-input-disabled {
                    border-color: ${theme.palette.text.disabled};
                    background-color: ${theme.palette.background.surface4};
                }
            }
        
            &.wcux-nxt-validation-error {
                .wcux-nxt-input {
                    border-bottom: ${VALIDATION_BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.error.main};
                }
        
                .wcux-nxt-validation-icon {
                    color: ${theme.palette.error.main};
                }
            }
        
            &.wcux-nxt-validation-warning {
                .wcux-nxt-input {
                    border-bottom: ${VALIDATION_BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.warning.main};
                }
        
                .wcux-nxt-validation-icon {
                    color: ${theme.palette.warning.main};
                }
            }
        
            &.wcux-nxt-validation-confirmation {
                .wcux-nxt-input {
                    border-bottom: ${VALIDATION_BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.success.main};
                }
        
                .wcux-nxt-validation-icon {
                    color: ${theme.palette.success.main};
                }
            }
        
            .wcux-nxt-validation-message {
                display: flex;
                align-items: start;
                padding-top: 2px;
            
                margin-top: 4px;
                font-family: ${theme.typography.fontFamily};
                font-size: ${theme.typography.fontSizes.medium};
                color: ${theme.palette.text.primary};
            
                .wcux-nxt-validation-icon {
                    height: ${theme.spacing(2)}px;
                    width: ${theme.spacing(2)}px;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }
            }

            .wcux-nxt-validation-message-disabled {
                display: flex;
                align-items: start;
                padding-top: 2px;
            
                margin-top: 4px;
                font-family: ${theme.typography.fontFamily};
                font-size: ${theme.typography.fontSizes.medium};
                color: ${theme.palette.text.disabled};
            
                .wcux-nxt-validation-icon {
                    height: ${theme.spacing(2)}px;
                    width: ${theme.spacing(2)}px;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }
            }

            .wcux-nxt-input-icon {
                width: ${theme.spacing(2)}px;
                height: ${theme.spacing(2)}px;
                margin-right: 5px;
        
                img,
                svg {
                    width: ${theme.spacing(2)}px;
                    height: ${theme.spacing(2)}px;
                }
            }
        
            .wcux-nxt-input-message-container {
                display: flex;
                justify-content: space-between;
            }
        
            .wcux-nxt-input-charcounter {
                margin-top: 5px;
                font-family: ${theme.typography.fontFamily};
                font-size: ${theme.typography.fontSizes.small};
                color: ${theme.palette.grey[104]};
                padding-top: 2px;
                padding-left: 5px;
            }
        
            ::-webkit-calendar-picker-indicator {
                margin-left: 0; // Reduce whitespace from covering text
            }
        }
    `}
`;

export default styles;
