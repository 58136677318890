/**
 * @file Pagination control
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import { ButtonBase, IconButton, Input, TablePagination } from '@material-ui/core';
import MUIPagination from '@material-ui/lab/Pagination';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Popup, MenuList } from '../';
import CaretRightArrow from '../Icons/CaretRightArrow';
import CaretLeftArrow from '../Icons/CaretLeftArrow';

export const IDS = {
    PAGINATION_ROOT: 'pagination-root',
    SHOWING_ITEMS_LABEL: 'items-shown-label',
    PAGE_INDEX_LABEL: 'page-index',
    SHOWING_ITEMS_RANGE: 'items-shown-number',
    GO_TO_PAGE_MENU: 'go-to-page-menu',
    GO_TO_PAGE_MENU_LABEL: 'go-to-page-menu-label',
    GO_TO_PAGE_INPUT: 'page-input',
    GO_TO_PAGE_BUTTON: 'go-to-page-button',
    CURRENT_PAGE: 'current-page',
};

const defaultLocales = {
    itemsPerPage: 'Items per page',
    goToPage: 'Go to page',
    showing: 'Showing',
    of: 'of',
    page: 'Page',
};

/**
 * Pagination bar component, including menus to change items per page and the page index
 * @returns {JSX.Element} - The component
 */
const Pagination = ({
    totalItems,
    itemsPerPage,
    itemsPerPageOptions,
    onChangeItemsPerPage,
    currentPage: currentPageProp,
    totalPages: totalPagesProp,
    onChangePage,
    siblingCount,
    locales,
    className,
    'data-testid': dataTestId,
    simplifiedPagination,
}) => {
    const [itemsPerPageMenuAnchorEl, setItemsPerPageMenuAnchorEl] = useState(null);
    const [goToMenuAnchorEl, setGoToMenuAnchorEl] = useState(null);

    const currentPage = totalItems > 0 ? currentPageProp : 1;
    // The input value
    const [goToPageIndex, setGoToPageIndex] = useState(currentPage);
    // Total pages should be 1 when totalItems are 0
    const totalPages = totalItems > 0 ? totalPagesProp : 1;
    // No items means current item index should be 0
    const currentItemIndex = totalItems > 0 ? currentPage * itemsPerPage - itemsPerPage + 1 : 0;
    const itemsUpperRange = simplifiedPagination
        ? currentPage * itemsPerPage - (itemsPerPage - totalItems)
        : Math.min(totalItems, currentPage * itemsPerPage);

    const mergedLocales = { ...defaultLocales, ...locales };

    useEffect(() => {
        if (goToPageIndex !== currentPage) {
            setGoToPageIndex(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    /**
     * @param {any} value - Any value to check
     * @returns {boolean} - True if function
     */
    const isFunction = (value) => typeof value === 'function';

    const closeItemsPerPageMenu = () => setItemsPerPageMenuAnchorEl(null);
    const closeGoToPageMenu = () => setGoToMenuAnchorEl(null);

    const index = parseInt(goToPageIndex);
    const isGoToButtonDisabled = !index || currentPage === index || index > totalPages || index < 1;

    /**
     * Called when an option in the items-per-page menu is clicked
     * @param {number} selectedItemsPerPage - The number of items to display
     * @param {number} index - The index of selected item
     */
    const handleOnClickItemsPerPage = (selectedItemsPerPage) => {
        if (isFunction(onChangeItemsPerPage) && selectedItemsPerPage !== itemsPerPage) {
            onChangeItemsPerPage(selectedItemsPerPage);
        }

        closeItemsPerPageMenu();
    };

    // If we tab out of the list, restore focus to the button that opens it
    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            itemsPerPageMenuAnchorEl && itemsPerPageMenuAnchorEl.focus();
            closeItemsPerPageMenu();
        }
    };

    /**
     * Sanitizes the value into a valid page number.
     * @param {any} value - Value to sanitize
     * @returns {number} - Page number
     */
    const validatePageNumber = (value) => {
        value = parseInt(value);

        if (isNaN(value) || value < 1) {
            return 1;
        } else if (value > totalPages) {
            return totalPages;
        }

        return value;
    };

    /**
     * After the user is finished typing, check if the input number is valid, if not, then rewrite it
     * @param {object} event - Input blur event
     */
    const handleInputBlur = (event) => {
        setGoToPageIndex(validatePageNumber(event.target.value));
    };

    // Needed so that user interaction updates the controlled input value
    const handleInputChange = (event) => setGoToPageIndex(event.target.value);

    const handleGoToButtonClick = () => {
        if (isFunction(onChangePage) && currentPage !== goToPageIndex) {
            onChangePage(goToPageIndex);
            closeGoToPageMenu();
        }
    };

    const handleGoToInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            const value = validatePageNumber(event.target.value);
            if (isFunction(onChangePage) && currentPage !== value) {
                onChangePage(value);
                closeGoToPageMenu();
            }
        }
    };

    // If we tabbed out of the go to menu controls, restore focus to the menu toggle
    const checkTabOut = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            goToMenuAnchorEl && goToMenuAnchorEl.focus();
            closeGoToPageMenu();
        }
    };

    const handleInputKeyDown = (event) => {
        if (isGoToButtonDisabled) {
            checkTabOut(event);
        }
    };

    const isLastPageOfSimplifiedPagination = useCallback(() => totalItems < itemsPerPage, [itemsPerPage, totalItems]);

    const getItemsRange = useCallback(() => {
        return `${currentItemIndex} - ${itemsUpperRange}`;
    }, [currentItemIndex, itemsUpperRange]);

    const getRangeText = useCallback(() => {
        if (!simplifiedPagination) {
            return `${mergedLocales.of} ${totalItems}`;
        }

        if (simplifiedPagination && isLastPageOfSimplifiedPagination()) {
            return `${mergedLocales.of} ${itemsUpperRange}`;
        }

        if (simplifiedPagination && !isLastPageOfSimplifiedPagination()) {
            return `${mergedLocales.ofMoreThan} ${itemsUpperRange}`;
        }
    }, [isLastPageOfSimplifiedPagination, itemsUpperRange, mergedLocales.of, mergedLocales.ofMoreThan, simplifiedPagination, totalItems]);

    const handleGoToButtonKeyDown = checkTabOut;

    const getTestID = (testId) => (dataTestId ? dataTestId + '-' + testId : testId);

    // TODO use Webcore NextGen input when it can be customized to retain invalid values (Currently it clears)
    return (
        <div className={classnames('wcux-nxt-pagination-control', className)} data-testid={getTestID(IDS.PAGINATION_ROOT)}>
            <span data-testid={getTestID(IDS.SHOWING_ITEMS_LABEL)}>
                {mergedLocales.showing}{' '}
                {isFunction(onChangeItemsPerPage) ? (
                    <ButtonBase
                        data-testid={getTestID(IDS.SHOWING_ITEMS_RANGE)}
                        aria-haspopup={'true'}
                        className={'wcux-nxt-pagination-control-highlight'}
                        onClick={(e) => setItemsPerPageMenuAnchorEl(e.target)}
                        disableRipple={true}
                    >
                        <span className={'wcux-nxt-pagination-control-highlight-inner'}>{getItemsRange()}</span>
                    </ButtonBase>
                ) : (
                    <span data-testid={getTestID(IDS.SHOWING_ITEMS_RANGE)}>{getItemsRange()}</span>
                )}{' '}
                {getRangeText()}
            </span>
            <span className={'wcux-nxt-pagination-page-control'}>
                {!simplifiedPagination && (
                    <span className={'wcux-nxt-pagination-page-index-label'} data-testid={getTestID(IDS.PAGE_INDEX_LABEL)}>
                        <>
                            {mergedLocales.page}{' '}
                            <ButtonBase
                                aria-haspopup={'true'}
                                className={'wcux-nxt-pagination-control-highlight'}
                                onClick={(e) => setGoToMenuAnchorEl(e.target)}
                                data-testid={getTestID(IDS.CURRENT_PAGE)}
                                disableRipple={true}
                                disabled={simplifiedPagination}
                            >
                                <span className={'wcux-nxt-pagination-control-highlight-inner'}>{currentPage}</span>
                                <span className={'wcux-nxt-pagination-control-padding'} />
                            </ButtonBase>{' '}
                            {mergedLocales.of} {totalPages}
                        </>
                    </span>
                )}

                {!simplifiedPagination && (
                    <MUIPagination
                        count={totalPages}
                        onChange={(event, page) => onChangePage(page)}
                        page={currentPage}
                        siblingCount={siblingCount}
                    />
                )}
                {simplifiedPagination && (
                    <TablePagination
                        component="div"
                        count={-1}
                        page={currentPage}
                        onChangePage={(event, page) => onChangePage(page)}
                        labelDisplayedRows={() => null}
                        rowsPerPageOptions={[]}
                        rowsPerPage={itemsPerPage}
                        ActionsComponent={() => (
                            <>
                                <IconButton
                                    data-testid="pagination-previous-page-button"
                                    onClick={() => onChangePage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <CaretLeftArrow />
                                </IconButton>
                                <IconButton
                                    data-testid="pagination-next-page-button"
                                    onClick={() => onChangePage(currentPage + 1)}
                                    disabled={isLastPageOfSimplifiedPagination()}
                                >
                                    <CaretRightArrow />
                                </IconButton>
                            </>
                        )}
                    />
                )}
            </span>
            {itemsPerPageMenuAnchorEl && (
                <Popup
                    isOpen={Boolean(itemsPerPageMenuAnchorEl)}
                    anchorEl={itemsPerPageMenuAnchorEl}
                    placement={'top'}
                    onOutsideClick={closeItemsPerPageMenu}
                    disablePortal={true}
                >
                    <MenuList
                        className={'wcux-nxt-pagination-items-per-page-list'}
                        onKeyDown={handleListKeyDown}
                        menuOptions={itemsPerPageOptions}
                        onClick={(selectedItemsPerPage) => handleOnClickItemsPerPage(selectedItemsPerPage)}
                        selected={itemsPerPage}
                        label={mergedLocales.itemsPerPage}
                    />
                </Popup>
            )}
            {goToMenuAnchorEl && (
                <Popup
                    isOpen={Boolean(goToMenuAnchorEl)}
                    anchorEl={goToMenuAnchorEl}
                    placement={'top'}
                    onOutsideClick={closeGoToPageMenu}
                    disablePortal={true}
                >
                    <div className={'wcux-nxt-pagination-go-to-page-menu'} data-testid={getTestID(IDS.GO_TO_PAGE_MENU)}>
                        <label>
                            <div className={'wcux-nxt-pagination-go-to-page-menu-label'} data-testid={getTestID(IDS.GO_TO_PAGE_MENU_LABEL)}>
                                {mergedLocales.goToPage}
                            </div>
                            <Input
                                type="number"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={goToPageIndex}
                                data-testid={getTestID(IDS.GO_TO_PAGE_INPUT)}
                                className={'wcux-nxt-pagination-go-to-page-input'}
                                disableUnderline={true}
                                autoFocus={true}
                                onFocus={(event) => event.target.select()}
                                onKeyPress={handleGoToInputKeyPress}
                                onKeyDown={handleInputKeyDown}
                                inputProps={{
                                    min: 1,
                                    max: totalPages,
                                }}
                            />
                        </label>
                        <Button
                            size="small"
                            className={'wcux-nxt-pagination-go-to-page-button'}
                            disabled={isGoToButtonDisabled}
                            onClick={handleGoToButtonClick}
                            data-testid={getTestID(IDS.GO_TO_PAGE_BUTTON)}
                            onKeyDown={handleGoToButtonKeyDown}
                            startIcon={{
                                icon: <CaretRightArrow />,
                            }}
                        />
                    </div>
                </Popup>
            )}
        </div>
    );
};

const StyledPagination = styled(Pagination)`
    ${({ theme }) => `
        &.wcux-nxt-pagination-control {
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.body1.fontSize};
            display: flex;
            justify-content: space-between;
            line-height: 32px;

            [class*='MuiPaginationItem-page'] {
                vertical-align: top;
            }

            [class*='MuiPaginationItem-root'] {
                font-size: ${theme.typography.body1.fontSize};

                &[class*='Mui-selected']:hover {
                    background-color: ${theme.palette.action.selected};
                }
            }

            .wcux-nxt-pagination-control-highlight {
                font-family: ${theme.typography.fontFamily};
                font-size: ${theme.typography.body1.fontSize};
                padding-top: 0;
                border-bottom: 2px solid ${theme.palette.primary.main};
                cursor: pointer;
                vertical-align: unset;
                &:focus {
                    outline: 0;

                    .wcux-nxt-pagination-control-highlight-inner {
                        background-color: ${theme.palette.background.surface1};
                        border-radius: 4px;
                    }
                }
            }

            .wcux-nxt-pagination-control-highlight-inner {
                padding: 2px;
                padding-bottom: 4px;
            }

            // This increases the click surface area of the control
            .wcux-nxt-pagination-control-padding {
                width: 30px;
                height: 30px;
                position: absolute;
            }

            .wcux-nxt-pagination-page-control {
                display: flex;
            }

            .wcux-nxt-pagination-page-index-label {
                margin-right: 8px;
            }

            .wcux-nxt-pagination-items-per-page-menu {
                font-family: ${theme.typography.fontFamily};
                min-width: 50px;
            }

            .wcux-nxt-pagination-items-per-page-menu-label {
                font-family: ${theme.typography.fontFamily};
                padding: 4px 16px;
                color: ${theme.typography.secondary};
            }

            .wcux-nxt-pagination-items-per-page-list {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-bottom: 8px;
            }

            .wcux-nxt-pagination-items-per-page-option {
                height: 40px;
                line-height: 40px;
                padding: 0 16px;
                cursor: pointer;
                width: 100%;
                border: 0;
                background: 0;
                text-align: left;

                &:hover {
                    background-color: ${theme.palette.action.hover};
                    transition: 0.1s;
                }

                &:focus {
                    outline: 0;
                    background-color: ${theme.palette.action.hover};
                    transition: 0.1s;
                }

                &.-selected {
                    background-color: ${theme.palette.action.selected};
                    &:hover {
                        background-color: ${theme.palette.action.selected};
                    }
                }
            }

            .wcux-nxt-pagination-go-to-page-menu {
                font-family: ${theme.typography.fontFamily};
                padding: 16px;
                padding-top: 8px;

                & .wcux-nxt-button {
                    margin-left: 8px;

                    &.wcux-nxt-button-primary {
                        vertical-align: top;
                        width: 24px;
                    }
                }
            }

            .wcux-nxt-pagination-go-to-page-menu-label {
                margin-bottom: 8px;
                color: ${theme.typography.secondary};
            }

            .wcux-nxt-pagination-go-to-page-input {
                font-family: ${theme.typography.fontFamily};
                border: 1px solid ${theme.palette.secondary.light};
                border-radius: 4px;
                padding: 2px 10px;
                width: 60px;
                height: 32px;

                &.Mui-focused {
                    border-color: ${theme.palette.primary.main};
                }
            }
            
            .MuiTablePagination-toolbar {
                min-height: initial;
              
                & .MuiIconButton-root {
                    padding: 6px;
                    & .MuiSvgIcon-root {
                        font-size: 1.25rem;
                    }
            }
        }
    `}
`;

Pagination.defaultProps = {
    currentPage: 1,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 1,
    itemsPerPageOptions: [
        {
            value: 10,
        },
        {
            value: 20,
        },
        {
            value: 50,
        },
        {
            value: 100,
        },
    ],
    locales: {},
    siblingCount: 1,
};

Pagination.propTypes = {
    /** Current page index that the user is on (starting from 1) */
    currentPage: PropTypes.number.isRequired,
    /** The total number of pages the user can traverse */
    totalPages: PropTypes.number.isRequired,
    /**
     * Called when a page button is clicked, or when the 'go to page' menu button is clicked.
     * Signature: (page: number) -> void
     */
    onChangePage: PropTypes.func.isRequired,
    /** Total number of items, rows, etc. in the data set */
    totalItems: PropTypes.number.isRequired,
    /** Class name to append to the element root */
    className: PropTypes.string,
    /** The selected number of items per page. Used to calculate current item display range, and highlights the selection in the menu. */
    itemsPerPage: PropTypes.number,
    /** The list of options to display in the items per page menu */
    itemsPerPageOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            displayLabel: PropTypes.string,
        })
    ),
    /**
     * Called when an option is clicked in the items per page menu. Required for the items per page menu.
     * Signature: (option: number) -> void
     */
    onChangeItemsPerPage: PropTypes.func,
    /** How many pages to display either side of the current page */
    siblingCount: PropTypes.number,
    /** Localizable label text. For any of the properties not supplied, it will fall back to English. */
    locales: PropTypes.shape({
        itemsPerPage: PropTypes.string,
        goToPage: PropTypes.string,
        showing: PropTypes.string,
        of: PropTypes.string,
        page: PropTypes.string,
    }),
    /** If provided, prepends the string to this components data-testids */
    'data-testid': PropTypes.string,
    /** Don't display anything related to amount of data in pagination component */
    simplifiedPagination: PropTypes.bool,
};

export default StyledPagination;
