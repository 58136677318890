/**
 * @file Context for horizontalPanelResizer
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useState, useCallback, useRef, createContext } from 'react';
import PropTypes from 'prop-types';
import { DRAG_BAR_HEIGHT } from './constants';

/**
 * @param {number} topElementHeight RealTime dragging element's top position
 * @return {string} a panel height to use
 */

const defaultCalcTopPanel = (topElementHeight) => `${topElementHeight}px`;

/**
 * @param {number} topElementHeight RealTime dragging element's top position
 * @return {string} a bottom panel height to use
 */
const defaultCalcBottomPanel = (topElementHeight) => `calc( 100% - ${topElementHeight + DRAG_BAR_HEIGHT}px)`;

export const initSettings = {
    /** The initial top panel position height to be displayed in the vertical resize wrapper. should be percentage default 0.5 */
    initTopPanelHeightPercentage: 0.5,
    /** Custom height calculation function default (topElementHeight) =>  `${topElementHeight}px` */
    calcTopPanel: defaultCalcTopPanel,
    /** Custom bottom calculation function default (topElementHeight) =>  `calc( 100% - ${topElementHeight + DRAG_BAR_HEIGHT}px)` */
    calcBottomPanel: defaultCalcBottomPanel,
    /** when dragging the bar to the top, how many pixels from the top should the bar automatically collapse. */
    topCollapseSpace: 0,
    /** when dragging the bar to the bottom, how many pixels from the bottom should the bar automatically collapse. */
    bottomCollapseSpace: DRAG_BAR_HEIGHT,
    /** can prevent the drag over the wrapper need wrapperRef */
    shouldPreventOverflow: true,
    /** the max value of top height for top panel position  */
    maxTop: 0,
    /** the max value of top height for bottom panel position  */
    maxBottom: 0,
    /** disable resize feature  */
    disable: false,
};

const Context = createContext({});

/**
 * HorizontalPanelResizer Provider.
 * @returns {React.Component} - react component
 */
export const Provider = ({ settings: propSettings, children }) => {
    const [isDragged, setIsDragged] = useState(null);
    const [topElementHeight, setTopElementHeight] = useState(null);
    const [isResizing, setIsResizing] = useState(false);
    const [isTop, setIsTop] = useState(false);
    const [isBottom, setIsBottom] = useState(false);
    const [wrapperBoundingClientRect, setWrapperBoundingClientRect] = useState({});
    const wrapperRef = useRef(null);
    const dragBarRef = useRef(null);
    const [settings, setSettings] = useState({ ...initSettings, ...propSettings });

    const setDefaultValue = useCallback(() => {
        setIsDragged(null);
        setIsBottom(null);
        setIsTop(null);
        setTopElementHeight(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Context.Provider
            value={{
                state: { isDragged, topElementHeight, isResizing, isTop, isBottom, wrapperBoundingClientRect },
                control: {
                    setIsDragged,
                    setTopElementHeight,
                    setIsResizing,
                    setIsTop,
                    setIsBottom,
                    setWrapperBoundingClientRect,
                    setDefaultValue,
                    setSettings,
                },
                settings,
                wrapperRef,
                dragBarRef,
            }}
        >
            {children}
        </Context.Provider>
    );
};

Provider.propTypes = {
    /** content */
    children: PropTypes.node.isRequired,
    /** collection of settings */
    settings: PropTypes.shape({
        /** The initial top panel position height to be displayed in the vertical resize wrapper. should be percentage or cell of px default 0.5 */
        initTopPanelHeightPercentage: PropTypes.number,
        /** Custom top height calculation function default (topElementHeight) =>  `${topElementHeight}px``
         *  @param {number} topElementHeight cell of px
         */
        calcTopPanel: PropTypes.func,
        /** Custom bottom calculation function default (topElementHeight) =>  `calc( 100% - ${topElementHeight + DRAG_BAR_HEIGHT}px)`
         *  @param {number} topElementHeight cell of px
         */
        calcBottomPanel: PropTypes.func,
        /** when dragging the bar to the top, how many pixels from the top should the bar automatically collapse. */
        topCollapseSpace: PropTypes.number,
        /** when dragging the bar to the bottom, how many pixels from the bottom should the bar automatically collapse. */
        bottomCollapseSpace: PropTypes.number,
        /** can prevent the drag over the wrapper need wrapperRef */
        shouldPreventOverflow: PropTypes.bool,
        /** the max value of top height for top panel position  */
        maxTop: PropTypes.number,
        /** the max value of top height for bottom panel position  */
        maxBottom: PropTypes.number,
        /** disable resize feature  */
        disable: false,
    }),
};

/**
 * HorizontalPanelResizer HOC.
 * @returns {React.Component} - react component
 */

// eslint-disable-next-line react/display-name
export const withVerticalResize = (Component) => (props) => {
    return (
        <Provider>
            <Component {...props} />
        </Provider>
    );
};

export default Context;
