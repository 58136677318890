/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export default function ExpandLess(props) {
    return <ExpandLessIcon {...props} />;
}
