/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';

export default function HomeTwoTone(props) {
    return <HomeTwoToneIcon {...props} />;
}
