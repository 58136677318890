/**
 * @file WidgetPie component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import classNames from 'classnames';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';
import i18next from 'i18next';
import { redrawHighchartsCharts } from '../../functions/redrawHighchartCharts/redrawHighchartCharts';

/** disable highcharts.com credits on bottom of chart */
Highcharts.setOptions({
    credits: {
        enabled: false,
    },
});

/**
 * Hook used to fetch Pie metrics for the logged in user
 * @param {function} getData - get data pending promise
 * @returns {object} - pie meta and series data

 */
const useWidgetPieMetric = (getData) => {
    const [pieMetric, setPieMetric] = useState([]);

    const doFetchPieMetric = useCallback(async () => {
        try {
            const data = await getData();
            setPieMetric(data);
            return data;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(`WidgetPieChart: Failed to retrieve data: ${err}`);
        }
    }, [getData]);

    return { pieMetric, doFetchPieMetric, setPieMetric };
};

const Container = styled.div`
    height: 95%;
    & > [data-highcharts-chart] {
        height: 100%;
        .highcharts-container {
            height: 100% !important;
            svg {
                height: 100% !important;
            }
        }
    }
`;

const Widget = styled.div`
    .hidden {
        visibility: hidden;
    }

    .visible {
        visibility: visible;
    }
`;

const generateSeriesData = (pieMetric) => {
    return pieMetric.series.map((metric) => ({
        name: metric.name,
        y: metric.value,
        sliced: false,
        selected: false,
    }));
};

/**
 * creates a widget pie component
 * @param {boolean} maximized - a boolean flag if the widget is in maximized mode
 * @param {string} title - the title of the pie chart
 * @param {Array} colors - a list of hex colors to use for the chart pieces
 * @param {function} getData - a pending getData promise
 * @param {function} onClick - handler to process the pie chart section click event
 * @returns {JSX.Element} the component
 * @constructor
 */

// eslint-disable-next-line react/prop-types
export const WidgetPieChart = ({ title = '', colors = [], getData, onClick = () => {} }) => {
    title = i18next.t(title);

    const containerRef = useRef({ current: {} });

    const updateChart = () => ({
        chart: {
            type: 'pie',
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: containerRef.current ? containerRef.current.clientHeight : '100%',
            width: containerRef.current ? containerRef.current.clientWidth : null,
        },
        title: {
            text: title,
        },
        tooltip: {
            style: { fontSize: '14px' },
            headerFormat: '<b style="font-size:18px">{point.key}</b></br><table>',
            pointFormat: '<b style="font-size:18px">{point.percentage:.1f}%</b></br>',
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        plotOptions: {
            pie: {
                size: '95%',
                innerSize: '30%',
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                ...(colors.length && { colors }),
                events: {
                    click: onClick,
                },
            },
        },
        series: [
            {
                name: title,
                colorByPoint: true,
                data: pieMetric && pieMetric.series ? generateSeriesData(pieMetric) : [],
            },
        ],
    });

    const { pieMetric, doFetchPieMetric } = useWidgetPieMetric(getData);
    const [pieChartOptions, setPieChartOptions] = useState(updateChart());

    useEffect(() => {
        doFetchPieMetric();
    }, []);

    useEffect(() => {
        const formatted = updateChart();
        setPieChartOptions(formatted || {});
        redrawHighchartsCharts(500);
    }, [containerRef.current.clientWidth, containerRef.current.clientHeight, getData, pieMetric]);

    return (
        <Widget ref={containerRef}>
            <ProgressBar
                data-testid={'de-cmn-widget-pie-progress-bar'}
                className={classNames({ hidden: pieMetric.breadcrumbId, visible: !pieMetric.breadcrumbId })}
            />
            <Container
                data-testid={'de-cmn-widget-pie-root'}
                className={classNames({ hidden: !pieMetric.breadcrumbId, visible: pieMetric.breadcrumbId })}
            >
                <HighchartsReact
                    data-testid="de-cmn-widget-pie-root"
                    highcharts={Highcharts}
                    options={pieChartOptions}
                    updateArgs={[true, true, true]}
                />
            </Container>
        </Widget>
    );
};

export default WidgetPieChart;
