/**
 * @module webcore-ux/nextgen/components/ToggleButton/ToggleButton
 * @copyright © Copyright 2021 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MuiToggleButton } from '@material-ui/lab/ToggleButton';
import styles from './styles';
import styled from 'styled-components';

/**
 * Toggle button control.
 * @returns {JSX.Element} - The component to render
 */
const ToggleButton = ({ children, className, disabled, icon, selected, value, grouped, size, ...other }) => {
    let buttonIcon = null;

    if (icon) {
        if (React.isValidElement(icon)) {
            buttonIcon = <div className="wcux-nxt-toggle-button-icon">{icon}</div>;
        } else {
            buttonIcon = (
                <div className="wcux-nxt-toggle-button-icon">
                    <img src={icon} />
                </div>
            );
        }
    }

    if (grouped) {
        return (
            <MuiToggleButton
                data-testid="wcux-nxt-toggle-button"
                classes={{ selected: 'wcux-nxt-toggle-button-selected' }}
                className={classNames('wcux-nxt-toggle-button-root', className)}
                disabled={disabled}
                disableRipple={true}
                selected={selected}
                value={value}
                {...other}
            >
                {buttonIcon}
                {children ? <div className="wcux-nxt-toggle-button-content">{children}</div> : null}
            </MuiToggleButton>
        );
    } else {
        const sizeClass = `wcux-nxt-toggle-button-group-${size}`;
        return (
            <div data-testid="wcux-nxt-toggle-button" className={className}>
                <div className={sizeClass}>
                    <MuiToggleButton
                        data-testid="wcux-nxt-toggle-button"
                        classes={{ selected: 'wcux-nxt-toggle-button-selected' }}
                        className="wcux-nxt-toggle-button-root"
                        disabled={disabled}
                        disableRipple={true}
                        selected={selected}
                        value={value}
                        {...other}
                    >
                        {buttonIcon}
                        {children ? <div className="wcux-nxt-toggle-button-content">{children}</div> : null}
                    </MuiToggleButton>
                </div>
            </div>
        );
    }
};

ToggleButton.defaultProps = {
    disabled: false,
    grouped: false,
    size: 'normal',
};

ToggleButton.propTypes = {
    /** Button content */
    children: PropTypes.node,

    /** CSS class name */
    className: PropTypes.string,

    /** True to disable the button */
    disabled: PropTypes.bool,

    /** Icon to show on the button. Can be an img src string or React node such as svg. */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /** True to render the button in the selected state */
    selected: PropTypes.bool,

    /** The value to associate with the button when selected in a ToggleButtonGroup */
    value: PropTypes.any,

    /**
     * Size of buttons.
     */
    size: PropTypes.oneOf(['small', 'normal', 'large']),

    grouped: PropTypes.bool,
};

export default styled(ToggleButton)`
    ${styles}
`;
