/**
 * @file WidgetCardGridLayout component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useEffect } from 'react';
import { CardGridLayout, CardGridLayoutProvider, CardItem, useCardGridLayoutContext } from 'webcore-ux/nextgen/components/CardGridLayout';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { WidgetSelector } from './WidgetSelector/WidgetSelector';
import { redrawHighchartsCharts } from '../../functions/redrawHighchartCharts/redrawHighchartCharts';
import { getWidgetDataSelector, getWidgetOnClickSelector } from './utils/widgetTransformConfig';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';

const useCardItemStyles = makeStyles(() => ({
    root: {
        height: '98%',
        padding: 0,
    },
    container: {
        height: 'inherit',
        padding: 0,
    },
    widgetContainer: {
        height: 'inherit',
        display: 'flex',
        width: '100%',
        '& > :first-child': {
            width: '100%',
            height: '100%',
        },
    },
}));

/** export context so client can update **/
export { useCardGridLayoutContext, CardGridLayoutProvider };

/**
 * renders a card grid layout
 * @param {function} getToken - the get token function
 * @param {string} host - the base api url
 * @param {function} onLayoutChange - the on layout change handler
 * @param {object} overrides - object with option override functions
 * @constructor
 */
export const RenderCardGridLayout = ({ getToken, host, onLayoutChange, overrides }) => {
    const cardGridContext = useCardGridLayoutContext();
    const customCardItemClasses = useCardItemStyles();

    useEffect(() => {
        redrawHighchartsCharts();
    }, []);

    if (!cardGridContext.config) {
        return <ProgressBar />;
    }

    const extendedStyles = {
        item: 'background-color:white;',
    };

    return (
        <CardGridLayout
            extendedStyles={extendedStyles}
            data-testid="de-cmn-nxt-widget-grid-card-layout"
            onLayoutChange={(currentBreakpoint, allBreakPoints) => {
                onLayoutChange(currentBreakpoint, allBreakPoints);
                redrawHighchartsCharts(500);
                redrawHighchartsCharts();
            }}
        >
            {cardGridContext.config &&
                cardGridContext.config.Cards.map((Card) => (
                    <CardItem
                        useStyles={useCardItemStyles}
                        key={Card.id}
                        id={Card.id}
                        data-testid={`de-cmn-nxt-widget-grid-card-item-${Card.id}`}
                        headerContent={i18next.t(Card.title)}
                        showMaximizeButton={Card.showMaximizeButton}
                        openInNew={Card.openInNew}
                    >
                        <div className={customCardItemClasses.widgetContainer}>
                            {
                                WidgetSelector({
                                    id: Card.id,
                                    type: Card.type,
                                    subtype: Card.subtype,
                                    params: {
                                        chartPie: {
                                            ...Card.params.chartPie,
                                            getData: () => getWidgetDataSelector(getToken, host, Card, overrides),
                                            events: {
                                                onClick: getWidgetOnClickSelector(Card, overrides),
                                            },
                                        },
                                        chartBar: {
                                            ...Card.params.chartBar,
                                            getData: () => getWidgetDataSelector(getToken, host, Card, overrides),
                                            events: {
                                                onClick: getWidgetOnClickSelector(Card, overrides),
                                            },
                                        },
                                        kpi: {
                                            ...Card.params.kpi,
                                            getData: () => getWidgetDataSelector(getToken, host, Card, overrides),
                                            events: {
                                                onClick: getWidgetOnClickSelector(Card, overrides),
                                            },
                                        },
                                        count: {
                                            ...Card.params.count,
                                            getData: () => getWidgetDataSelector(getToken, host, Card, overrides),
                                            events: {
                                                onClick: getWidgetOnClickSelector(Card, overrides),
                                            },
                                        },
                                        dataGrid: {
                                            ...Card.params.dataGrid,
                                            getData: () => getWidgetDataSelector(getToken, host, Card, overrides),
                                        },
                                        attachments: {
                                            ...Card.params.attachments,
                                            getData: () => getWidgetDataSelector(getToken, host, Card, overrides),
                                        },
                                        attributes: {
                                            ...Card.params.attributes,
                                            getData: getWidgetDataSelector(getToken, host, Card, overrides),
                                        },
                                    },
                                }).component
                            }
                        </div>
                    </CardItem>
                ))}
        </CardGridLayout>
    );
};

RenderCardGridLayout.defaultProps = {
    onLayoutChange: () => {},
};

RenderCardGridLayout.propTypes = {
    /** the base api url **/
    host: PropTypes.string.isRequired,
    /** the get token function **/
    getToken: PropTypes.func.isRequired,
    /** on layout change handler **/
    onLayoutChange: PropTypes.func,
    /** object containing optional override functions **/
    overrides: PropTypes.object,
};

/**
 * renders a card grid layout
 * @param {string} className - custom classname
 * @param {function} getToken - the getToken function
 * @param {string} host - the base api host url
 * @param {function} onLayoutChange - the on layout change handler
 * @param {object} overrides - object with option override functions
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const WidgetCardGridLayout = ({ className, getToken, host, onLayoutChange, overrides = {} }) => {
    return (
        <div className={className} data-testid="de-cmn-nxt-widget-grid-card-layout-container">
            <RenderCardGridLayout getToken={getToken} host={host} onLayoutChange={onLayoutChange} overrides={overrides} />
        </div>
    );
};

WidgetCardGridLayout.defaultProps = {
    onLayoutChange: () => {},
};

WidgetCardGridLayout.propTypes = {
    /** custom classname **/
    className: PropTypes.string,
    /** the base api url **/
    host: PropTypes.string.isRequired,
    /** the get token function **/
    getToken: PropTypes.func.isRequired,
    /** loading state boolean **/
    loading: PropTypes.bool,
    /** on layout change handler **/
    onLayoutChange: PropTypes.func,
    /** object containing optional override functions **/
    overrides: PropTypes.object,
};

export default WidgetCardGridLayout;
