/**
 * @module webcore-ux/nextgen/components/Button/styles
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import { alpha } from '@material-ui/core';
import { css } from 'styled-components';

const DISCRETE_BORDER_WIDTH = '1px';
const DISCRETE_BORDER_TYPE = 'solid';

const styles = css`
    ${({ theme }) => ` 
        && { // Increase specificity to override MUI styles
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSizes.large};
            text-transform: none;
            transition: none;
            height: 40px;
            min-width: 40px;
            padding: 0px ${theme.spacing(2)}px;
            border-radius: 8px;
            box-shadow: ${theme.shadows[2]};
        }

        .wcux-nxt-button-icon {
            width: 24px;
            height: 24px;

            &.-justify-left {
                margin-right: auto;
            }

            &.-justify-right {
                margin-left: auto;
            }

            img,
            svg {
                width: 24px;
                height: 24px;
            }
        }

        .wcux-nxt-button-content {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.-start {
                margin-left: ${theme.spacing(1)}px;
            }

            &.-end {
                margin-right: ${theme.spacing(1)}px;
            }
        }

        &:disabled {
            opacity: 0.26;
        }

        &:active {
            box-shadow: ${theme.shadows[8]};
        }

        &.wcux-nxt-button-small {
            height: 32px;
            min-width: 32px;
        }

        &.wcux-nxt-button-x-small {
            font-size: ${theme.typography.fontSizes.medium};
            height: 24px;
            min-width: 24px;

            .wcux-nxt-button-icon {
                width: 16px;
                height: 16px;

                img,
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        &.wcux-nxt-button-primary {
            svg {
                fill: ${theme.palette.primary.contrastText};
            }

            &.Mui-disabled {
                background-color: ${theme.palette.primary.main};
                color: ${theme.palette.primary.contrastText};
                svg {
                    fill: ${theme.palette.primary.contrastText};
                }
            }
        }

        &.wcux-nxt-button-secondary {
            svg {
                fill: ${theme.palette.secondary.contrastText};
            }

            &.Mui-disabled {
                background-color: ${theme.palette.secondary.main};
                color: ${theme.palette.secondary.contrastText};
                svg {
                    fill: ${theme.palette.secondary.contrastText};
                }
            }
        }

        &.wcux-nxt-button-discrete {
            background-color: transparent;
            border: ${DISCRETE_BORDER_WIDTH} ${DISCRETE_BORDER_TYPE} ${alpha(theme.palette.primary.main, 0.54)};
            box-shadow: unset;
            
            &, &.Mui-disabled {
                color: ${theme.palette.primary.main};
            }

            &.Mui-disabled {
                border-color: ${theme.palette.primary.main};
            }

            svg {
                fill: ${theme.palette.primary.main};
            }

            &:hover {
                border-color: ${theme.palette.primary.main};
                background-color: transparent;
            }
        }

        &.wcux-nxt-button-secondary-discrete {
            background-color: transparent;
            border: ${DISCRETE_BORDER_WIDTH} ${DISCRETE_BORDER_TYPE} ${alpha(theme.palette.secondary.main, 0.54)};
            box-shadow: unset;

            &, &.Mui-disabled {
                color: ${theme.palette.secondary.main};
            }

            &.Mui-disabled {
                border-color: ${theme.palette.secondary.main};
            }

            .MuiButton-label {
                color: ${theme.palette.secondary.main};
            }

            svg {
                fill: ${theme.palette.secondary.main};
            }

            &:hover {
                border-color: ${theme.palette.secondary.main};
                background-color: transparent;
            }
        }

        &.wcux-nxt-button-ultra-discrete {
            padding: 0 ${theme.spacing(2)}px; // 56px total width when it's icon only
            box-shadow: none;

            &, &.Mui-disabled {
                color: ${theme.palette.primary.main};
            }

            & {
                background-color: transparent;
            }

            &:active {
                box-shadow: none;
            }

            .MuiButton-label {
                color: ${theme.palette.primary.main};
            }

            svg {
                fill: ${theme.palette.primary.main};
            }

            &:hover {
                background-color: ${alpha(theme.palette.primary.main, 0.1)};
            }

            &:disabled {
                svg {
                    fill: ${theme.palette.primary.main};
                }
            }
        }

        &.wcux-nxt-button-secondary-ultra-discrete {
            padding: 0 16px; // 56px total width when it's icon only
            box-shadow: none;

            &, &.Mui-disabled {
                color: ${theme.palette.secondary.main};
            }

            & {
                background-color: transparent;
            }

            &:active {
                box-shadow: none;
            }

            .MuiButton-label {
                color: ${theme.palette.secondary.main};
            }

            svg {
                fill: ${theme.palette.secondary.main};
            }

            &:hover {
                background-color: ${alpha(theme.palette.secondary.main, 0.1)};
            }

            &:disabled {
                svg {
                    fill: ${theme.palette.secondary.main};
                }
            }
        }

        &.wcux-nxt-button-warning {
            background-color: ${theme.palette.warning.main};

            &, &.Mui-disabled {
                color: ${theme.palette.warning.contrastText};
            }

            svg {
                fill: ${theme.palette.warning.contrastText};
            }

            &:hover {
                background-color: ${theme.palette.warning.dark};
            }

            &:disabled {
                background-color: ${theme.palette.warning.main};

                svg {
                    fill: ${theme.palette.warning.contrastText};
                }
            }
        }

        &.wcux-nxt-button-error {
            background-color: ${theme.palette.error.main};

            &, &.Mui-disabled {
                color: ${theme.palette.error.contrastText};
            }

            .MuiButton-label {
                color: ${theme.palette.error.contrastText};
            }
            svg {
                fill: ${theme.palette.error.contrastText};
            }

            &:hover {
                background-color: ${theme.palette.error.dark};
            }

            &:disabled {
                background-color: ${theme.palette.error.main};

                svg {
                    fill: ${theme.palette.error.contrastText};
                }
            }
        }
    `}
`;

export default styles;
