/**
 * @file index.js Contains all the common functions we want to share externally
 * @module webcore-common
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

export { getValueFromObj, setValueToObj, valueFromObjSatisfiesCondition, valueFromObjSatisfiesConditionsArray, parseStringTemplate, parseValueTemplateConfig } from './DataUtils';
export { send } from './Send';
export { default as FeatureFlags } from './FeatureFlags';
export { default as KeycloakSettings } from './KeycloakSettings';
export { initializeBreadcrumb, uninitializeBreadcrumb } from './Breadcrumb';
export { initializeApplicationInsights, isApplicationInsightsFeatureEnabled } from './ApplicationInsightsInitializer';
export { loadConfigV1, configReducer, clearAllStoredConfig } from './ConfigLoader';
export { setConfig, clearConfig } from './actions/actions';
export { initializeRemoteLogger, isRemoteLoggerFeatureEnabled } from './RemoteLoggerInitializer';
export { setupI18next, reloadBackendLocales, loadNamespaces } from './LocaleLoader';
