/**
 * @module webcore-ux/react/components/LaunchScreen
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../../style/react/components/LaunchScreen/LaunchScreen.css';
import defaultImage from '../../../images/ABB_Logo_Screen_RGB.svg';
import backgroundImage from '../../../images/abb_bg_1920w.png';

export default class LaunchScreen extends React.Component {
    constructor(props) {
        super(props);

        this.launchScreenContainerRef = React.createRef();
    }

    componentDidMount() {
        let img = new Image();
        img.src = backgroundImage;

        this.interval = setInterval(() => {
            if (img.complete) {
                clearInterval(this.interval);
                this.launchScreenContainerRef.current.style.background = `url(${backgroundImage}) no-repeat`;
                this.forceUpdate();
            }
        }, 50);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { className, children, productName, subProductName, productVersion, copyrightText, copyrightTextDir, legalNotice } =
            this.props;

        let abbImage = defaultImage;

        // TODO: add the language picker to the top left corner when required in the future

        return (
            <div
                data-testid="wcux-launch-screen"
                ref={this.launchScreenContainerRef}
                className={classNames('wcux-launch-screen', className)}
            >
                {/*Note: The below container is here so that the background image above is 100% height/width, but the scrollable region is 100% - 85px*/}
                <div className={'wcux-launch-screen-scrollable-container'}>
                    <div className="wcux-launch-screen-upper-section">
                        <div className="wcux-launch-screen-product-version">{productVersion}</div>
                        <img src={abbImage} className="wcux-launch-screen-abb-icon" />
                        <span className="wcux-launch-screen-line" />
                        <div className="wcux-launch-screen-product-name">{productName}</div>
                        <div className="wcux-launch-screen-sub-product-name">{subProductName}</div>
                    </div>
                    <div className="wcux-launch-screen-middle-section">
                        <div className={'wcux-launch-screen-content'}>{children}</div>
                    </div>
                </div>
                <div className="wcux-launch-screen-bottom-section">
                    {copyrightText && (
                        <div className="wcux-launch-screen-copyright" dir={copyrightTextDir}>
                            {copyrightText}
                        </div>
                    )}
                    {legalNotice && <div className="wcux-launch-screen-legal">{legalNotice}</div>}
                </div>
            </div>
        );
    }
}

LaunchScreen.defaultProps = {
    copyrightTextDir: 'ltr',
};

LaunchScreen.propTypes = {
    /** Class name to apply to the Launch Screen */
    className: PropTypes.string,
    /** Children to render with this component */
    children: PropTypes.node.isRequired,
    /** Product name to display */
    productName: PropTypes.string.isRequired,
    /** Sub product name */
    subProductName: PropTypes.string,
    /** Product version to display */
    productVersion: PropTypes.string.isRequired,
    /** Copyright to display */
    copyrightText: PropTypes.string,
    /** Legal Notice Text */
    legalNotice: PropTypes.string,
    /**
     * The copyright text is always English and should be displayed LTR, or the characters become out of order.
     * If for some reason that isn't the case, this prop can control the display direction of the text.
     */
    copyrightTextDir: PropTypes.oneOf(['ltr', 'rtl']),
};
