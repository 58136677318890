/**
 * @file CardGridLayoutProvider component
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { updateGridConfigForMaximizingByCardId } from './util';

export const CardGridLayoutContext = React.createContext();

export const useCardGridLayoutContext = () => {
    return useContext(CardGridLayoutContext);
};

const _CARD_GRID_MAXIMIZED_CARD_ID = '_CARD_GRID_MAXIMIZED_CARD_ID';
let currentCardGridLayoutConfig = {};

export const CardGridLayoutProvider = ({ children, cardGridLayoutConfig }) => {
    // if you have more than one CardGridLayoutProvider, you must provide a uinique id with each
    // so their settings in localStorage won't overwritten
    if (!cardGridLayoutConfig.id) {
        // eslint-disable-next-line no-console
        console.warn('You need to provide a unique id for each CardGridLayoutProvider in the config');
    }

    const _maximizedCardId = _CARD_GRID_MAXIMIZED_CARD_ID + '_' + (cardGridLayoutConfig.id || '');

    const inputEl = useRef(null);

    const [config, setConfig] = useState({
        ...cardGridLayoutConfig,
        layouts: (currentCardGridLayoutConfig && currentCardGridLayoutConfig[cardGridLayoutConfig.id]) || cardGridLayoutConfig.layouts,
    });

    const [maximizedCardId, setMaximizedCardId] = useState();

    useEffect(() => {
        const maximizedCardId = localStorage.getItem(_maximizedCardId);
        if (maximizedCardId) {
            const newConfig = updateGridConfigForMaximizingByCardId(
                config,
                maximizedCardId,
                0.9 * window.innerHeight < inputEl.current.clientHeight ? 0.9 * window.innerHeight : inputEl.current.clientHeight
            );

            setConfig(newConfig);
            localStorage.removeItem(_maximizedCardId);
        } else {
            setConfig({
                ...config,
                layouts: (currentCardGridLayoutConfig && currentCardGridLayoutConfig[cardGridLayoutConfig.id]) || config.layouts,
            });
        }

        setMaximizedCardId(maximizedCardId);
    }, [cardGridLayoutConfig.id]);

    const updateConfig = (cardGridLayoutConfig) => {
        if (!maximizedCardId) {
            setConfig(cardGridLayoutConfig);
            currentCardGridLayoutConfig[cardGridLayoutConfig.id] = cardGridLayoutConfig.layouts;
        }
    };

    const openInNew = (cardId) => {
        localStorage.setItem(_maximizedCardId, cardId);
        return window.open(window.location.href, '_blank');
    };

    const maximizeCard = (cardId) => {
        if (maximizedCardId) {
            // restore
            setMaximizedCardId();
            localStorage.removeItem(_maximizedCardId);
            setConfig({
                ...config,
                rowHeight: 150,
                layouts:
                    (currentCardGridLayoutConfig && currentCardGridLayoutConfig[cardGridLayoutConfig.id]) || cardGridLayoutConfig.layouts,
            });
        } else {
            // maximize
            setMaximizedCardId(cardId);

            if (!currentCardGridLayoutConfig || !currentCardGridLayoutConfig[cardGridLayoutConfig.id]) {
                currentCardGridLayoutConfig[cardGridLayoutConfig.id] = config.layouts;
            }

            // when maximizing a card, the height will be either 90% of view port or height of GridContainer, what ever is smaller
            // it will prevent the cases when we have too many cards and making a maximized card a really long one
            const newConfig = updateGridConfigForMaximizingByCardId(
                config,
                cardId,
                0.9 * window.innerHeight < inputEl.current.clientHeight ? 0.9 * window.innerHeight : inputEl.current.clientHeight
            );

            setConfig(newConfig);
        }
    };

    return (
        <div ref={inputEl} style={{ height: '100%' }}>
            <CardGridLayoutContext.Provider value={{ config, updateConfig, openInNew, maximizeCard, maximizedCardId }}>
                {children}
            </CardGridLayoutContext.Provider>
        </div>
    );
};

CardGridLayoutProvider.propTypes = {
    children: PropTypes.node.isRequired,
    cardGridLayoutConfig: PropTypes.object.isRequired,
};
