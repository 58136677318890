/**
 * @module webcore-ux/nextgen/components/Dropdown/StyledSplitButton
 * @copyright © Copyright 2021 Hitachi ABB. All rights reserved.
 */

import styled from 'styled-components';
import { Button } from '..';
import { wcuxNxtMenuStyle, wcuxNxtMenuOptionStyle } from '../../sharedStyles/Menu';
import { variantTypes } from './SplitButton';
import { dirLTR } from '../../theme/theme';
import { alpha } from '@material-ui/core';

const getButtonBorders = (props) => {
    switch (props.theme.direction) {
        case dirLTR:
            return `&&.wcux-nxt-split-button-main { // && to increase specificity for overrides
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &&.wcux-nxt-split-button-toggle {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }`;
        default:
            return `&&.wcux-nxt-split-button-main {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }

                    &&.wcux-nxt-split-button-toggle {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }`;
    }
};

const getButtonVariants = (props) => {
    switch (props.variant) {
        case variantTypes['primary']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${props.theme.palette.primary.dark};
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${props.theme.palette.primary.dark};
                        border-right: 0;
                    }
                `;

        case variantTypes['secondary']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${props.theme.palette.secondary.dark};
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${props.theme.palette.secondary.dark};
                        border-right: 0;
                    }
                `;

        case variantTypes['discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.secondary.main, 0.3) : props.theme.palette.primary.main
                        };

                        button {
                            border-right: 0;
                        }

                        button,
                        button:hover {
                            border-right: 1px solid ${alpha(props.theme.palette.primary.main, 0)};
                        }
                    }

                    &&:last-child {

                        button {
                            border-left: 0;
                        }

                        button, 
                        button:hover {
                            border-left: 1px solid ${alpha(props.theme.palette.primary.main, 0)};
                        }
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.secondary.main, 0.3) : props.theme.palette.primary.main
                        };
                        border-right: 0;
                        
                        button {
                            border-left: 0;
                        }

                        button,
                        button:hover {
                            border-left: 1px solid ${alpha(props.theme.palette.primary.main, 0)};
                        }
                    }

                    &&:last-child {
                        button {
                            border-right: 0;
                        }   

                        button,
                        button:hover {
                            border-right: 1px solid ${alpha(props.theme.palette.primary.main, 0)};
                        }
                    }
                `;

        case variantTypes['secondary-discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };

                        button {
                            border-right: 0;
                        }

                        button,
                        button:hover {
                            border-right: 1px solid ${alpha(props.theme.palette.secondary.main, 0)};
                        }
                    }

                    &&:last-child {

                        button {
                            border-left: 0;
                        }
                        
                        button,
                        button:hover {
                            border-left: 1px solid ${alpha(props.theme.palette.secondary.main, 0)};
                        }
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };
                        border-right: 0;

                        button {
                            border-right: 0;
                        }

                        button,
                        button:hover {
                            border-left: 1px solid ${alpha(props.theme.palette.secondary.main, 0)};
                        }
                    }

                    &&:last-child {

                        button {
                            border-left: 0;
                        }
                        
                        button,
                        button:hover {
                            border-right: 1px solid ${alpha(props.theme.palette.secondary.main, 0)};
                        }
                    }
                `;

        case variantTypes['ultra-discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.primary.main, 0.3) : props.theme.palette.primary.main
                        };
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.primary.main, 0.3) : props.theme.palette.primary.main
                        };
                        border-right: 0;
                    }
                `;

        case variantTypes['secondary-ultra-discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? alpha(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };
                        border-right: 0;
                    }
                `;
    }
};

const StyledSplitButton = styled.div`
    position: relative;
    display: inline-block;
`;

const StyledButton = styled(Button)`
    .wcux-nxt-button-content {
        display: flex;
        align-items: center;
        position: relative;
    }

    .wcux-nxt-button-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Mui-disabled {
        &:hover {
            cursor: not-allowed;
        }
    }

    ${(props) => {
        return getButtonBorders(props) + getButtonVariants(props);
    }}
`;

const StyledMenuItem = styled.li`
    ${wcuxNxtMenuOptionStyle}

    ${({ theme }) => `
        &.wcux-nxt-split-button-menu-item-disabled {
            color: ${theme.palette.secondary.light};
            cursor: not-allowed;

            &:hover {
                background: ${theme.palette.background.surface4};
            }
        }
    `}
`;

const StyledMenu = styled.ul`
    ${wcuxNxtMenuStyle}

    ${({ theme }) => `
        position: absolute; 
        left: 0;
        right: 0;
        z-index: 2;
        list-style-type: none;
        margin: 0;
        padding: 0;

        &.wcux-nxt-split-button-menu-top {
            top: auto;
            bottom: 100%;
            margin-bottom: ${theme.spacing(1) / 2}px;
        }

        &.wcux-nxt-split-button-menu-bottom {
            top: 100%;
            bottom: auto;
            margin-top: ${theme.spacing(1) / 2}px;
        }
    `}
`;

export { StyledSplitButton, StyledButton, StyledMenuItem, StyledMenu };
