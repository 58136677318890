import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    display: flex;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        flex-direction: column;
    }
`;

export const LeftSection = styled.div`
    width: 70%;
    display: flex;
    align-items: baseline;
    & > * {
        margin-right: 16px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        margin-bottom: 16px;
    }
`;

// TODO: Use Typography and change the theme
// for 1.7 we are going to hard code these and don't change the theme
export const Title = styled.h4`
    max-width: 50%;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px;
    margin: 0 16px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

// TODO: Use Typography and change the theme
// for 1.7 we are going to hard code these and don't change the theme
export const Subtitle = styled.h4`
    max-width: 50%;
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row-reverse;
    & > * {
        margin-left: 8px;
    }
`;
