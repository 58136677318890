/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Language(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.3">
                <path d="M14.6582 15C14.0431 17.7833 12.7444 20 12 20C11.2556 20 9.95694 17.7833 9.3418 15H14.6582Z" />
                <path d="M13.9088 19.7709C16.4189 19.1564 18.4642 17.3574 19.4185 15H15.7361C15.3571 17.0689 14.6251 18.6438 13.9088 19.7709Z" />
                <path d="M19.748 14C19.9125 13.3608 20 12.6906 20 12C20 11.3094 19.9125 10.6392 19.748 10H15.8858C15.9592 10.6265 16 11.2929 16 12C16 12.7071 15.9592 13.3735 15.8858 14H19.748Z" />
                <path d="M19.4185 9C18.4642 6.64261 16.4189 4.8436 13.9088 4.22913C14.6251 5.35618 15.3571 6.93111 15.7361 9H19.4185Z" />
                <path d="M10.0912 4.22913C7.58106 4.8436 5.53576 6.64261 4.58152 9H8.2639C8.64291 6.93111 9.37489 5.35618 10.0912 4.22913Z" />
                <path d="M4.25204 10C4.08751 10.6392 4 11.3094 4 12C4 12.6906 4.08751 13.3608 4.25204 14H8.11421C8.04082 13.3735 8 12.7071 8 12C8 11.2929 8.04082 10.6265 8.11421 10H4.25204Z" />
                <path d="M4.58152 15H8.2639C8.64291 17.0689 9.37489 18.6438 10.0912 19.7709C7.58106 19.1564 5.53576 17.3574 4.58152 15Z" />
                <path d="M12 4C12.7444 4 14.0431 6.21674 14.6582 9H9.3418C9.95694 6.21674 11.2556 4 12 4Z" />
                <path d="M14.8444 14C14.9434 13.346 15 12.673 15 12C15 11.327 14.9434 10.654 14.8444 10H9.15557C9.05662 10.654 9 11.327 9 12C9 12.673 9.05662 13.346 9.15557 14H14.8444Z" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C12.7444 20 14.0431 17.7833 14.6582 15H9.3418C9.95694 17.7833 11.2556 20 12 20ZM19.4185 15C18.4642 17.3574 16.4189 19.1564 13.9088 19.7709C14.6251 18.6438 15.3571 17.0689 15.7361 15H19.4185ZM19.748 14H15.8858C15.9592 13.3735 16 12.7071 16 12C16 11.2929 15.9592 10.6265 15.8858 10H19.748C19.9125 10.6392 20 11.3094 20 12C20 12.6906 19.9125 13.3608 19.748 14ZM19.4185 9H15.7361C15.3571 6.93111 14.6251 5.35618 13.9088 4.22913C16.4189 4.8436 18.4642 6.64261 19.4185 9ZM10.0912 19.7709C9.37489 18.6438 8.64291 17.0689 8.2639 15H4.58152C5.53576 17.3574 7.58106 19.1564 10.0912 19.7709ZM4.25203 14H8.11421C8.04082 13.3735 8 12.7071 8 12C8 11.2929 8.04082 10.6265 8.11421 10H4.25203C4.08751 10.6392 4 11.3094 4 12C4 12.6906 4.08751 13.3608 4.25203 14ZM4.58152 9H8.2639C8.64291 6.93111 9.37489 5.35618 10.0912 4.22913C7.58106 4.8436 5.53576 6.64261 4.58152 9ZM12 4C12.7444 4 14.0431 6.21674 14.6582 9H9.3418C9.95694 6.21674 11.2556 4 12 4ZM15 12C15 12.673 14.9434 13.346 14.8444 14H9.15557C9.05662 13.346 9 12.673 9 12C9 11.327 9.05662 10.654 9.15557 10H14.8444C14.9434 10.654 15 11.327 15 12Z"
            />
        </SvgIcon>
    );
}
