/**
 * @module webcore-ux/nextgen/components/ToggleButton/styles
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import { css } from 'styled-components';
import { wcuxNxtLabelClass, wcuxNxtMandatoryIndicatorClass } from '../../sharedStyles/Form';
import { dirLTR } from '../../theme/theme';

const BORDER_WIDTH = '1px';
const BORDER_TYPE = 'solid';
const RADIUS = '8px';

const getButtonRadius = (props) => {
    if (props.theme.direction === dirLTR) {
        return `
            .wcux-nxt-toggle-button-group {
            
                .wcux-nxt-toggle-button-root:not(:first-child) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            
                .wcux-nxt-toggle-button-root:not(:last-child) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        `;
    }

    return `
        .wcux-nxt-toggle-button-group {

            .wcux-nxt-toggle-button-root:not(:first-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        
            .wcux-nxt-toggle-button-root:not(:last-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    `;
};

const styles = css`
    ${wcuxNxtLabelClass}
    ${wcuxNxtMandatoryIndicatorClass}
    ${({ theme }) => `
        .wcux-nxt-toggle-button-group {
            background-color: ${theme.palette.background.surface4};
            border-radius: ${RADIUS};
        }

        .wcux-nxt-toggle-button-root {
            font-family: ${theme.typography.fontFamily};
            padding: 0px ${theme.spacing(2)}px;
            color: ${theme.palette.secondary.main};
            border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.secondary.main};
            border-radius: ${RADIUS};
            text-transform: none;
        
            .wcux-nxt-toggle-button-content {
                margin: 0px 5px;
            }
        
            &:hover {
                background-color: ${theme.palette.background.surface4};
                border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.secondary.dark};
            }
        
            &:disabled {
                background-color: ${theme.palette.background.surface4};
                border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.secondary.main};
                color: ${theme.palette.secondary.main};
                opacity: 0.3;
            }
        
            &.wcux-nxt-toggle-button-selected {
                background-color: ${theme.palette.primary.main};
                border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.primary.main};
                color: ${theme.palette.background.surface4};
        
                svg {
                    fill: ${theme.palette.background.surface4};
                }

                &:hover {
                    background-color: ${theme.palette.primary.dark};
                    border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.primary.dark};
                }
            }               
        }
        
        .wcux-nxt-toggle-button-group-small {

            .wcux-nxt-toggle-button-root {
                font-size: ${theme.typography.fontSizes.medium};
                height: 32px;
                min-width: 32px;
        
                .wcux-nxt-toggle-button-icon {
                    width: 16px;
                    height: 16px;
        
                    img,
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }          
        }

        .wcux-nxt-toggle-button-group-normal {

            .wcux-nxt-toggle-button-root {
                font-size: ${theme.typography.fontSizes.large};
                height: 40px;
                min-width: 40px;
        
                .wcux-nxt-toggle-button-icon {
                    width: 24px;
                    height: 24px;
            
                    img,
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }       
            }
        }

        .wcux-nxt-toggle-button-group-large {        

            .wcux-nxt-toggle-button-root {
                font-size: ${theme.typography.fontSizes.xlarge};
                height: 48px;
                min-width: 48px;
        
                .wcux-nxt-toggle-button-icon {
                    width: 32px;
                    height: 32px;
        
                    img,
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }

        .wcux-nxt-toggle-button-group-readonly {
            pointer-events: none;
        
            .wcux-nxt-toggle-button-root {
                background-color: ${theme.palette.background.surface4};
                border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.secondary.main};
                color: ${theme.palette.secondary.main};
                opacity: 0.3;
            }
        
            .wcux-nxt-toggle-button-selected {
                background-color: ${theme.palette.secondary.main};
                border: ${BORDER_WIDTH} ${BORDER_TYPE} ${theme.palette.secondary.main};
                color: ${theme.palette.background.surface4};
                opacity: 1.0;
            }    
        }`}

    ${(props) => {
        return getButtonRadius(props);
    }}
`;

export default styles;
