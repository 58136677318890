/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import DownloadIcon from '@material-ui/icons/SaveAlt';

export default function Download(props) {
    return <DownloadIcon {...props} />;
}
