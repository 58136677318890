/**
 * @module webcore-ux/nextgen/components/MenuList/MenuList
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import MuiMenuList from '@material-ui/core/MenuList';
import MuiListSubheader from '@material-ui/core/ListSubheader';
import { IconButton as MuiIconButton } from '@material-ui/core';

const BASE_PADDING = 3;
const BASE_HEIGHT = 32;
const SUBHEAD_HEIGHT = `${BASE_HEIGHT}px`;
const IDS = {
    MENU_LABEL: 'wcux-nxt-menu-label',
    MENU_ITEM_OPTION: 'wcux-nxt-menu-item',
};

const MenuList = (props) => {
    const { id, className, menuOptions, selected, onClick: onMenuClick, label, ...other } = props;

    let hasIcon = false;
    for (let i = 0; menuOptions.length > i; i++) {
        if (menuOptions[i].icon) {
            hasIcon = true;
            break;
        }
    }

    return (
        <MuiMenuList
            id={id}
            data-testid={props['data-testid']}
            className={classnames('wcux-nxt-menu-list', className)}
            subheader={
                label && (
                    <MuiListSubheader classes={menuSubheaderClasses} data-testid={`${IDS.MENU_LABEL}`}>
                        {label}
                    </MuiListSubheader>
                )
            }
            classes={menuListClasses}
            {...other}
        >
            {menuOptions.map(({ value, displayLabel, onClick, disabled, icon }, index) => (
                <MenuItem
                    key={index}
                    value={value}
                    onClick={() => {
                        typeof onClick === 'function' && onClick(value, index);
                        typeof onMenuClick === 'function' && onMenuClick(value, index);
                    }}
                    selected={selected === value}
                    autoFocus={selected === value}
                    data-testid={`${IDS.MENU_ITEM_OPTION}-${value}`}
                    disabled={disabled}
                >
                    {hasIcon && (
                        <div className={classnames('wcux-nxt-menu-item-icon-container')}>
                            <div className={classnames('wcux-nxt-menu-item-icon-icon')}>
                                <MuiIconButton className={classnames('wcux-nxt-menu-item-icon-button')}>{icon}</MuiIconButton>
                            </div>
                            <div className={classnames('wcux-nxt-menu-item-icon-value')}>{displayLabel || value}</div>
                        </div>
                    )}
                    {!hasIcon && (displayLabel || value)}
                </MenuItem>
            ))}
        </MuiMenuList>
    );
};

const menuListClasses = {
    root: 'wcux-nxt-menu-list-root',
};

const menuSubheaderClasses = {
    root: 'wcux-menu-subheader-root',
};

const StyledMenuList = styled(MenuList)`
    ${({ theme }) => `
        &.wcux-nxt-menu-list-root {
            outline: none;

            & .wcux-menu-subheader-root {
                font-size: ${theme.typography.body1.fontSize};
                padding-left: ${theme.spacing(BASE_PADDING)}px;
                padding-right: ${theme.spacing(BASE_PADDING)}px;
                line-height: ${SUBHEAD_HEIGHT};
                height: ${SUBHEAD_HEIGHT};
            }
        }

        .wcux-nxt-menu-item-icon-container {
            display: flex;
        }

        .wcux-nxt-menu-item-icon-icon {
            width: 36px;
        }

        .wcux-nxt-menu-item-icon-button {
            padding-left: 0;
            pointer-events: none;
        }

        .wcux-nxt-menu-item-icon-value {
            display: flex;
            align-items: center;
        }
    `}
`;

MenuList.defaultProps = {
    'data-testid': 'wcux-nxt-menu-list',
};

MenuList.propTypes = {
    /** Id of the menu list element. */
    id: PropTypes.string,
    /** CSS class passed down */
    className: PropTypes.string,
    /** Custom data test id applied to the element */
    'data-testid': PropTypes.string,
    /** A list of {value, displayLabel} options to display in the menu */
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            /** The value of the menu item to pass into the click callback. If displayLabel is not supplied, value will be used as the display text */
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /** Optional - Human-friendly text representation of the value. Eg. value could be a UUID and displayLabel could be a name */
            displayLabel: PropTypes.string,
            /**
             * Callback when this option is clicked
             * Signature: (value: String | Number, index: number) -> undefined
             */
            onClick: PropTypes.func,
            /** Optional - show Icon in menuitem */
            icon: PropTypes.node,
        })
    ).isRequired,
    /**
     * Callback that receives the value of the selected option
     * Signature: (value: String | Number, index: number) -> undefined
     */
    onClick: PropTypes.func.isRequired,
    /** The currently selected option. The option in the list with a value that matches this prop will be highlighted */
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** An optional text label that shows at the top of the menu */
    label: PropTypes.string,
};

export default StyledMenuList;
