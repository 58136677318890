/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DocumentTwoTone(props) {
    return (
        <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 8H28V16H38V40H10V8ZM14 26V28H34V26H14ZM14 32V34H28V32H14ZM14 14V16H24V14H14ZM14 22V20H34V22H14Z"
                fill="#3F4551"
            />
            <path
                d="M10 4C8.93913 4 7.92172 4.42143 7.17157 5.17157C6.42143 5.92172 6 6.93913 6 8V40C6 41.0609 6.42143 42.0783 7.17157 42.8284C7.92172 43.5786 8.93913 44 10 44H38C39.0609 44 40.0783 43.5786 40.8284 42.8284C41.5786 42.0783 42 41.0609 42 40V16L30 4H10ZM10 8H28V16H38V40H10V8ZM14 26V28H34V26H14ZM14 32V34H28V32H14Z"
                fill="#3F4551"
            />
            <path d="M14 22V20H34V22H14Z" fill="#3F4551" />
            <path d="M14 16V14H24V16H14Z" fill="#3F4551" />
        </SvgIcon>
    );
}
