/**
 * @module webcore-ux/react/FormBuilder
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import FormContext from './FormContext';
import Form from '../Form/Form';
import Record from '../../../data/Record';
import { generateRecordFromInline } from './';

/**
 * Callback for getting a string resource
 *
 * @callback getStringResourceCallback
 * @param {string} key - key of the string resource to get
 */

/**
 * Validation result object where the properties are the field names and the values indicate if there are errors.
 * Values can be a boolean or an error string.
 *
 * For field validation this contains the validation result of the named field and any dependent fields.
 * For form validation this contains the validation result for all validated fields.
 *
 * @typedef {object} validationResultType
 */

/**
 * Callback for validating a field
 *
 * @callback validateCallback
 * @param {string} name - name of field to validate
 * @param {object} record - record containing the form values
 * @returns {validationResultType} - validation result
 */

/**
 * Callback for validating a form
 *
 * @callback validateAllCallback
 * @param {object} record - record containing the form values
 * @returns {validationResultType} - validation result
 */

/**
 * @typedef {object} validationType
 * @properties {validateCallback} validate - callback for validating a field
 * @properties {validateAllCallback} validateAll - callback for validating all fields
 */

/**
 * Dynamic form builder
 */
export default class FormBuilder {
    /**
     * Constructor
     *
     * @param {object} config - object containing the form configurations
     * @param {getStringResourceCallback} getStringResource - function for getting a string resource
     */
    constructor(config, getStringResource) {
        this.config = config;
        this.getStringResource = getStringResource;
    }

    /**
     * Builds the named form
     *
     * @param {string} formName - name of the form to build
     * @param {object} [options] - build options
     * @param {string} [options.className] - CSS class name to add to the form
     * @param {object} [options.relatedRecords] - related records (e.g. records used for prefill)
     * @param {object} [options.callbacks] - name value pairs of form event name and their callback function
     * @param {validationType} [options.validation] - field/form validation
     * @param {string} [options.hostUrl] - host url
     * @param {function} [options.getToken] - callback for getting auth token
     * @returns {FormContext} the built form context
     */
    buildForm(
        formName,
        { className, relatedRecords, callbacks, validation, hostUrl, getToken, childMenuPlacement, childMenuPosition } = {}
    ) {
        let formConfig = this.config.items[formName];
        const formChildMenuPlacement = childMenuPlacement ? childMenuPlacement : 'auto';
        const formChildMenuPosition = childMenuPosition ? childMenuPosition : 'fixed';

        if (!formConfig) {
            throw new Error(`Error building form ${formName}. Form is not configured.`);
        }

        let recordDef = this.config.data && this.config.data.records && this.config.data.records[formConfig.record];
        if (!recordDef) {
            recordDef = generateRecordFromInline(formConfig);
        }

        let record = new Record(formConfig.record, recordDef, formConfig.defaultValues, true, relatedRecords),
            context = new FormContext(formName);

        context.form = (
            <Form
                ref={context.formRef}
                className={className}
                config={formConfig}
                configData={this.config.data}
                record={record}
                getStringResource={this.getStringResource}
                callbacks={callbacks}
                validation={validation}
                hostUrl={hostUrl}
                getToken={getToken}
                childMenuPlacement={formChildMenuPlacement}
                childMenuPosition={formChildMenuPosition}
            />
        );

        return context;
    }

    /**
     * Builds the named display only form
     *
     * @param {string} formName - name of the form to build
     * @param {object} data - form data
     * @param {object} [options] - build options
     * @param {string} [options.className] - CSS class name to add to the form
     * @param {object} [options.callbacks] - name value pairs of form event name and their callback function
     * @returns {FormContext} the built form context
     */
    buildDisplayOnlyForm(formName, data, options = {}) {
        let formConfig = this.config.items[formName];

        if (!formConfig) {
            throw new Error(`Error building form ${formName}. Form is not configured.`);
        }

        let recordDef = this.config.data && this.config.data.records && this.config.data.records[formConfig.record];
        if (!recordDef) {
            recordDef = generateRecordFromInline(formConfig);
        }

        let record = new Record(formConfig.record, recordDef, data, false),
            context = new FormContext(formName);

        context.form = (
            <Form
                ref={context.formRef}
                className={options.className}
                config={formConfig}
                configData={this.config.data}
                record={record}
                getStringResource={this.getStringResource}
                callbacks={options.callbacks}
            />
        );

        return context;
    }
}
