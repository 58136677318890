import clonedeep from 'lodash.clonedeep';

export const updateGridConfigForMaximizingByCardId = (config, cardId, clientHeight) => {
    const newLayout = {};
    for (const key in config.layouts) {
        if (Object.hasOwnProperty.call(config.layouts, key)) {
            const layout = config.layouts[key];
            newLayout[key] = layout.map((item) => {
                if (item.i === cardId) {
                    return { ...item, x: 0, y: 0, w: config.cols[key], h: 1, static: true };
                } else {
                    return { ...item, w: 0, h: 0 };
                }
            });
        }
    }

    return { ...clonedeep(config), layouts: newLayout, rowHeight: clientHeight - config.margin[1] * 2 };
};
