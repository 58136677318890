/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Scheduling(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 8H4V21H20V8ZM6 11H9V12H6V11ZM11 11H18V12H11V11ZM18 14H15V15H18V14ZM6 14H13V15H6V14ZM9 17H6V18H9V17ZM11 17H18V18H11V17Z"
            />
            <path d="M6 11H9V12H6V11Z" />
            <path d="M11 11H18V12H11V11Z" />
            <path d="M18 14H15V15H18V14Z" />
            <path d="M6 14H13V15H6V14Z" />
            <path d="M9 17H6V18H9V17Z" />
            <path d="M11 17H18V18H11V17Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19 3H22V23H2V3H5V1H7V3H17V1H19V3ZM4 21H20V8H4V21Z" />
        </SvgIcon>
    );
}
