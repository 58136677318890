/**
 * @module webcore-ux/data/Record
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import Logger from 'abb-webcore-logger/Logger';
import moment from 'moment';
import { getValueFromObj, setValueToObj } from 'webcore-common';
import cloneDeep from 'lodash.clonedeep';

/**
 * Data record class with methods to get and set data and doing type conversion where necessary.
 */
export default class Record {
    /**
     * Constructor
     *
     * @param {string} recordName - Record name
     * @param {object} recordDef - Defines the fields in the record, the path and type.
     * @param {object} [defaultValues] - Field default values
     * @param {boolean} [isNameValue] - true if the default values contain name/value pairs of field name and value, or false if the field value is retrieved from the field path.
     * @param {object} [relatedRecords] - related records (e.g. records used for prefill)
     */
    constructor(recordName, recordDef, defaultValues = {}, isNameValue = true, relatedRecords = {}) {
        this.recordName = recordName;
        this.recordDef = recordDef;

        if (!this.recordDef || !this.recordDef.fields || typeof this.recordDef.fields !== 'object') {
            throw new Error('Invalid record definition');
        }

        this._originalData = defaultValues;

        if (isNameValue) {
            this.defaultValues = defaultValues;
        } else {
            this.defaultValues = {};

            let fieldName, fieldDef;

            for (fieldName in this.recordDef.fields) {
                fieldDef = this.recordDef.fields[fieldName];
                this.defaultValues[fieldName] = getValueFromObj(defaultValues, fieldDef.path);
            }
        }

        this.relatedRecords = relatedRecords;
        this.initData();
    }

    /** Get the original data used to populate the record */
    get originalData() {
        return this._originalData;
    }

    /**
     * Initialize or reset the record data to the default values
     */
    initData() {
        let data = {},
            fieldName;

        for (fieldName in this.recordDef.fields) {
            let fieldDef = this.recordDef.fields[fieldName],
                defaultValue = this.defaultValues[fieldName],
                value;

            if (Array.isArray(defaultValue)) {
                if (fieldDef.type === 'array') {
                    value = defaultValue;
                } else if (fieldDef.type === 'string') {
                    // Array of values to concatenate together to form the default value. Support for string fields only.
                    // Anything other than string fields may require localization, which we are not handling here now.
                    let concatenatedValue = '';

                    defaultValue.forEach((item) => {
                        if (typeof item === 'object' && item !== null) {
                            // Get the value from a related record field (string source field expected)
                            let recValue = this.getRelatedRecordValue(item, 'string');

                            if (recValue) {
                                concatenatedValue += recValue;
                            }
                        } else {
                            // Literal value, convert to string if necessary.
                            concatenatedValue += this.convert(item, 'string');
                        }
                    }, this);

                    value = concatenatedValue;
                } else {
                    Logger.error('Array of values as the default value supported for array and string fields only');
                }
            } else if (defaultValue && defaultValue.recordName && defaultValue.fieldName) {
                // Get the value from a related record field (source field type expected to be the same as the destination field type)
                value = this.getRelatedRecordValue(defaultValue, fieldDef.type);
            } else {
                // literal value
                value = defaultValue;
            }

            // Convert done here in case value is 'null', 'undefined', or ''.
            data[fieldName] = this.convert(value, fieldDef.type);
        }

        this.data = data;
    }

    /**
     * Clear the record
     */
    clearRecord() {
        const data = {};

        for (const fieldName in this.recordDef.fields) {
            const type = this.recordDef.fields[fieldName].type;
            data[fieldName] = type === 'array' ? [] : null;
        }

        this.data = data;
    }

    /**
     * Get a related record value
     *
     * @param {object} source - data source
     * @param {string} source.recordName - record name
     * @param {string} source.fieldName - field name
     * @param {string} expectedType - the expected data type of the value
     * @returns {*} The value if the source exist and of the expected type, otherwise undefined.
     */
    getRelatedRecordValue(source, expectedType) {
        if (typeof source !== 'object' || !source.recordName || !source.fieldName) {
            return;
        }

        let record = this.relatedRecords[source.recordName];

        if (!record || !(record instanceof Record)) {
            Logger.error(`Invalid related record ${source.recordName}`);
            return;
        }

        if (!record.recordDef.fields[source.fieldName]) {
            Logger.error(`Invalid related record field ${source.fieldName}`);
            return;
        }

        if (record.recordDef.fields[source.fieldName].type !== expectedType) {
            Logger.error(`Related record field ${source.fieldName} is not of the expected type: ${expectedType}`);
            return;
        }

        return record.getValue(source.fieldName);
    }

    /**
     * Get a copy of the record data as a flat name/value pair object
     *
     * @param {boolean} [all] - true to get all form values including null, '', and empty array
     *
     * @returns {object} record data
     */
    getData(all) {
        const data = { ...this.data };

        if (!all) {
            const isEmpty = (value) => [null, ''].includes(value) || (Array.isArray(value) && value.length === 0);
            for (const fieldName in data) {
                if (isEmpty(data[fieldName])) {
                    delete data[fieldName];
                }
            }
        }

        return data;
    }

    /**
     * Get the structured record data
     *
     * @param {boolean} [all] - true to get all form values including null and ''
     *
     * @returns {object} structured record data
     */
    getStructuredData(all) {
        const structuredData = {};
        const isEmpty = (value) => [null, ''].includes(value) || (Array.isArray(value) && value.length === 0);

        for (const fieldName in this.recordDef.fields) {
            if (all || !isEmpty(this.data[fieldName])) {
                const field = this.recordDef.fields[fieldName];
                setValueToObj(structuredData, field.path, this.data[fieldName]);
            }
        }

        return structuredData;
    }

    /**
     * Get a record value
     *
     * @param {string} name - field name
     * @returns {*} field value
     */
    getValue(name) {
        return this.data[name];
    }

    /**
     * Set a record value
     *
     * @param {string} name - name of the field to set the new value
     * @param {*} newValue - new field value to set
     */
    setValue(name, newValue) {
        let field = this.recordDef.fields[name],
            value = this.convert(newValue, field.type);

        this.data[name] = value;
    }

    /**
     * Convert the value to the specified record type format (i.e. dates are stored as a date string and not a Date object)
     *
     * @param {*} value - value to convert
     * @param {string} type - type to convert to
     * @returns {*} converted value
     */
    convert(value, type) {
        switch (type) {
            case 'string':
                if (value === undefined || value === null || typeof value === 'object') {
                    return null;
                } else if (value === '') {
                    return '';
                } else {
                    return String(value);
                }

            case 'duration':
            case 'number':
                if (value === undefined || value === null || value === '' || typeof value === 'object') {
                    return null;
                } else {
                    let num = Number(value);

                    if (isNaN(num)) {
                        return null;
                    }

                    return num;
                }

            case 'boolean':
                if (!value || typeof value === 'object') {
                    return false;
                } else {
                    return value === true || value === 'true' || (typeof value === 'number' && value !== 0);
                }

            case 'tristate':
                if (value === undefined || value === null || value === '' || typeof value === 'object') {
                    return null;
                } else {
                    return value === true || value === 'true' || (typeof value === 'number' && value !== 0);
                }

            case 'date':
            case 'time':
            case 'datetime': {
                if (!value || !(value instanceof Date || ['string', 'number'].includes(typeof value))) {
                    return null;
                }

                if (typeof value === 'string' && !isNaN(value)) {
                    // This is assumed to be an epoch ms string. Convert it to a number that moment can handle.
                    value = Number(value);
                }

                const { TIME_MS, TIME_SECONDS, TIME, DATE } = moment.HTML5_FMT;
                let dt = moment(value);

                if (type === 'time' && typeof value === 'string') {
                    dt = moment(value, [moment.ISO_8601, TIME_MS, TIME_SECONDS, TIME]);
                }

                if (dt.isValid()) {
                    if (type === 'date') {
                        return dt.format(DATE);
                    } else if (type === 'time') {
                        return dt.format(TIME_SECONDS);
                    } else {
                        return dt.utc().format();
                    }
                }

                return null;
            }

            case 'date-relative':
                if (typeof value === 'object') {
                    return cloneDeep(value);
                }

                return {
                    date: new Date(),
                    dateType: 'absolute',
                    relativeDateOffset: 0,
                    relativeDateOptionValue: undefined,
                };

            case 'array':
                if (Array.isArray(value)) {
                    return value.slice();
                } else {
                    return [];
                }

            case 'object':
                if (typeof value === 'object') {
                    return cloneDeep(value);
                }

                return null;

            default:
                Logger.error(`Record convert type '${type}' is invalid`);
        }

        return value;
    }
}
