/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import CaretLeftArrowIcon from '@material-ui/icons/ChevronLeft';

export default function CaretLeftArrow(props) {
    return <CaretLeftArrowIcon {...props} />;
}
