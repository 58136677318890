/**
 * @file Component that renders a attachment thumbnail component
 * @copyright © 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import { Card, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logger from 'abb-webcore-logger/Logger';
import PropTypes from 'prop-types';
import React from 'react';
import { Close, Download, DocumentTwoTone } from '../Icons';
import More from '../More/More';

const menuIconSideLength = '32px';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    image: {
        borderRadius: 4,
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    },
    card: {
        borderRadius: 4,
        width: (props) => props.width,
        height: (props) => props.height,
        padding: 0,
        position: 'relative',
        boxShadow: 'none',
        textAlign: 'center',
    },
    defaultThumbnail: {
        border: `1px solid ${theme.palette.secondary.light}`,
    },
    defaultThumbnailBody: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },
    removeButton: {
        position: 'absolute',
        top: `${theme.spacing(1)}px`,
        right: `${theme.spacing(1)}px`,
        background: 'rgba(0, 0, 0, 0.3)', // TODO reference a theme colour
        padding: '4px',
        color: 'white',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.4)',
        },
    },
    defaultIcon: {
        height: 45,
        width: 45,
    },
    menuLabelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '4px',
        maxWidth: ({ width }) => width,
    },
    // This is to normalize the whitespace between the items of the label section when a menu exists/doesn't exist
    labelIconPlaceholder: {
        width: menuIconSideLength,
    },
    labelContainer: {
        minHeight: menuIconSideLength,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    label: {
        margin: '0 4px',
        '-webkit-line-clamp': 2,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-all',
    },
    ext: {
        fontWeight: 700,
    },
    pdf: {
        color: '#D60000',
    },
    doc: {
        color: '#2A6AE7',
    },
    extDefault: {
        color: '#686868',
    },
    bottomActionsContainer: {
        display: 'flex',
        height: menuIconSideLength,
        '& button': {
            padding: '4px',
        },
    },
}));

/**
 * Transform current usages of the menuItem API to the Webcore MenuList API.
 * @see MenuList
 * @param {array} menuItems - MenuItems API
 * @returns {array} menuOptions
 */
const convertMenuItems = (menuItems) => {
    return menuItems.map(({ text, ...other }) => ({
        ...other,
        value: text,
        displayLabel: text,
    }));
};

/**
 * Given a fileName, try and get an uppercase extension
 * @param {string} fileName - Eg. 'image.jpg'
 * @returns {string} The extension, or '' if not found.
 */
const getExtension = (fileName = '') => {
    if (!fileName) {
        return '';
    }

    const split = fileName.split('.');
    if (split.length === 1) {
        return '';
    }

    return split[split.length - 1].toUpperCase();
};

/**
 * renders an attachment thumbnail and action menu
 * @param {number} height - the height of the thumbnail in pixels
 * @param {number} width - the width of the thumbnail in pixels
 * @param {string} thumbnailImagePath - the thumbnail image path
 * @param {string} fileName - the file name
 * @param {array} menuOptions - array of menu item objects
 * @param {object} handleBodyEvents - object with event names
 * @returns {JSX.Element} - the badge component
 * @constructor
 */
export const AttachmentThumbnail = ({
    height,
    width,
    thumbnailImagePath,
    fileName,
    menuOptions,
    menuItems,
    handleBodyEvents,
    onClickRemove,
    onClickDownload,
    fileExtension,
}) => {
    const classes = useStyles({ height, width });

    if ((!menuOptions || !menuOptions.length) && menuItems && menuItems.length) {
        Logger.warn(
            'The AttachmentThumbnail menuItems prop is deprecated and will be eventually removed. Please use the menuOptions API instead.'
        );
        menuOptions = convertMenuItems(menuItems);
    }

    const removeButton = (
        <IconButton className={classes.removeButton} onClick={onClickRemove} data-testid="attachment-remove-btn">
            <Close />
        </IconButton>
    );

    const ext = fileExtension || getExtension(fileName);

    const extSelectors = (() => {
        const selectors = [classes.ext];
        if (ext.toLowerCase() === 'pdf') {
            selectors.push(classes.pdf);
        } else if (ext.toLowerCase().includes('doc')) {
            selectors.push(classes.doc);
        } else {
            selectors.push(classes.extDefault);
        }

        return selectors.join(' ');
    })();

    const hasMenuOptions = menuOptions && menuOptions.length;
    const hasBottomActions = hasMenuOptions || onClickDownload;

    return (
        <div className={classes.root}>
            {thumbnailImagePath && (
                <Card classes={{ root: classes.card }} data-testid={'wcux-nxt-attachment-thumbnail-card'}>
                    <img
                        data-testid={'wcux-nxt-attachment-thumbnail-image'}
                        className={classes.image}
                        alt={fileName}
                        src={thumbnailImagePath}
                    />
                    {onClickRemove && removeButton}
                </Card>
            )}
            {!thumbnailImagePath && (
                <Card
                    classes={{ root: classes.card }}
                    className={classes.defaultThumbnail}
                    data-testid={'wcux-nxt-attachment-thumbnail-default-image'}
                >
                    <div className={classes.defaultThumbnailBody}>
                        <div
                            onClick={handleBodyEvents ? handleBodyEvents.onClick : null}
                            data-testid={'wcux-nxt-attachment-thumbnail-body'}
                        >
                            <DocumentTwoTone className={classes.defaultIcon} />
                        </div>
                        {ext && (
                            <Typography classes={{ root: extSelectors }} data-testid="wcux-nxt-attachment-extension">
                                {ext}
                            </Typography>
                        )}
                    </div>
                    {onClickRemove && removeButton}
                </Card>
            )}
            <div className={classes.menuLabelContainer}>
                <span className={hasBottomActions && classes.labelIconPlaceholder} />
                <div className={classes.labelContainer}>
                    <Typography
                        variant={'body2'}
                        align="center"
                        className={classes.label}
                        data-testid={'wcux-nxt-attachment-thumbnail-filename'}
                        title={fileName}
                    >
                        {fileName}
                    </Typography>
                </div>
                {hasBottomActions ? (
                    <div className={classes.bottomActionsContainer}>
                        {onClickDownload && (
                            <IconButton onClick={onClickDownload} data-testid={'wcux-nxt-attachment-download-button'}>
                                <Download />
                            </IconButton>
                        )}
                        {hasMenuOptions && <More menuOptions={menuOptions} data-testid={'wcux-nxt-attachment-thumbnail-menu-button'} />}
                    </div>
                ) : (
                    // To maintain even spacing for justify-content: space-between
                    <span />
                )}
            </div>
        </div>
    );
};

AttachmentThumbnail.defaultProps = {
    height: 160,
    width: 160,
};

AttachmentThumbnail.propTypes = {
    /** height in pixels of thumbnail **/
    height: PropTypes.number,
    /** width in pixels of thumbnail **/
    width: PropTypes.number,
    /** the thumbnail image node **/
    thumbnailImagePath: PropTypes.string,
    /** filename **/
    fileName: PropTypes.string,
    /** The file extension to display in the non-image thumbnail. If not supplied, AttachmentThumbnail will try to parse an extension from the file name */
    fileExtension: PropTypes.string,
    /**
     * A list of options to display in the menu, useful if there are multiple actions that can be taken against an attachment.
     * See MenuList for the full API.
     */
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            /** Text label to display in the menu item */
            displayLabel: PropTypes.string,
            /** The value of the menu item to pass into the click callback. If displayLabel is not supplied, value will be used as the display text */
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /**
             * Callback when this option is clicked
             * Signature: (value: String | Number, index: number) -> undefined
             */
            onClick: PropTypes.func,
        })
    ),

    /**
     * Callback when the download button is clicked. The download button is only visible if this is supplied.
     * While you can have a menu and download button at the same time, these props are intended to be mutually exclusive.
     * The download button is meant for user convenience if the only action provided is to download the attachment, that way
     * they don't need to open a menu before doing so.
     */
    onClickDownload: PropTypes.func,
    /** Callback when the remove button is clicked. The remove button is only visible if this is supplied. */
    onClickRemove: PropTypes.func,
    /** * DEPRECATED * - menuItems may be removed in a future release. Please use menuOptions instead. */
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            /** text for the menu item **/
            text: PropTypes.string.isRequired,
            /** on click handler for menu item **/
            onClick: PropTypes.func.isRequired,
        })
    ),
    /** object with event names as keys to handle events (click, tap, etc) **/
    handleBodyEvents: PropTypes.shape({
        /** on click handler for body click **/
        onClick: PropTypes.func.isRequired,
    }),
};

export default AttachmentThumbnail;
