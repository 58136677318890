/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';

export default function AccountTreeTwoTone(props) {
    return <LocalAtmTwoToneIcon {...props} />;
}
