/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Analytics(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path opacity="0.3" d="M19 5H5V19H19V5ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z" />
            <path d="M3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5ZM5 5H19V19H5V5ZM7 10H9V17H7V10ZM11 7H13V17H11V7ZM15 13H17V17H15V13Z" />
        </SvgIcon>
    );
}
