/**
 * @module webcore-ux/nextgen/components/LazyMenuList/LazyMenuList
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import identity from 'lodash/identity';
import Logger from 'abb-webcore-logger/Logger';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from '../../../Utils';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import MenuList from '../MenuList/MenuList';

const PAGE_SIZE = 5;

const LazyMenuList = React.forwardRef(
    (
        {
            className,
            name,
            selectedItems,
            searchTerm,
            fetchOptions,
            onItemClick,
            onFirstAvailableOption,
            getItemDisplayLabel,
            getStringResource,
        },
        ref
    ) => {
        const [queryKey, setQueryKey] = useState([name, searchTerm]);
        const [menuOptions, setMenuOptions] = useState([]);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            // Updating query key makes a new query
            setQueryKey([name, searchTerm]);
            setMenuOptions([]);
            onFirstAvailableOption();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchTerm]);

        const { data, error, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useInfiniteQuery(
            queryKey,
            ({ pageParam = { searchTerm: searchTerm, from: 0, size: PAGE_SIZE } }) => fetchOptions(pageParam),
            {
                getNextPageParam: (lastPage) => {
                    // Return undefined if there are no more pages to fetch
                    if (!lastPage) {
                        return undefined;
                    } else {
                        return lastPage.hasMore ? { searchTerm: searchTerm, from: lastPage.cursor, size: PAGE_SIZE } : undefined;
                    }
                },
            }
        );

        useEffect(() => {
            if (status === 'error') {
                Logger.error(`Error fetching menu options: ${JSON.stringify(error)}`);
            }

            setLoading(status === 'loading');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [status]);

        const buildAndSetDisplayLabels = (entries, search) => {
            if (typeof getItemDisplayLabel === 'function') {
                return entries.reduce((acc, entry, i) => {
                    const newEntry = { ...entry };
                    const currentValue = newEntry.displayLabel || newEntry.value || '';
                    const newDisplayLabel = getItemDisplayLabel(currentValue, search[1] || '', i);
                    if (currentValue !== newDisplayLabel) {
                        newEntry.displayLabel = newDisplayLabel;
                    }

                    acc.push(newEntry);
                    return acc;
                }, []);
            }

            return entries;
        };

        useEffect(() => {
            // Parse the data from API format to menu options format if it changes
            if (data && data.pages && data.pages[data.pages.length - 1] && data.pages[data.pages.length - 1].results) {
                const parsedResults = data.pages.reduce((acc, page) => {
                    // If the results contain any already selected values, remove them now
                    if (!isEmpty(selectedItems)) {
                        const filteredResults = page.results.filter((o) => !selectedItems.includes(o.value));
                        return acc.concat(filteredResults);
                    } else {
                        return acc.concat(page.results);
                    }
                }, []);

                setMenuOptions(buildAndSetDisplayLabels(parsedResults, queryKey));

                onFirstAvailableOption(parsedResults[0] && parsedResults[0].value);
            } else {
                setMenuOptions([]);
                onFirstAvailableOption();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data, selectedItems]);

        const observer = useRef();
        const loadMoreButtonRef = useCallback(
            (node) => {
                if (observer.current) {
                    observer.current.disconnect();
                }

                observer.current = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting && hasNextPage) {
                        fetchNextPage();
                    }
                });

                if (node) {
                    observer.current.observe(node);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [hasNextPage, menuOptions]
        );

        return (
            <div ref={ref} className={classNames(className, 'wcux-nxt-filterchip-lazymenulist')}>
                <MenuList
                    className={'wcux-nxt-filterchip-menulist'}
                    menuOptions={menuOptions}
                    onClick={(value) => {
                        onItemClick(value);
                    }}
                    disableListWrap={true}
                />
                {(loading || isFetchingNextPage) && <LoadingIndicator className={'wcux-nxt-lazymenulist-load-icon'} size="small" />}
                {!isFetchingNextPage && hasNextPage && !loading && (
                    <div
                        data-testid="wcux-nxt-lazymenulist-more-data-to-load"
                        className="wcux-nxt-lazymenulist-more-data-to-load"
                        ref={loadMoreButtonRef}
                    />
                )}
                {!isFetchingNextPage && !hasNextPage && !loading && isEmpty(menuOptions) && (
                    <div data-testid="wcux-nxt-lazymenulist-no-data" className="wcux-nxt-lazymenulist-no-data">
                        {getStringResource('filter.noValues')}
                    </div>
                )}
            </div>
        );
    }
);

const StyledLazyMenuList = styled(LazyMenuList)`
    ${({ theme }) => `
        overflow-y: auto;
        min-height: 40px;

        .wcux-nxt-lazymenulist-load-icon {
            padding-bottom: ${theme.spacing(1)}px;
        }

        .wcux-nxt-lazymenulist-more-data-to-load {
            // TRICKY: making this 1px high so that it will be visible at the bottom of the scroll,
            //         otherwise there is a chance it won't trigger a page fetch
            height: 1px;
        }

        .wcux-nxt-filterchip-menulist {
            padding-bottom: unset;
        }

        .wcux-nxt-lazymenulist-no-data {
            padding: ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px;
            line-height 24px;
            font-size: ${theme.typography.body1.fontSize};
            font-weight: ${theme.typography.fontWeightRegular};
            color: ${theme.palette.text.secondary};
        }
    `}
`;

const defaultStringResource = {
    'filter.noValues': 'No values available',
};

LazyMenuList.defaultProps = {
    name: undefined,
    selectedItems: [],
    searchTerm: undefined,
    fetchOptions: () => {},
    onItemClick: () => {},
    onFirstAvailableOption: () => {},
    getItemDisplayLabel: identity,
    getStringResource: (key) => defaultStringResource[key] || key,
};

LazyMenuList.propTypes = {
    /** CSS class name to apply */
    className: PropTypes.string,
    /** A unique identifier for LazyMenuList instances, used for caching purposes in useInfiniteQuery */
    name: PropTypes.string,
    /** A list of options to exclude from the list */
    selectedItems: PropTypes.array,
    /** Used in fetchOptions to filter down the options from user input */
    searchTerm: PropTypes.string,
    /**
     * Asynchronous function to load options on menu open as well as while scrolling. Used for fetching data from an API.
     * @param {object} pageParam - Defines the parameters to adhere to in order to fetch the current page appropriately, with the following format:
     * ```
     * {
     *     searchTerm: 'asdf',
     *     from: 13,
     *     size: 42,
     * }
     * ```
     * @param {string} pageParam.searchTerm - A search term to filter results with
     * @param {string} pageParam.from - A number indicating where to start fetching results from
     * @param {string} pageParam.size - The size of the page
     * @returns {{results: array, cursor: number, hasMore: boolean}} The results of the query, with the following format:
     * ```
     * {
     *     "results": [...] - 'An array containing the results of the query',
     *     "cursor": 15 - 'A number indicating the index of next result to fetch',
     *     "hasMore": false/true - 'A boolean indicating whether there are more results to fetch',
     * }
     * ```
     */
    fetchOptions: PropTypes.func,
    /** Callback for whenever an item is clicked */
    onItemClick: PropTypes.func,
    /** Callback for whenever the first option is available */
    onFirstAvailableOption: PropTypes.func,
    /**
     * Callback to provide the display override for a fetched menu item. Can be useful for providing query highlighting or custom styling for fetched data.
     * @param {string} value - The text of the current menu item.
     * @param {string} searchValue - The search value that was used to fetch the current menu item.
     * @param {int} index - The index of the current menu item.
     * @returns {(string|object)} The display text, supports any renderable object (for example strings or React components).
     */
    getItemDisplayLabel: PropTypes.func,
    /** Function for getting a string resource. Signature: `getStringResource(key)` */
    getStringResource: PropTypes.func,
};

export default StyledLazyMenuList;
