/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import OpenIcon from '@material-ui/icons/OpenInNew';

export default function OpenInNew(props) {
    return <OpenIcon {...props} />;
}
