/**
 * @module webcore-ux/nextgen/components/FilterChip/Divider
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import styled from 'styled-components';

const Divider = (props) => {
    return <hr {...props} />;
};

const StyledDivider = styled(Divider)`
    ${({ theme }) => `
        width: 100%;
        border: none;
        border-bottom: 1px solid ${theme.palette.grey.light};
        margin-bottom: ${theme.spacing(3)}px;
    `}
`;

export default StyledDivider;
