/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import InformationCircle2Icon from '@material-ui/icons/InfoOutlined';

export default function InformationCircle2(props) {
    return <InformationCircle2Icon {...props} />;
}
