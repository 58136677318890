/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';

export default function ListAltTwoTone(props) {
    return <ListAltTwoToneIcon {...props} />;
}
